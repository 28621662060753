import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';

import { MetaProviderService } from '@common/core/services/meta-provider.service';

import { ENDPOINTS } from '@common/const/endpoint.const';
import { UserModel } from '@common/models/user/user.model';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { SessionModel } from '@common/models/session/session.model';
import { RoleModel } from '@common/models/role/role.model';
import { ProviderResponseType } from '@common/types/provider-response.type';
import { map } from 'rxjs/operators';
import { LANGUAGE_DEFAULT } from '@common/const/system.const';

import _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class UserProviderService extends MetaProviderService {
  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }


  /**
   * Return a specific user
   * @param {string} userId
   * @returns {Observable<any>}
   */
  getUserById(userId: string): Observable<any> {
    return this.getById(ENDPOINTS.USER.getById, userId, null, UserModel, true);
  }



  /**
   * Return the user list
   * @param {object} [query] query: sort and filter conditions
   * @returns {Observable<any>}
   */
  getUsers(query?: object): Observable<any> {
    return this.get(ENDPOINTS.USER.get, query, UserModel, true);
  }



  /**
   * Return the user list
   * @param {WorkspaceModel} workspace
   * @param {object} [query = {}] query: sort and filter conditions
   * @returns {Observable<any>}
   */
  getUsersOutWorkspace(workspace: WorkspaceModel, query: object = {}): Observable<any> {
    return this.getUsers(query).pipe(
      map((response: ProviderResponseType) => {
        const memberIdList = _.map(_.get(workspace, 'members', []), 'user._id');

        response.data = _.filter(_.get(response, 'data', []), (u: UserModel) => !_.includes(memberIdList, _.get(u, '_id')));
        return response;
      })
    );
  }


  /**
   * Create an user
   * @param {UserModel} user
   */
  public createUser(user: UserModel): Observable<any> {
    return this.add(ENDPOINTS.USER.post, this.getQuery(user), UserModel, true);
  }



  /**
   * Update user data
   * @param {UserModel} user
   */
  public updateUser(user: UserModel): Observable<any> {
    return this.update(ENDPOINTS.USER.put, _.get(user, '_id'), this.getQuery(user), UserModel, true);
  }

  /**
   * Delete user
   * @param {UserId} user
   */
  public deleteUser(userId: string): Observable<any> {
    // return this.delete(ENDPOINTS.USER.delete, userId, this.getQuery(id), UserModel, true);
    return this.delete(ENDPOINTS.USER.delete, userId);
  }



  /**
   * Update user language only
   * @param {UserModel} user
   * @param {string} lang
   */
  public updateUserLanguage(user: UserModel, lang: string): Observable<any> {
    const query = {
      blm_language: lang === LANGUAGE_DEFAULT ? null : lang
    };

    return this.update(ENDPOINTS.USER.put, _.get(user, '_id'), query, UserModel, true);
  }



  /**
   * Change the actual user workspace
   * @param {WorkspaceModel} env
   * @returns {Observable<any>}
   */
  updateLastWorkspace(user: UserModel, ws: WorkspaceModel): Observable<any> {
    const params = {
      workspace_id: ws._id
    };

    return this.update(ENDPOINTS.USER.changeWorkspace, user._id, params, SessionModel, true);
  }



  /**
   * Add an user to indicate workspace
   * @param {string} workspaceId
   * @param {UserModel} user
   * @returns {Observable<any>}
   */
  aggregateUserToWorkspace(workspaceId: string, user: UserModel, role: RoleModel): Observable<any> {
    return of(null);
    // const query = {
    //   workspace_id: workspaceId,
    //   user_id: user._id,
    //   role_id: role._id
    // };

    // return this.add(ENDPOINTS.USER.addToWorkspace, query, UserModel, true);
  }



  /**
   * Add users to indicate workspace
   * @param {string} workspaceId
   * @param {Array<UserModel>} users
   * @returns {Observable<any>}
   */
  aggregateMultiUsersToWorkspace(workspaceId: string, users: Array<UserModel>, role: RoleModel): Observable<any> {
    return of(null);
    // const query = {workspace_id: workspaceId, users: []};

    // users.forEach((user: UserModel) => {
    //   query.users.push({
    //     user_id: user._id,
    //     role_id: role._id
    //   });
    // });

    // return this.add(ENDPOINTS.USER.addToWorkspace, query, UserModel, true);
  }

  public getUserDetailsForOTP(userId: string): Observable<any> {
    const query = {
      user_id: userId
    };
    return this.add(ENDPOINTS.USER.detailsOTP, query, null, true);
  }

  /*********************** PRIVATE METHODS ************************/
  private getQuery(user: UserModel): object {
    const keyParams = {
      username: {name: 'username', invalid: [null, '']},
      name: {name: 'name', invalid: [null, '']},
      use2fa: {name: 'use2fa', isBoolean: true, invalid: [null, '']},
      'workgroup._id': {name: 'workgroup_id', invalid: [null, '']},
      'role._id': {name: 'role_id', invalid: [null, '']},
      language: {name: 'blm_language', invalid: ['']}
    };

    return this.generateQuery({}, keyParams, user);
  }
}
