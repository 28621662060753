import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { MetaProviderService } from '@common/core/services/meta-provider.service';

import { ENDPOINTS } from '@common/const/endpoint.const';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { UserModel } from '@common/models/user/user.model';
import { WorkspaceMemberModel } from '@common/models/workspace/workspace-member.model';

import _ from 'lodash';
import { WorkspaceCloudModel } from '@common/models/workspace/workspace-cloud.model';
@Injectable({
  providedIn: 'root'
})
export class WorkspaceProviderService extends MetaProviderService {
  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }


  /**
   * Return the workspaces list
   * @param {object} [query]
   * @returns {Observable<any>}
   */
  getWorkspaces(query?: object): Observable<any> {
    return this.get(ENDPOINTS.WORKSPACE.get, query, WorkspaceModel, true);
  }



  /**
   * Return a specific workspace
   * @param {string} id
   * @returns {Observable<any>}
   */
  getWorkspaceById(id: string): Observable<any> {
    return this.getById(ENDPOINTS.WORKSPACE.getById, id, null, WorkspaceModel, true);
  }



  /**
   * Return the user workspaces list
   * @param {UserModel} user
   * @param {object} [query]
   * @returns {Observable<any>}
   */
  getWorkspacesByUser(user: UserModel, query?: object): Observable<any> {
    return this.getWorkspaces(query);
  }



  /**
   * Return a specific user list of workspace
   * @param {string} id
   * @returns {Observable<any>}
   */
  getWorkspaceUsers(id: string): Observable<any> {
    return this.getById(ENDPOINTS.WORKSPACE.members.get, id, null, WorkspaceMemberModel, true);
  }

  /**
   * Return a specific workspace
   * @param {WorkspaceModel} id
   * @returns {Observable<any>}
   */
  createWorkspace(workspace: WorkspaceModel, user_id: string): Observable<any> {
    const query = {
      name: workspace.name,
      description: workspace.description,
      user_id: user_id
      // bp_id: null,
      // work_infras: [],
      // work_contracts: [],
    };
    return this.add(ENDPOINTS.WORKSPACE.post, query, WorkspaceModel, true);
  }

  /**
   * Return a specific workspace
   * @param {WorkspaceModel} id
   * @returns {Observable<any>}
   */
  updateWorkspace(workspace: WorkspaceModel, user_id: string): Observable<any> {
    const query = {
      // _id: workspace._id,
      user_id: user_id,
      name: workspace.name,
      description: workspace.description,
    };
    return this.update(ENDPOINTS.WORKSPACE.put, _.get(workspace, '_id'), query, WorkspaceModel, true);
  }
  
  /**
   * Return a specific workspace
   * @param {string} workspace_id
   * @param {string} user_id
   * @param {string} user_role
   * @returns {Observable<any>}
   */
  addMemberWorkspace(workspace_id: string, user_id: string, user_role: string): Observable<any> {
    const query = {
      user_id: user_id,
      user_role: user_role,
    };
    return this.update(ENDPOINTS.WORKSPACE.members.put['id/member'], workspace_id, query,WorkspaceModel, true);
  }

  /**
   * Return the Clouds list
   * @param {object} query
   * @returns {Observable<any>}
   */
  getCloudsWorkspace(workspace_id: string): Observable<any> {
    return this.getById(ENDPOINTS.WORKSPACE.clouds.get, workspace_id, undefined, WorkspaceCloudModel, true);
  }

  addCloudWorkspace(workspace_id: string, query: any): Observable<any>{
    return this.update(ENDPOINTS.WORKSPACE.clouds.put['id/cloud'], workspace_id, query, WorkspaceModel, true);
  }

  deleteCloudWorkspace(workspace_id: string, cloud_id: string): Observable<any>{
    return this.delete(ENDPOINTS.WORKSPACE.clouds.delete, workspace_id, undefined, undefined, undefined, {
        cloud: cloud_id
      });
    }

  /**
   * Delete Workspace
   * @param {workspaceId} workspace
   */
  public deleteWorkspace(workspaceId: string): Observable<any> {
    return this.delete(ENDPOINTS.WORKSPACE.delete, workspaceId);
  }

  /*********************** PRIVATE METHODS ************************/
  private getQuery(workspace: WorkspaceModel): object {
    const keyParams = {
      name: {name: 'name', invalid: [null, '']},
      description: {name: 'description', invalid: [null, '']},
      bp_id: {name: 'bp', invalid: [null, '']},
      work_infras: {name: 'workInfrastructures', invalid: [null, '']},
      work_contracts: {name: 'workContracts', invalid: [null, '']},
    };

    return this.generateQuery({}, keyParams, workspace);
  }
}
