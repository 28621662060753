import { ConstructorModel } from '../../core/models/constructor.model';
import { UserModel } from '../user/user.model';
import { WorkspaceModel } from '../workspace/workspace.model';
import { WorkgroupModel } from '../workspace/workgroup.model';

import _ from 'lodash';

export class SessionModel extends ConstructorModel {
    _id: string;
    username: string;
    name: string;
    language: string;
    token: string;
    lastWorkspace: WorkspaceModel;
    workgroup: WorkgroupModel;

    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.username = this.setString('username', data);
        this.name = this.setString('name', data);
        this.workgroup = this.setEntity('workgroup', WorkgroupModel, data);
        this.language = this.setString('blm_language', data, null) || this.setString('language', data, null);
        this.token = this.setString('current_session', data, null) || this.setString('token', data);
        this.lastWorkspace = this.setEntity('lastWorkspace', WorkspaceModel, data, null) || this.setEntity('last_workspace', WorkspaceModel, data);
    }


    public getUser(): UserModel {
        return new UserModel(this);
    }


    public updateUser(user: UserModel) {
        this._id = this.setId('_id', user);
        this.username = this.setString('username', user);
        this.name = this.setString('name', user);
        this.workgroup = this.setEntity('workgroup', WorkgroupModel, user, null);
        this.language = this.setString('language', user, null);
    }
}
