import { environment } from '../../../environments/environment';

import _ from 'lodash';
import moment from 'moment';


export function date4Api(date: any) {
    if (_.get(environment, 'addTimeZoneOffset')) {
        return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    }

    return moment(date).toISOString();
}
