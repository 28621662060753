
import { Component, OnInit, Inject, Injector } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MetaListComponent } from '@common/core/components/meta-list.component';

import _ from 'lodash';
import { WorkspaceProviderService } from '@common/services/providers/workspace-provider.service';
import { WorkspaceCloudModel } from '@common/models/workspace/workspace-cloud.model';

@Component({
  selector: 'infraestructure-identities-ethereum-transfer',
  templateUrl: './infraestructure-identities-ethereum-transfer.component.html',
  styleUrls: ['./infraestructure-identities-ethereum-transfer.component.scss']
})
export class InfraestructureIdentitiesEthereumTransferComponent extends MetaListComponent implements OnInit {

  public clouds: Array<WorkspaceCloudModel> = [];
  public transfer: any = {};

  constructor(
    private _injector: Injector,
    public dialogRef: MatDialogRef<InfraestructureIdentitiesEthereumTransferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _contractSrv: WorkspaceProviderService) {
      super(_injector);
    }

  ngOnInit() {
  }

  submit() {
    this.transfer.fromAddress = this.data.identity.account
    this.transfer.ammount = this.transfer.ammountEth * 1000000000000000000
    this.dialogRef.close(this.transfer)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
