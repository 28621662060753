import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PANEL_ROUTES } from './panel.routes';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule,  } from '@angular/material/button';

import { PanelComponent } from './panel.component';
import { NavbarHeaderModule } from '@common/components/navbar-header/navbar-header.module';
import { NavbarLateralModule } from '@common/components/navbar-lateral/navbar-lateral.module';
import { WorkspaceSessionModule } from '@common/components/workspace-session/workspace-session.module';

@NgModule({
  declarations: [PanelComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(PANEL_ROUTES),
    MatSidenavModule,
    MatButtonModule,
    NavbarHeaderModule,
    NavbarLateralModule,
    WorkspaceSessionModule
  ]
})
export class PanelModule { }
