import { MenuModel } from '@common/models/navigation/menu.model';

/** lateral menu */
export const APP_MENU = new MenuModel(
  {items: [
    {
      icon: 'group_work',
      title: 'Menu.WorkspaceItem.title',
      link: 'workspace/list',
      enable: true,
      active: true
    },
    // {
    //   icon: 'category',
    //   title: 'Menu.BussinessProcessItem.title',
    //   link: 'businessprocess',
    //   enable: true,
    //   active: true,
    //   subMenu: [
    //     {icon: null, title: 'Menu.BussinessProcessItem.Submenu.List', enable: true, active: true, link: './list'},
    //     {icon: null, title: 'Menu.BussinessProcessItem.Submenu.New', enable: true, active: true, link: './new'},
    //     {icon: null, title: 'Menu.BussinessProcessItem.Submenu.Template', enable: true, active: true, link: './template'}
    //   ]
    // },
    {
      icon: 'cloud',
      title: 'Menu.CloudWizardItem.title',
      link: 'cloudwizard/list',
      enable: true,
      active: true
    },
    {
      icon: 'device_hub',
      title: 'Menu.InfraestructuresItem.title',
      link: 'infraestructure/list',
      enable: true,
      active: true
    },
    {
      icon: 'polymer',
      title: 'Menu.SmartContractItem.title',
      link: 'smartcontract',
      enable: true,
      active: true,
      subMenu: [
        {icon: null, title: 'Menu.SmartContractItem.Submenu.List', enable: true, active: true, link: './list'},
        // {icon: null, title: 'Menu.SmartContractItem.Submenu.New', enable: true, active: true, link: './new'},
        {icon: null, title: 'Menu.SmartContractItem.Submenu.Template', enable: true, active: true, link: './template'}
      ]
    },
    {
      icon: 'supervisor_account',
      title: 'Menu.AccountItem.title',
      link: 'account/list',
      enable: true,
      active: true
    },
    // {
    //   icon: 'group_work',
    //   title: 'Menu.FormsItem.title',
    //   link: 'forms',
    //   enable: true,
    //   active: true,
    //   subMenu: [
    //     {icon: null, title: 'Menu.FormsItem.Submenu.HSM', enable: true, active: true, link: './hsm'},
    //     {icon: null, title: 'Menu.FormsItem.Submenu.LDAP', enable: true, active: true, link: './ldap'}
    //   ]
    // },
    {
      icon: 'account_circle',
      title: 'Menu.UserItem.title',
      link: 'user',
      enable: true,
      active: true,
      subMenu: [
        {icon: null, title: 'Menu.UserItem.Submenu.Profile', enable: true, active: true, callback: 'goToProfile'},
        {icon: null, title: 'Menu.UserItem.Submenu.Logout', enable: true, active: true, callback: 'logout'}
      ]
    }
    // {
    //   icon: 'help',
    //   title: 'Menu.HelpItem.title',
    //   link: 'help',
    //   enable: true,
    //   active: true
    // }
  ]
});
