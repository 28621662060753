import { ConstructorModel } from '../../core/models/constructor.model';
import { UserModel } from '../user/user.model';
import { getBlockchainImage, getBlockchainIcon } from '../../libs/blockchain';
import { WorkTreeModel } from './work-tree.model';
import { WorkContractHistoricModel } from './work-contract-historic.model';
import { ContractModel } from './contract.model';

import _ from 'lodash';
import md5 from 'md5';

export class WorkContractModel extends ConstructorModel {
    _id: string;
    name: string;
    description: string;
    path: WorkTreeModel;
    published: boolean;
    editable: boolean;
    markAsTemplate: boolean;
    documentation: string;
    source: string;
    type: string;
    version: string;
    hash: string;
    language: string;
    blockchain: string;
    contract: ContractModel;
    createdDate: string;
    createdBy: UserModel;
    updatedDate: string;
    updatedBy: UserModel;
    reviewers: Array<UserModel>;
    deploys: Array<any>;
    historic: Array<WorkContractHistoricModel>;
    logo: string;
    icon: string;
    collections: string;


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.deploys = this.setList('deploys', data);
        this.name = this.setString('name', data);
        this.description = this.setString('description', data);
        this.path = this.setEntity('path', WorkTreeModel, data);
        this.published = this.setBoolean('published', data);
        this.editable = this.setBoolean('editable', data);
        this.markAsTemplate = this.setBoolean('markAsTemplate', data) || this.setBoolean('mark_as_template', data);
        this.documentation = this.setString('documentation', data);
        this.source = this.setString('source', data);
        this.type = this.setString('type', data);
        this.version = this.setString('version', data);
        this.hash = this.getSourceHash(data);
        this.language = this.setString('language', data);
        this.blockchain = this.setString('blockchain', data);
        this.contract = this.setEntity('contract', ContractModel, data);
        this.createdDate = this.setDate('createdDate', data) || this.setDate('created_date', data);
        this.createdBy = this.setEntity('createdBy', UserModel, data, null) || this.setEntity('created_by', UserModel, data);
        this.updatedDate = this.setDate('updatedDate', data) || this.setDate('updated_date', data);
        this.updatedBy = this.setEntity('updatedBy', UserModel, data, null) || this.setEntity('updated_by', UserModel, data);
        this.reviewers = this.setEntityList('reviewers', UserModel, data);
        this.logo = getBlockchainImage(this.blockchain);
        this.icon = getBlockchainIcon(this.blockchain);
        this.historic = this.setEntityList('historic', WorkContractHistoricModel, data);
        this.collections = this.setString('collections', data);
    }



    areSameSources(source: string): boolean {
      return this.generateSourceHash() === this.generateSourceHash(source);
    }


    generateNewVersion(): string {
      let lastChar = _.get(this, 'version', '1.0').slice(-1);

      if (/^[0-9]$/.test(lastChar)) {
        lastChar = parseInt(lastChar, 10) + 1;

      } else if (/^[[a-zA-Z]$/) {
        lastChar = String.fromCharCode(lastChar.charCodeAt() + 1);

      } else {
        return '1.0';
      }

      return this.version.slice(0, this.version.length - 1) + lastChar.toString();
    }

    /********************** PRIVATE METHODS *************************/
    private getSourceHash(data?: object): string {
      const hash = this.setString('hash', data, null);

      if (!_.isNil(hash) && hash !== '') {
        return hash;
      }

      return this.generateSourceHash();
    }


    private generateSourceHash(source?: string): string {
      const tSource = source || this.source;

      return !_.isNil(tSource) && tSource !== '' ? md5(tSource) : null;
    }
}
