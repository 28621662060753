import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';

import { MetaProviderService } from '../../core/services/meta-provider.service';

import { ENDPOINTS } from '../../const/endpoint.const';
import { CloudCatalogModel } from '@common/models/infraestructure/cloud-catalog.model';
import { CloudModel } from '@common/models/infraestructure/cloud.model';
import { BlockchainCatalogModel } from '@common/models/blockchain/blockchain-catalog.model';
import { RoleModel } from '@common/models/role/role.model';


import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SystemProviderService extends MetaProviderService {
  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }


  /**
   * Return the role types
   * @returns {Observable<any>}
   */
  getRoleTypes(): Observable<any> {
    return this.get(ENDPOINTS.SYSTEM.roles, null, RoleModel, true);
  }


  /**
   * Return the dictionaries availables for translation
   * @returns {Observable<any>}
   */
  getLanguages(): Observable<any> {
    return this.get(ENDPOINTS.SYSTEM.languages);
  }


  /**
   * Return the blockchain types
   * @returns {Observable<any>}
   */
  getBlockchainTypes(): Observable<any> {
    return this.get(ENDPOINTS.SYSTEM.blockchains, null, BlockchainCatalogModel, true);
  }



  /**
   * Return the cloud types
   * @returns {Observable<any>}
   */
  getCloudTypes(): Observable<any> {
    // @TODO: unmock cuando existe servicio en la API
    return of({
      data: new CloudCatalogModel(
        [
          new CloudModel({name: 'Azure', key: 'azure'}),
          new CloudModel({name: 'gcp', key: 'google_cloud'}),
          new CloudModel({name: 'aws', key: 'amazon'})
        ]
      )
    });
    return this.get(ENDPOINTS.SYSTEM.clouds, null, CloudCatalogModel, true);
  }
}
