import { Injectable, Injector } from '@angular/core';
import { Observable, concat } from 'rxjs';
import { last } from 'rxjs/operators';
import { AbstractIdentitiesProviderService } from '../shared/abstract-identities.provider.service';
import { IDENTITIES_ENDPOINTS } from './ethereum-endpoints.const';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EthereumIdentitiesProviderService extends AbstractIdentitiesProviderService {

  /** provider constructor */  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }

    /**
     * Create identity on a Network
     * @param {string} network network id where to create the identity
     * @param {string} id identifier used for identity
     * @param {any} info additional data reqired to create identity (public key, csr,  etc)
     * @returns {Observable<any>}
     */
    public createIdentity(network: string, id: string, info: any): Observable<any> {
      const data = {
        networkid: network,
        password: info.password
      };
      
      return concat(
        this.add(IDENTITIES_ENDPOINTS.createIdentity, data, null, true)
      ).pipe(
          last()
      );
    }

    /**
     * Save identity in platform
     * @param {string} network network id where to create the identity
     * @param {string} id identifier used for identity
     * @param {any} info additional data reqired to create identity (public key, csr,  etc)
     * @returns {Observable<any>}
     */
     public saveIdentity(network: string, label: string, account: string, password: string): Observable<any> {
      const data = {
        networkid: network,
        label: label,
        account: account,
        password: password
      };
      
      return concat(
        this.add(IDENTITIES_ENDPOINTS.saveIdentity, data, null, true)
      ).pipe(
          last()
      );
    }

    /**
     * Unlock identity on a Network
     * @param {string} network network id where to create the identity
     * @param {string} id identifier used for identity
     * @param {any} info additional data reqired to create identity (public key, csr,  etc)
     * @returns {Observable<any>}
     */
    public unlockIdentity(network: string, account: string, password: string): Observable<any> {
      const data = {
        networkid: network,
        account: account,
        password: password
      };
  
      return concat(
        this.add(IDENTITIES_ENDPOINTS.unlockIdentity, data, String, true, false)
      ).pipe(
          last()
      );
    }

    
    /**
     * Send transaction on a Network
     * @param {string} network network id where to create the identity
     * @param {string} id identifier used for identity
     * @param {any} info additional data reqired to create identity (public key, csr,  etc)
     * @returns {Observable<any>}
     */
    public sendTransaction(network: string, fromAddress: string, toAddress: string, amount: number): Observable<any> {
        const data = {
          networkid: network,
          fromAddress: fromAddress,
          toAddress: toAddress,
          amount: amount
        };
      return concat(
        this.add(IDENTITIES_ENDPOINTS.transfer, data, null, true, true)
      ).pipe(
          last()
      );
    }

  /**
     * Get identities list from a Network
     * @param {string} network network id from to get the identities list
     * @returns {Observable<any>}
     */
   public listIdentity(network: string): Observable<any> {
    const data = {
      networkid: network,
    };

    return concat(
      this.get(IDENTITIES_ENDPOINTS.listIdentity, data)
    ).pipe(
        last()
    );
  }

  /**
     * Get identities list from platform backend
     * @param {string} network network id from to get the identities list
     * @returns {Observable<any>}
     */
   public listIdentityInPlatform(network: string): Observable<any> {
    const data = {
      networkid: network,
    };

    return concat(
      this.get(IDENTITIES_ENDPOINTS.listIdentityBackend, data)
    ).pipe(
        last()
    );
  }

  /**
   * Get identities list from platform backend
   * @param {string} network network id from to get the identities list
   * @returns {Observable<any>}
   */
  public getSecrets(network: string): Observable<any> {
    const data = {
      networkid: network,
    };

    return concat(
      this.get(IDENTITIES_ENDPOINTS.listSecrets, data)
    ).pipe(
        last()
    );
  }
  

  /**
     * Get identities list from a Network
     * @param {string} network network id from to get the identities list
     * @param {string} id identifier used for identity
     * @returns {Observable<any>}
     */
   public deleteIdentity(network: string, id: string): Observable<any> {
    const query = {
      networkid: network
    };

    return concat(
      this.delete(IDENTITIES_ENDPOINTS.deleteIdentity, id, null, false, false, query)
    ).pipe(
        last()
    );
  }
}
