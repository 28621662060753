import { ConstructorModel } from '@common/core/models/constructor.model';

export class MonitorProfileModel extends ConstructorModel {
  genesis: string;
  channel: string;

  /** constructor */
  constructor(data?: object) {
    super();

    this.genesis = this.setString('genesis', data);
    this.channel = this.setString('channel', data);
  }
}


export class MonitorConfigTxModel extends ConstructorModel {
  profiles: MonitorProfileModel;
  file: string;
  /** constructor */
  constructor(data?: object) {
    super();

    this.profiles = this.setEntity('profiles', MonitorProfileModel, data);
    this.file = this.setString('file', data);
  }
}
