import { ConstructorModel } from '../../core/models/constructor.model';
import { PeerModel } from '../environment/peer.model';
import { EnvironmentModel } from '../environment/environment.model';

export class ContractInstalledItemModel extends ConstructorModel {
    peer: PeerModel;
    environment: EnvironmentModel;
    version: string;
    info: object;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.peer = this.setEntity('peer', PeerModel, data);
        this.environment = this.setEntity('environment', EnvironmentModel, data);
        this.version = this.setString('version', data);
        this.info = this.setObject('info', data);
    }
}
