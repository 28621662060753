import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { WorkspaceSessionComponent } from './workspace-session.component';
import { TableMaterialModule } from '@common/modules/table-material.module';


@NgModule({
  declarations: [WorkspaceSessionComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TableMaterialModule
  ],
  exports: [WorkspaceSessionComponent]
})
export class WorkspaceSessionModule { }
