import { ConstructorModel } from '@common/core/models/constructor.model';


export class MonitorCloudModel extends ConstructorModel {
    id: string;
    name: string;
    type: string;


    constructor(data?: object) {
        super();

        this.id = this.setString('id', data);
        this.name = this.setString('name', data);
        this.type = this.setString('type', data);
    }
    
}
