import { ConstructorModel } from '@common/core/models/constructor.model';

import _ from 'lodash';

export class WorkInfraestructureHistoricModel extends ConstructorModel {
  source: string;
  version: string;
  createdDate: string;

  /** constructor */
  constructor(data?: object) {
    super();

    this.source = this.setString('source', data);
    this.version = this.setString('version', data);
    this.createdDate = this.setDate('createdDate', data) || this.setDate('created_date', data);
  }
}
