import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatIconModule, MatCheckboxModule, MatButtonModule,
         MatSelectModule, MatCardModule, MatTooltipModule, MatSlideToggleModule } from '@angular/material';
import { BLMPipesModule } from '../pipes/pipes.module';

/** @ignore */
const MODULE_LIST = [
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatCheckboxModule,
  MatButtonModule,
  MatSelectModule,
  MatCardModule,
  MatTooltipModule,
  MatSlideToggleModule,
  BLMPipesModule
];

@NgModule({
  declarations: [],
  imports: MODULE_LIST,
  exports: MODULE_LIST
})
export class FormMaterialModule { }
