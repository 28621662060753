import { Pipe, PipeTransform } from '@angular/core';

import _ from 'lodash';

/** Pipe for datatable cells */
@Pipe({
  name: 'BLMcell'
})
export class BLMCellPipe implements PipeTransform {

  transform(value: any, cell: any): any {
    return !_.isNil(cell) && !_.isEmpty(cell) ? value : '';
  }
}
