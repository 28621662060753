import { ConstructorModel } from '@common/core/models/constructor.model';

import _ from 'lodash';



export class BPElementModel extends ConstructorModel {
    element: string;
    parent: string;
    shapeId: string;

    /** model constructor */
    constructor(data?: object) {
      super();

      this.element = this.setString('element', data);
      this.parent = this.setString('parent', data);
      this.shapeId = this.setString('shapeId', data);
    }
}
