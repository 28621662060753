import { ConstructorModel } from '../../core/models/constructor.model';
import { UserModel } from '../user/user.model';
import { SettingModel } from './setting.model';
import { getBlockchainImage, getBlockchainIcon } from '../../libs/blockchain';


import _ from 'lodash';

export class EnvironmentModel extends ConstructorModel {
    _id: string;
    type: string;
    name: string;
    description: string;
    ip: string;
    port: string;
    users: Array<UserModel>;
    settings: Array<SettingModel>;
    blockchain: string;
    credentials: Array<any>;
    logo: string;
    icon: string;

    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.type = this.setString('type', data);
        this.name = this.setString('name_env', data, null) || this.setString('name', data);
        this.description = this.setString('description', data);
        this.ip = this.setString('ip', data);
        this.port = this.setString('port', data);
        this.users = this.setEntityList('users', UserModel, data);
        this.settings = this.setEntityList('settings', SettingModel, data);
        this.blockchain = this.setString('blockchain_type', data, null) || this.setString('blockchain', data, null);
        this.credentials = this.setList('credentials', data);

        this.logo = getBlockchainImage(this.blockchain);
        this.icon = getBlockchainIcon(this.blockchain);
    }
}
