import { ConstructorModel } from '../../core/models/constructor.model';
import { RoleModel } from '../role/role.model';
import { WorkgroupModel } from '../workspace/workgroup.model';
import { WorkspaceModel } from '../workspace/workspace.model';

import _ from 'lodash';


export class UserModel extends ConstructorModel {
    _id: string;
    name: string;
    username: string;
    password: string;
    memorable: string;
    use2fa: boolean;
    surname: string;
    language: string;
    registerDate: string;
    role: RoleModel;

    workgroup: WorkgroupModel;
    workspaces: Array<WorkspaceModel>;
    lastWorkspace: WorkspaceModel;
    cloudCredentials: object;


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.username = this.setString('username', data);
        this.password = this.setString('password', data);
        this.memorable = this.setString('memorable', data);
        this.use2fa = this.setBoolean('use2fa', data);
        this.surname = this.setString('surname', data);
        this.language = this.setString('blm_language', data, null) || this.setString('language', data, null);
        this.registerDate = this.setDate('register_date', data) || this.setDate('registerDate', data);
        this.role = this.setEntity('role', RoleModel, data);
        this.workgroup = this.setEntity('workgroup', WorkgroupModel, data);
        this.workspaces = this.setEntityList('workspaces', WorkspaceModel, data);
        this.lastWorkspace = this.setEntity('lastWorkspace', WorkspaceModel, data, null) || this.setEntity('last_workspace', WorkspaceModel, data);
    }


    public getFullName(): string {
        return _.trim([this.name, this.surname].join(' '));
    }
}
