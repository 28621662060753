import _ from 'lodash';

/** returns the blockchain image associated */
export function getBlockchainImage(blockchain: string) {
    if (_.isNil(blockchain) || blockchain === '') {
        return null;
    }

    return './assets/images/blockchain/' + _.lowerCase(blockchain) + '-logo.png';
}

/** returns the blockchain icon associated */
export function getBlockchainIcon(blockchain: string) {
  if (_.isNil(blockchain) || blockchain === '') {
      return null;
  }

  return './assets/images/blockchain/' + _.lowerCase(blockchain) + '-icon.png';
}


/** returns the cloud technologiy image associated */
export function getCloudImage(cloud: string) {
  if (_.isNil(cloud) || cloud === '') {
      return null;
  }

  return './assets/images/cloud/logos/' + _.lowerCase(cloud) + '-logo.png';
}


/** returns the language image associated */
export function getLanguageImage(language: string) {
  if (_.isNil(language) || language === '') {
    return null;
  }

  return {
    javascript: './assets/images/language/javascript.png',
    golang: './assets/images/language/golang.png',
    go: './assets/images/language/golang.png',
    node: './assets/images/language/node.png',
    solidity: './assets/images/language/solidity.png'
  }[_.lowerCase(_.trim(language))];
}

/** returns the language icon associated */
export function getLanguageIcon(language: string): string {
  if (_.isNil(language) || language === '') {
    return null;
  }

  return {
    javascript: './assets/images/language/javascript-icon.png',
    golang: './assets/images/language/golang-icon.png',
    go: './assets/images/language/golang-icon.png',
    node: './assets/images/language/node-icon.png',
    solidity: './assets/images/language/solidity-icon.png'
  }[_.lowerCase(_.trim(language))];
}
