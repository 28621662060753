import { ConstructorModel } from '@common/core/models/constructor.model';


export class MonitorChannelModel extends ConstructorModel {
  name: string;
  peers: Array<string>;

  /** constructor */
  constructor(data?: object) {
    super();

    this.name = this.setString('name', data);
    this.peers = this.setList('peers', data);
  }
}
