import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatButtonModule, MatDialogModule } from '@angular/material';

import {MatSnackBarModule} from '@angular/material/snack-bar';

// CUSTOM MODULES
import { SignModule } from './signModule/sign.module';
import { PanelModule } from './panelModule/panel.module';
import { LoaderNavigationModule } from './common/components/loader-navigation/loader-navigation.module';
import { LoaderModule } from './common/components/loader/loader.module';
import { BLMPipesModule } from './common/pipes/pipes.module';
import { DirectivesModule } from './common/directives/directives.module';


// CUSTOM SERVICES
import { HttpService } from './common/core/services/http.service';
import { AlertService } from './common/services/shared/alert.service';
import { SessionService } from './common/services/shared/session.service';
import { LoaderService } from './common/services/shared/loader.service';
import { FeaturesProviderService } from './common/services/shared/features-provider.service';


// COMPONENTS
import { AppComponent } from './app.component';
import { MetaSimpleComponent } from './common/core/components/meta-simple.component';
import { MetaFormComponent } from './common/core/components/meta-form.component';
import { MetaListComponent } from './common/core/components/meta-list.component';
import { MetaPanelComponent } from './common/core/components/meta-panel.component';
import { ConfirmDialogComponent } from './common/components/confirm-dialog/confirm-dialog.component';


// LOCALE REGISTRATION
import localeES from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';

// THIRD PARTY MODULES
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';



// BLOCKCHAIN FACTORY SERVICES
import { FabricBlockchainProviderService } from './common/blockchains/fabric/fabric-blockchain.provider.service';
import { EthereumBlockchainProviderService } from './common/blockchains/ethereum/ethereum-blockchain.provider.service';
import { HttpDeployModuleService } from '@common/core/services/http.deploy.module.service';
import { MetricsDialogComponent } from './panelModule/infraestructure/infrastructure-monitoring/machine-monitoring/metrics-dialog/metrics-dialog.component';
import { PopupCloudComponent } from '@common/components/monitor/popup-cloud/popup-cloud.component';
import { FormMaterialModule } from '@common/modules/form-material.module';
import {MatTabsModule} from '@angular/material/tabs';
import { PopupInfraErrorComponent } from '@common/components/monitor/popup-infra-error/popup-infra-error.component';

// IDENTITIES SERVICES
import { HyperledgerIdentitiesProviderService } from './common/identities/hyperledger-fabric/hyperledger-fabric.provider.service';

import { EthereumIdentitiesProviderService } from './common/identities/ethereum/ethereum.provider.service';
import { InfraestructureIdentitiesEthereumTransferComponent } from './panelModule/infraestructure/infraestructure-identities-ethereum/infraestructure-identities-ethereum-transfer/infraestructure-identities-ethereum-transfer.component';
// import { InfraestructureIdentitiesEthereumCreateComponent } from './panelModule/infraestructure/infraestructure-identities-ethereum/infraestructure-identities-ethereum-create/infraestructure-identities-ethereum-create.component';

/** @ignore */
const ENVIRONMENT = environment;
/** @ignore */
registerLocaleData(localeES);


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


const APP_ROUTES: Routes = [
  { path: '', component: AppComponent },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  declarations: [
    AppComponent,
    MetaSimpleComponent,
    MetaFormComponent,
    MetaListComponent,
    MetaPanelComponent,
    ConfirmDialogComponent,
    MetricsDialogComponent,
    PopupCloudComponent,
    PopupInfraErrorComponent,
    InfraestructureIdentitiesEthereumTransferComponent,
    // InfraestructureIdentitiesEthereumCreateComponent
    //AddWorkspaceComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    LoaderNavigationModule,
    LoaderModule,
    MatSnackBarModule,
    MatDialogModule,
    FormMaterialModule,
    MatButtonModule,
    MatTabsModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: false }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        }
    }),
    BrowserAnimationsModule,
    SignModule,
    PanelModule,
    BLMPipesModule,
    DirectivesModule,
    MonacoEditorModule,
  ],
  providers: [
    HttpService,
    HttpDeployModuleService,
    AlertService,
    SessionService,
    LoaderService,
    FeaturesProviderService,
    { provide: LOCALE_ID, useValue: ENVIRONMENT.language.default },
    { provide: 'EthereumBlockchainProviderService', useExisting: EthereumBlockchainProviderService},
    { provide: 'FabricBlockchainProviderService', useExisting: FabricBlockchainProviderService},
    { provide: 'FabricIdentitiesProviderService', useExisting: HyperledgerIdentitiesProviderService},
    { provide: 'EthereumIdentitiesProviderService', useExisting: EthereumIdentitiesProviderService}
  ],
  entryComponents: [ 
    ConfirmDialogComponent, 
    MetricsDialogComponent, 
    PopupCloudComponent,
    // InfraestructureIdentitiesEthereumCreateComponent, 
    InfraestructureIdentitiesEthereumTransferComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
