import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

/** @deprecated */
@Component({
  selector: 'app-navbar-header',
  templateUrl: './navbar-header.component.html',
  styleUrls: ['./navbar-header.component.scss']
})
export class NavbarHeaderComponent implements OnInit, OnChanges {
  @Input() show: boolean = true;
  /** constructor */
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

}
