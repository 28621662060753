import { SignComponent } from './sign.component';
import { Routes } from '@angular/router';
import { HomeGuard } from '../common/guards/home.guard';


export const SIGN_ROUTES: Routes = [
    {
        path: '',
        component: SignComponent,
        canActivate: [HomeGuard],
        children: [
            { path: '', redirectTo: 'login', pathMatch: 'full' },
            { path: 'login', loadChildren: 'app/signModule/signIn/sign-in.module#SignInModule'}
        ]
    }
];
