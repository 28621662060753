import { ConstructorModel } from '@common/core/models/constructor.model';
import { BlockchainModel } from './blockchain.model';

import _ from 'lodash';

export class BlockchainCatalogModel extends ConstructorModel {
    catalog: Array<BlockchainModel>;

    /** model constructor */
    constructor(data?: object | Array<object>) {
        super();

        this.catalog = [];

        if (_.isArray(data)) {
            _.forEach(data, (blockData: object) => {
                this.catalog.push(new BlockchainModel(blockData));
            });

        } else {
            _.forEach(data, (blockData: object, name: string) => {
                const blockDataExtend = Object.assign({name: name}, blockData);
                this.catalog.push(new BlockchainModel(blockDataExtend));
            });
        }
    }


    /****************** PUBLIC METHODS *************************/
    /**
     * Return a BlockchainModel according to blockchain type
     * @param {string} value: blockchain name or key or abbreviation
     * @returns {BlockchainModel}
     */
    public getBlockchain(value: string): BlockchainModel {
        return _.find(this.catalog, (blockchain: BlockchainModel) => {
            const regExName = new RegExp('^' + (blockchain.name || '') + '$', 'i');
            const regExKey = new RegExp('^' + (blockchain.key || '') + '$', 'i');
            const regExAbb = new RegExp('^' + (blockchain.abbreviation || '') + '$', 'i');

            return regExName.test(value) || regExKey.test(value) || regExAbb.test(value);
        });
    }


    /**
     * Returns if exists in the catalog the blockchain
     * @param {string} value: blockchain name or key or abbreviation
     * @returns {boolean}
     */
    public hasBlockchain(value: string): boolean {
        return !_.isNil(this.getBlockchain(value));
    }



    /**
     * Returns the blockchain string list available in the catalog
     * @param {Array<BlockchainModel>} [catalog]
     * @returns {Array<string>}
     */
    public getBlockchainList(catalog?: Array<BlockchainModel>): Array<string> {
        const list = [];
        const tCatalog = catalog ? catalog : this.catalog;

        tCatalog.forEach((blockchain: BlockchainModel) => {
            list.push(blockchain.name);
        });

        return list;
    }



    /**
     * Returns a blockchain list filtered by language
     * @param {string | Array<string>} language
     * @returns {Array<string>}
     */
    public filterBlockchainByLanguage(language: string | Array<string>): Array<BlockchainModel> {
      const languageList = _.isArray(language) ? language : [language];

      return _.filter(this.catalog, (blockchain: BlockchainModel) =>
        _.intersection(blockchain.languages, languageList).length > 0
      );
    }


    /**
     * Returns the blockchain string list available in the catalog filtered by languages
     * @param {string | Array<string>} language
     * @returns {Array<string>}
     */
    public getBlockchainListFilteredByLanguage(language: string | Array<string>): Array<string> {
      return this.getBlockchainList(this.filterBlockchainByLanguage(language));
  }
}
