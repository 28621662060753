import { ConstructorModel } from '@common/core/models/constructor.model';
import { RoleModel } from '../role/role.model';
import { UserModel } from '../user/user.model';


export class WorkspaceMemberModel extends ConstructorModel {
    user: UserModel;
    role: RoleModel;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.user = this.setEntity('user', UserModel, data);
        this.role = this.setEntity('role', RoleModel, data);
    }
}
