// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * localhost environment
 */
export const environment = {
  production: false,
  logs: true,
  language: {
    default: 'en',
    list: ['en', 'es']
  },
  API: {
    // url: '/api/',
    url: '/api/',
    basicToken: '',
    enableLangParam: false
  },
  API_MODULE_DEPLOY: {
    url: '/api/orquestador/',
    basicToken: '',
    enableLangParam: false,
  },
  API_K1: {
    url: '/api/',
    basicToken: '',
    enableLangParam: false
  },
  features: {
    creation: {
      infraestructure: {
        enable: true,
        developing: true // disabled reason
      }
    },
    edition: {
      diagram: { // canva actions in diagram BPMN
        enable: true,
        developing: true // disabled reason
      },
      infraestructure: {
        enable: true,
        developing: true // disabled reason
      }
    },
    deployment: {
      diagram: { // deploy button in diagram BPMN
        enable: true,
        developing: true // disabled reason
      },
      smartcontract: { // deploy button in code editor
        enable: true,
        developing: true // disabled reason
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
