import { Component, OnInit, ViewEncapsulation, OnDestroy, Injector, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { SessionService } from '@common/services/shared/session.service';
import { AuthenticationProviderService } from '@common/services/providers/authentication-provider.service';

import { MetaSimpleComponent } from '@common/core/components/meta-simple.component';

import { MenuModel } from '@common/models/navigation/menu.model';
import { ItemMenuModel } from '@common/models/navigation/item-menu.model';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { SessionModel } from '@common/models/session/session.model';
import { LATERAL_NAV, STYLE } from '@common/const/system.const';


import _ from 'lodash';

/**
 * lateral menu
 */
@Component({
  selector: 'app-navbar-lateral',
  templateUrl: './navbar-lateral.component.html',
  styleUrls: ['./navbar-lateral.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarLateralComponent extends MetaSimpleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() workspaceList: Array<WorkspaceModel> = [];
  @Output() changeWorkspace = new EventEmitter();
  @Output() callback = new EventEmitter();

  public menu: MenuModel;
  public styleSetup = STYLE.lateralNav;

  public workspaceComboOptions: Array<WorkspaceModel> = [];
  public workspaceComboControl: FormControl;

  private workspaceSelected: WorkspaceModel = new WorkspaceModel({_id: null});
  /** constructor */
  constructor(private _injector: Injector,
              private _router: Router,
              private _authSrv: AuthenticationProviderService,
              private _sessionSrv: SessionService) {
    super(_injector);

    this.menu = this._sessionSrv.getMenu();
    this.workspaceSelected = this._sessionSrv.getWorkspaceSession();
    this.workspaceComboControl = new FormControl({value: this.workspaceSelected._id, disabled: true});

    this.startChangeWorkspaceComboSubscription();
    this.onChangeSessionSubscription();
  }




  ngOnInit() { }


  ngOnChanges(changes: SimpleChanges) {
    if (!_.isNil(_.get(changes, 'workspaceList.currentValue'))) {
      this.generateComboOptions();
    }
  }


  ngOnDestroy() {
    this.unsubscribeOnDestroy();
  }


  reset() {
    this.stopChangeWorkspaceComboSubscription();
    this.workspaceSelected = this._sessionSrv.getWorkspaceSession();
    this.workspaceComboControl.setValue(this.workspaceSelected._id);
    this.workspaceSessionToTop();
    this.startChangeWorkspaceComboSubscription();
  }


  /************************* DOM MEHTODs **********************************/
  public onClickItem(item: ItemMenuModel) {
    typeof this[item.callback] === 'function'
      ? this[item.callback]()
      : this.callback.emit({action: item.callback});
  }


  /************************* PRIVATE METHODS ******************************/

  /**
   * Function call from itemMenu callback
   */
  private goToProfile() {;
    this._router.navigateByUrl('panel/profile');
  }



  /**
   *
   * @param {string | any} workspaceId
   */
  private updateActualWorkspace(workspaceId: string | any) {
    if (_.isNil(workspaceId) || workspaceId === '' || workspaceId === 0) {
      this.workspaceComboControl.setValue(this.workspaceSelected._id);
      this.changeWorkspace.emit({value: null});

    } else {
      this.changeWorkspace.emit({value: _.find(this.workspaceList, {'_id': workspaceId})});
    }
  }



  /**
   * Set the actual workspace to top on list
   */
  private workspaceSessionToTop() {
    if (this.workspaceSelected.hasValidId() && this.workspaceComboOptions.length > 0) {
      const workspaceSelected = this.workspaceComboOptions.find(workspace => workspace._id === this.workspaceSelected._id);
      this.workspaceComboOptions = _.take(_.unionBy([workspaceSelected], this.workspaceComboOptions, '_id'), LATERAL_NAV.TOP_WORSPACE);
    }
  }


  /**
   * fill the combo list of workspaces
   */
  private generateComboOptions() {
    this.workspaceComboOptions = _.take(this.workspaceList, LATERAL_NAV.TOP_WORSPACE);

    this.workspaceSessionToTop();
    this.workspaceComboControl.enable();
  }




  /**
   * Subscription for workspace combo
   */
  private startChangeWorkspaceComboSubscription() {
    this.subcriptionOnDestroy$['wsControl'] = this.workspaceComboControl.valueChanges.subscribe(
      (value: string | number) => {
        if (_.isNil(value) || value === this.workspaceSelected._id) {
          return;
        }

        this.updateActualWorkspace(value);
      }
    );
  }


  private stopChangeWorkspaceComboSubscription() {
    if (!_.isNil(this.subcriptionOnDestroy$['wsControl']) && !this.subcriptionOnDestroy$['wsControl'].closed) {
      this.subcriptionOnDestroy$['wsControl'].unsubscribe();
    }
  }


  /**
   * Subscription for session changes
   */
  private onChangeSessionSubscription() {
    this.subcriptionOnDestroy$['session'] = this._sessionSrv.onChange().subscribe(
      (session: SessionModel) => {
        if (this.workspaceSelected._id !== _.get(session, 'lastWorkspace._id')) {
          this.workspaceSelected = session.lastWorkspace;
          this.workspaceComboControl.setValue(this.workspaceSelected._id);
        }

        this.workspaceSessionToTop();
      }
    );
  }



  /*********************** API METHODS **********************/
  /**
   * Function call from itemMenu callback
   */
  private logout() {
    if (!_.isNil(this.userLogged)) {
      this.subcriptionApi$['logout'] = this._authSrv.logout().subscribe(
        (response) => {},
        (error) => {
          console.log(error);
          this._sessionSrv.endSession();
          this._router.navigateByUrl('login');
        },
        () => {
          this._sessionSrv.endSession();
          this._router.navigateByUrl('login');
        }
      );
    } else {
      this._sessionSrv.endSession();
      this._router.navigateByUrl('login');
    }
  }
}
