import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable, TimeoutError, of, throwError } from 'rxjs';
import { timeout, catchError, map } from 'rxjs/operators';


import { AlertService } from '../../services/shared/alert.service';
import { SessionService } from '../../services/shared/session.service';
import { LoaderService } from '../../services/shared/loader.service';

import { environment } from '../../../../environments/environment';
import { SYSTEM_API } from '../../const/system.const';



import _ from 'lodash';
import { HttpServiceAbstract } from './abstract.http.service';

/** @ignore */
const ENVIRONMENT = environment;


/**
 * Custom http Service.
 * Use the environment configuration parameters to build the requests (API url, token, timeout, etc) as well to parse the response
 */
// @Injectable()
export class HttpService extends HttpServiceAbstract {
  /** @ignore */
  protected _urlServer: string = ENVIRONMENT.API.url;
}
