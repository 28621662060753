
import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-popup-infra-error',
  templateUrl: './popup-infra-error.component.html',
  styleUrls: ['./popup-infra-error.component.scss']
})
export class PopupInfraErrorComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<PopupInfraErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
