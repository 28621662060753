import { AbstractControl, ValidatorFn } from '@angular/forms';

import _ from 'lodash';


/**
 * FormControl validator for values whitout white spaces
 * @param control
 */
export function ValidateNonWhiteSpaces(control: AbstractControl) {
  const regEx = new RegExp(' ', 'g');

  return control.hasOwnProperty('value') && !regEx.test(control.value)
    ? null
    : { whitespaces: true };
}


/**
 * FormControl validator for alphanumeric values
 * @param control
 */
export function AlphanumericValidator(control: AbstractControl) {
  const regEx = new RegExp('^[a-zA-Z0-9_]+$', 'g');

  return control.hasOwnProperty('value') && regEx.test(control.value)
    ? null
    : { alphanumeric: true };
}


/**
 * FormControl validator for alphanumeric and special characters values
 * @param control
 */
export function AlphanumericAndHyphenValidator(control: AbstractControl) {
  const regEx = new RegExp('^[a-zA-Z0-9_-]+$', 'g');

  return control.hasOwnProperty('value') && regEx.test(control.value)
    ? null
    : { alphanumericHyphen: true };
}


/**
 * FormControl validator for numbers values distinct to zero
 * @param control
 */
export function nonZeroValidator(control: AbstractControl) {
  return control.hasOwnProperty('value') && _.isNumber(control.value) && control.value !== 0
    ? null
    : { requiredNonZero: true };
}


/**
 * FormControl validator for number values
 * @param control
 */
export function numberValidator(control: AbstractControl) {
  return control.hasOwnProperty('value') && _.isNumber(control.value)
    ? null
    : { requiredNumber: true };
}


/**
 * FormControl validator for values with typeof object
 * @param control
 */
export function requiredObject(control: AbstractControl) {
  return control.hasOwnProperty('value') && _.isObject(control.value) && !_.isArray(control.value)
      ? null
      : { requiredObject: true };
}


/**
 * FormControl validator for values with variable pattern
 * @param control
 */
export function variableNameTest(value: string): boolean {
  const regEx = new RegExp('^[a-zA-Z_]+[a-zA-Z0-9_]*$', 'g');

  return regEx.test(value);
}


/**
 * FormControl validator for name values
 * @param control
 */
export function VariableNameValidator(control: AbstractControl) {
  return control.hasOwnProperty('value') && variableNameTest(control.value)
    ? null
    : { variableName: true };
}


/**
 * FormControl validator for unique values
 * @param control
 */
export function UniqueValidator(list: any, key: string = '_id', insensitive: boolean = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const unique = _.isNil(_.find(list, (item) =>
      insensitive
        ? _.lowerCase(_.get(item, key)) === _.lowerCase(_.get(control, 'value'))
        : _.get(item, key) === _.get(control, 'value'))
    );

    return unique
      ? null
      : { uniqueText: true };
  };
}
