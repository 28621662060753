export const BLOCKCHAIN_ENDPOINTS = {
    switchEnv: 'k2/blockchain/switchEnvironment/:id', // put
    deploy: 'k2/blockchain/deploySmartContract/:id', // put
    invoke: 'k2/blockchain/invokeSmartContract', // post
    getInstalled: 'k2/blockchain/queryInstalledChaincodes/:peer', // get
    getInstantiated: 'k2/blockchain/queryInstantiatedChaincodes/:peer/:channel', // get
    getInfoWallet: 'k2/blockchain/listInfoWallet', // get
    getPeers: 'k2/blockchain/getPeersForOrgOnChannel', // get
    getChannels: 'k2/blockchain/queryChannelsOnPeer/:peer', // get
    getInfoDiscovery: 'k2/blockchain/infoDiscovery/:channel', // get
    deleteUser: 'k2/blockchain/deleteUser', // get
    registerUser: 'k2/blockchain/registerUserHyp', // post
    enrollAdmin: 'k2/blockchain/enrollAdmin' // post
}