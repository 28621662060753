import { ConstructorModel } from '../../core/models/constructor.model';
import { ChannelModel } from '../environment/channel.model';
import { EnvironmentModel } from '../environment/environment.model';

export class ContractInstantiatedItemModel extends ConstructorModel {
    channel: ChannelModel;
    environment: EnvironmentModel;
    version: string;
    info: object;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.channel = this.setEntity('channel', ChannelModel, data);
        this.environment = this.setEntity('environment', EnvironmentModel, data);
        this.version = this.setString('version', data);
        this.info = this.setObject('info', data);
    }
}
