import { ConstructorModel } from '../../core/models/constructor.model';
import { UserModel } from '../user/user.model';
import { BusinessProcessModel } from '../business-process/business-process.model';
import { WorkFabricInfraestructureModel } from '../infraestructure/work-infraestructure.model';
import { WorkContractModel } from '../contract/work-contract.model';
import { getBlockchainImage, getBlockchainIcon } from '@common/libs/blockchain';
import { WorkspaceMemberModel } from './workspace-member.model';
import { _ } from 'lodash';
import { WorkspaceCloudModel } from './workspace-cloud.model';

export class WorkspaceModel extends ConstructorModel {
    _id: string;
    name: string;
    description: string;
    owner: UserModel;
    createdDate: string;
    updatedDate: string;
    members: Array<WorkspaceMemberModel>;
    work_clouds: Array<WorkspaceCloudModel>;
    bp: BusinessProcessModel;
    workInfrastructures: Array<WorkFabricInfraestructureModel>;
    workContracts: Array<WorkContractModel>;


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.description = this.setString('description', data);
        this.owner = this.setEntity('owner', UserModel, data);
        this.createdDate = this.setDate('createdDate', data) || this.setDate('created_date', data);
        this.updatedDate = this.setDate('updatedDate', data) || this.setDate('updated_date', data);
        this.members = this.setEntityList('members', WorkspaceMemberModel, data);
        this.work_clouds = this.setEntityList('work_clouds', WorkspaceCloudModel, data);
        this.bp = this.setEntity('bp', BusinessProcessModel, data);
        this.workInfrastructures = this.setEntity('workInfrastructures', WorkFabricInfraestructureModel, data)
          || this.setEntity('work_infrastructures', WorkFabricInfraestructureModel, data);
        this.workContracts = this.setEntity('workContracts', WorkContractModel, data)
          || this.setEntity('work_contracts', WorkContractModel, data);
    }


    public getBlockchainList(): Array<string> {
      return _.uniq(_.map(this.workInfrastructures, 'blockchainType'));
    }


    public getLogoList(): Array<string> {
      return _.map(this.getBlockchainList(), (blockchain: string) => getBlockchainImage(blockchain));
    }


    public getIconList(): Array<string> {
      return _.map(this.getBlockchainList(), (blockchain: string) => getBlockchainIcon(blockchain));
    }
}
