import { PanelComponent } from './panel.component';
import { Routes } from '@angular/router';
import { AuthGuard } from '../common/guards/auth.guard';
import { ProfileGuard } from '../common/guards/profile.guard';


export const PANEL_ROUTES: Routes = [
    {
        path: 'panel',
        component: PanelComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', loadChildren: 'app/panelModule/home/home.module#HomeModule'},
            { path: 'profile', loadChildren: 'app/panelModule/profile/profile.module#ProfileModule'},
            { path: 'help', loadChildren: 'app/panelModule/help/help.module#HelpModule'},
            { path: 'account', children: [
                { path: '', redirectTo: 'list', pathMatch: 'full'},
                { path: 'list', loadChildren: 'app/panelModule/accounts/account-list/account-list.module#AccountListModule'},
                { path: 'new', loadChildren: 'app/panelModule/accounts/account-edit/account-edit.module#AccountEditModule'},
                { path: ':id', canActivate: [ProfileGuard], loadChildren: 'app/panelModule/accounts/account-edit/account-edit.module#AccountEditModule'}
            ]},
            { path: 'smartcontract', children: [
                { path: '', redirectTo: 'list', pathMatch: 'full'},
                { path: 'list', loadChildren: 'app/panelModule/smartcontract/smartcontract-list/smartcontract-list.module#SmartcontractListModule'},
                { path: 'template', children: [
                    { path: '', loadChildren: 'app/panelModule/smartcontract/template-list/template-list.module#TemplateListModule'},
                    { path: 'new', children: [
                        { path: '', loadChildren: 'app/panelModule/smartcontract/template-menu/template-menu.module#TemplateMenuModule'},
                        { path: ':type', loadChildren: 'app/panelModule/smartcontract/template-edit/template-edit.module#TemplateEditModule'}
                      ]
                    },
                    { path: ':id', loadChildren: 'app/panelModule/smartcontract/template-edit/template-edit.module#TemplateEditModule'}
                  ]
                },
                { path: 'new', children: [
                    { path: '', loadChildren: 'app/panelModule/smartcontract/smartcontract-menu/smartcontract-menu.module#SmartcontractMenuModule'},
                    { path: ':type', loadChildren: 'app/panelModule/smartcontract/smartcontract-edit/smartcontract-edit.module#SmartcontractEditModule'}
                ]},
                { path: ':id', loadChildren: 'app/panelModule/smartcontract/smartcontract-edit/smartcontract-edit.module#SmartcontractEditModule'}
            ]},
            { path: 'businessprocess', children: [
                { path: '', redirectTo: 'list', pathMatch: 'full'},
                { path: 'list', loadChildren: 'app/panelModule/business-process/business-process-list/business-process-list.module#BusinessProcessListModule', data: { new: false }},
                { path: 'new', loadChildren: 'app/panelModule/business-process/business-process-list/business-process-list.module#BusinessProcessListModule', data: { new: true }},
                { path: 'template', loadChildren: 'app/panelModule/business-process/business-process-list/business-process-list.module#BusinessProcessListModule', data: { new: false, template: true }},
                { path: ':id', children: [
                    { path: '', loadChildren: 'app/panelModule/business-process/business-process-detail/business-process-detail.module#BusinessProcessDetailModule' },
                    { path: 'diagram', loadChildren: 'app/panelModule/business-process/business-process-editor/business-process-editor.module#BusinessProcessEditorModule'},
                    { path: 'deployment', children: [
                        { path: '', loadChildren: 'app/panelModule/deployment/deployment-wizard/deployment-wizard.module#DeploymentWizardModule'},
                        { path: ':envId', loadChildren: 'app/panelModule/deployment/deployment-wizard/deployment-wizard.module#DeploymentWizardModule'}
                    ]}
                ]}
            ]},
            { path: 'infraestructure', children: [
                { path: '', redirectTo: 'list', pathMatch: 'full'},
                { path: 'list', loadChildren: 'app/panelModule/infraestructure/infraestructure-list/infraestructure-list.module#InfraestructureListModule'},
                { path: 'new', loadChildren: 'app/panelModule/infraestructure/infraestructure-list/infraestructure-list.module#InfraestructureListModule', data: { new: true }},
                { path: 'fabric', children: [
                    { path: '', loadChildren: 'app/panelModule/infraestructure/infraestructure-monitor/infraestructure-monitor.module#InfraestructureMonitorModule', data: { new: false }},
                    { path: ':id', loadChildren: 'app/panelModule/infraestructure/infraestructure-monitor/infraestructure-monitor.module#InfraestructureMonitorModule', data: { new: false }},
                    { path: ':id/identities', children: [
                        { path: '', redirectTo: 'list', pathMatch: 'full'},
                        { path: 'list', loadChildren: 'app/panelModule/infraestructure/infraestructure-identities/infraestructure-identities-list.module#InfraestructureIdentitiesListModule', data: { new: false }},
                        { path: 'new', loadChildren: 'app/panelModule/infraestructure/infraestructure-identities/infraestructure-identities-create/infraestructure-identities-create.module#InfraestructureIdentitiesModule', data: { new: false }}
                    ]}
                ]},
                { path: 'ethereum', children: [
                    { path: '', loadChildren: 'app/panelModule/infraestructure/infraestructure-monitor-ethereum/infraestructure-monitor-ethereum.module#InfraestructureMonitorEthereumModule', data: { new: false }},
                    { path: ':id', loadChildren: 'app/panelModule/infraestructure/infraestructure-monitor-ethereum/infraestructure-monitor-ethereum.module#InfraestructureMonitorEthereumModule', data: { new: false }},
                    { path: ':id/identities', children: [
                        { path: '', redirectTo: 'list', pathMatch: 'full'},
                        { path: 'list', loadChildren: 'app/panelModule/infraestructure/infraestructure-identities-ethereum/infraestructure-identities-ethereum-list.module#InfraestructureIdentitiesListEthereumModule', data: { new: false }},
                        { path: 'new', loadChildren: 'app/panelModule/infraestructure/infraestructure-identities-ethereum/infraestructure-identities-ethereum-create/infraestructure-identities-ethereum-create.module#InfraestructureIdentitiesEthereumModule', data: { new: false }}
                    ]}
                ]},
                { path: ':id/monitoring', loadChildren: 'app/panelModule/infraestructure/infrastructure-monitoring/infrastructure-monitoring.module#InfrastructureMonitoringModule', data: { new: false }},
            ]},
            { path: 'workspace', children: [
                { path: '', redirectTo: 'list', pathMatch: 'full'},
                { path: 'list', loadChildren: 'app/panelModule/workspace/workspace-list/workspace-list.module#WorkspaceListModule'},
                { path: 'new', loadChildren: 'app/panelModule/workspace/workspace-edit/workspace-edit.module#WorkspaceEditModule'},
                { path: ':id', canActivate: [ProfileGuard], loadChildren: 'app/panelModule/workspace/workspace-edit/workspace-edit.module#WorkspaceEditModule'}
            ]},
            { path: 'forms', children: [
                { path: '', redirectTo: 'hsm', pathMatch: 'full'},
                { path: 'hsm', loadChildren: 'app/panelModule/forms/hsm/hsm.module#HsmModule'},
                { path: 'ldap', loadChildren: 'app/panelModule/forms/ldap/ldap.module#LdapModule'}
            ]},
            { path: 'cloudwizard', children: [
                { path: '', redirectTo: 'new', pathMatch: 'full'},
                { path: 'list', loadChildren: 'app/panelModule/cloudwizard/cloudwizard-list/cloudwizard-list.module#CloudwizardListModule'},
                { path: 'new', children: [
                    { path: '', loadChildren: 'app/panelModule/cloudwizard/cloudwizard-menu/cloudwizard-menu.module#CloudwizardMenuModule'},
                    { path: ':type', loadChildren: 'app/panelModule/cloudwizard/cloudwizard-edit/cloudwizard-edit.module#CloudwizardEditModule'}
                ]},
                { path: ':id', loadChildren: 'app/panelModule/cloudwizard/cloudwizard-edit/cloudwizard-edit.module#CloudwizardEditModule'},
            ]}
        ]
    }
];
