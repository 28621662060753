import { ConstructorModel } from '@common/core/models/constructor.model';

import _ from 'lodash';

export class DeploymentActionModel extends ConstructorModel {

  delete: boolean;
  undo: boolean;
  version: string;
  hash: string;

  /** constructor */
  constructor(data?: object) {
    super();

    this.delete = this.setBoolean('delete', data);
    this.undo = this.setBoolean('undo', data);
    this.version = this.setString('version', data);
    this.hash = this.setString('hash', data);
  }


  isEmptyAction(): boolean {
    return this.isEqual(new DeploymentActionModel());
  }
}
