import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignComponent } from './sign.component';
import { SIGN_ROUTES } from './sign.routes';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SignComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(SIGN_ROUTES)
  ]
})
export class SignModule { }
