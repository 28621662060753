import { ConstructorModel } from '../../core/models/constructor.model';
import { ContractAPIMethodModel } from './contract-api-method.model';
import { ContractAPIMethodLegendModel } from './contract-api-method-legend.model';

import _ from 'lodash';

export class ContractAPIModel extends ConstructorModel {
    _id: string;
    methods: Array<ContractAPIMethodModel>;
    initArgs: object;
    key: ContractAPIMethodLegendModel;
    blockchain: string;

    /** model constructor */
    constructor(data?: object) {
        super();

        // @TODO: pendiente de modelar objects
        this._id = this.setId('_id', data);
        this.initArgs = _.get(data, 'init_args') || _.get(data, 'initArgs');
        this.key = this.setEntity('key', ContractAPIMethodLegendModel, data);
        this.blockchain = this.setString('blockchain', data);
        this.methods = this.getApiMethods(this.setObject('methods', data));
    }


    private getApiMethods(methods?: object): Array<ContractAPIMethodModel> {
        if (_.isNil(methods) || _.isEmpty(methods)) {
            return [];
        }

        const methodList = [];

        _.forEach(methods, (value, key) => {
            methodList.push(new ContractAPIMethodModel(Object.assign({name: key}, value)));
        });

        return methodList;
    }
}
