import { ConstructorModel } from '../../core/models/constructor.model';
import { ContractModel } from '../contract/contract.model';
import { TransactionEntryModel } from './transaction-entry.model';
import { ContractInstalledItemModel } from '../contract/contract-installed-item.model';
import { ContractInstantiatedItemModel } from '../contract/contract-instantiated-item.model';

import _ from 'lodash';


export class BlockchainDeployResponseModel extends ConstructorModel {
    _id: string;
    contracts: Array<ContractModel>;
    historical: Array<TransactionEntryModel>

    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.contracts = this.setEntityList('contracts', ContractModel, data);
        this.historical = this.setEntityList('historical', TransactionEntryModel, data);
    }


    /**
     * Returns smartcontract list with installed and/or instantiated on environment with version
     * @param {string} envId
     * @param {string} version
     */
    public getContractsFiltered(envId: string, version: string): Array<ContractModel> {
        if (_.isNil(this.contracts) || _.isEmpty(this.contracts)) {
            return [];
        }

        const filtered = [];

        _.forEach(this.contracts, (contract: ContractModel) => {
            const aux = _.update(contract.clone(), ['installed', 'instantiated'], (list: Array<ContractInstalledItemModel> | Array<ContractInstantiatedItemModel>) =>
                _.filter(list, (item: ContractInstalledItemModel |ContractInstantiatedItemModel) =>
                    _.get(item, 'environment._id') === envId && _.get(item, 'version') === version
                )
            );

            filtered.push(aux);
        });

        return filtered;
    }



    public allTransactionsSuccesfully(): boolean {
        if (_.isNil(this.historical) || _.isEmpty(this.historical)) {
            return false;
        }

        return _.filter(this.historical, {'successfully': false}).length === 0;
    }


}
