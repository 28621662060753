import { ConstructorModel } from '@common/core/models/constructor.model';
import { UserModel } from '@common/models/user/user.model';
import { ContractAPIModel } from './contract-api.model';

import _ from 'lodash';
import md5 from 'md5';

export class WorkContractHistoricModel extends ConstructorModel {
  version: string;
  language: string;
  source: string;
  hash: string;
  user: UserModel;
  createdDate: string;
  api: ContractAPIModel;


  /** constructor */
  constructor(data?: object) {
    super();

    this.version = this.setString('version', data);
    this.language = this.setString('language', data);
    this.source = this.setString('source', data);
    this.hash = this.getSourceHash(data);
    this.user = this.setEntity('users', UserModel, data);
    this.createdDate = this.setDate('createdDate', data, null) || this.setDate('created_date', data);
    this.api = this.setEntity('api', ContractAPIModel, data);
  }



  /********************** PRIVATE METHODS *************************/
  private getSourceHash(data?: object): string {
    const hash = this.setString('hash', data, null);

    if (!_.isNil(hash) && hash !== '') {
      return hash;
    }

    return !_.isNil(this.source) && this.source !== '' ? md5(this.source) : null;
  }
}
