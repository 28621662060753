import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class LoaderService {

  private _subject = new Subject<object>();
  /** constructor */
  constructor(private _router: Router) {
    this._router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.stop();
        }
      }, () => {
        this.stop();
    });
  }


  // ********************* PUBLIC METHODS ************************* //

  /**
   * add loader item to poolling
   * @param {string} [msg] msg
   */
  start(msg?: string) {
    this._subject.next(<object>{status: true, msg: msg});
  }


  /**
   * remove loader item from pooling
   */
  stop() {
    this._subject.next(<object>{status: false});
  }



  /**
   * Returns an observable instance
   * @returns {Observable<object>}
   */
  getLoader(): Observable<object> {
    return this._subject.asObservable();
  }
}
