import { ConstructorModel } from '@common/core/models/constructor.model';

import _ from 'lodash';



export class SCBPTemplateModel extends ConstructorModel {
    language: string;
    template: string;

    /** model constructor */
    constructor(data?: object) {
      super();

      this.language = this.setString('language', data);
      this.template = this.setString('template', data);
    }
}
