import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { Observable, TimeoutError, of, throwError } from 'rxjs';
import { timeout, catchError, map } from 'rxjs/operators';


import { AlertService } from '../../services/shared/alert.service';
import { SessionService } from '../../services/shared/session.service';
import { LoaderService } from '../../services/shared/loader.service';

import { environment } from '../../../../environments/environment';
import { SYSTEM_API } from '../../const/system.const';



import _ from 'lodash';

/** @ignore */
const ENVIRONMENT = environment;


/**
 * Custom http Service.
 * Use the environment configuration parameters to build the requests (API url, token, timeout, etc) as well to parse the response
 */
@Injectable()
export abstract class HttpServiceAbstract {

  /** @ignore */
  protected _urlServer: string;
  /** @ignore */
  protected _token: string;
  /** @ignore */
  protected _lang: string;
  /** @ignore */
  protected _timeout = SYSTEM_API.Timeout;
  /** @ignore */
  protected _errorKeys = SYSTEM_API.Error.Filter;


  // /**
  //  * Generate the URL string params
  //  * @param query
  //  * @returns {URLSearchParams}
  //  */
  // protected static createQueryString(query: any): URLSearchParams {
  //   const paramsQueryString = new URLSearchParams();

  //   if (query) {
  //     for (const param in query) {
  //       if (query.hasOwnProperty(param)) {
  //         paramsQueryString.set(param, query[param]);
  //       }
  //     }
  //   }
  //   return paramsQueryString;
  // }

  /** constructor */
  constructor(protected _http: HttpClient,
              protected _alertSrv: AlertService,
              protected _sessionSrv: SessionService,
              protected _loaderSrv: LoaderService) { }


  /**
   * HttpClient Get method
   * @param {string} endpoint: url to call
   * @param {object} query: params to inject in URL. Optional
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  public getXHR(endpoint: string, query?: object, loaderShow: boolean = false, alertShow: boolean = false): Observable<any> {
    return this.requestXHR('get', endpoint, query, null, null, loaderShow, alertShow);
  }


  /**
   * HttpClient POST method
   * @param {string} endpoint: url to call
   * @param {object} query: params to inject in URL. Optional
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  public postXHR(endpoint: string, query?: object, loaderShow: boolean = false, alertShow: boolean = false): Observable<any> {
    return this.requestXHR('post', endpoint, query, null, null, loaderShow, alertShow);
  }



  /**
   * HttpClient PUT method
   * @param {string} endpoint: url to call
   * @param {object} query: params to inject in URL. Optional
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  public putXHR(endpoint: string, query?: object, loaderShow: boolean = false, alertShow: boolean = false): Observable<any> {
    return this.requestXHR('put', endpoint, query, null, null, loaderShow, alertShow);
  }



  /**
   * HttpClient DELETE method
   * @param {string} endpoint: url to call
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  public deleteXHR(endpoint: string, loaderShow: boolean = false, alertShow: boolean = false, query: any = null): Observable<any> {
    return this.requestXHR('delete', endpoint, query, null, null, loaderShow, alertShow);
}


  // ********************* protected METHODS ************************* //
  protected generateUrlXHR(endpoint: string): string {
    return endpoint.includes('http')
      ? endpoint
      : this._urlServer + endpoint;
  }


  protected requestXHR(method: string,
                     endpoint: string,
                     query?: object,
                     contentType?: string,
                     acceptType?: string,
                     loaderShow: boolean = false,
                     alertShow: boolean = false): Observable<any> {
    let methodObject;
    const queryXHR = this.queryParser(query);
    const urlXHR = this.generateUrlXHR(endpoint);

    if (loaderShow) {
      this._loaderSrv.start();
    }

    switch (method) {
      case 'get':
      case 'delete':
        methodObject = this._http[method]<{}>(
          urlXHR,
          {
            headers: this.generateHeaders(contentType, acceptType),
            params: this.generateUrlParams(queryXHR),
            observe: 'response'
          }
        );
        break;

      case 'post':
      case 'put':
        methodObject = this._http[method]<{}>(
          urlXHR,
          this.generateFormParams(queryXHR, contentType),
            {
              headers: this.generateHeaders(contentType, acceptType),
              params: this.generateUrlParams(),
              observe: 'response'
            }
          );
        break;
    }

    const request = methodObject.pipe(
      map(response => this.castResponse(response, loaderShow, alertShow)),
      catchError(error => this.handleError(error, this, loaderShow, alertShow))
    );

    if (this._timeout > 0) {
      request.pipe(timeout(this._timeout));
    }

    return request;
  }





  // ********************* SERVICE METHODS ************************* //

  /**
   * Return the JWT header
   * @returns {object}
   */
  protected getAuthorizationHeader(): object {
    this._token = _.get(this._sessionSrv.getSession(), 'token');

    return !_.isNil(this._token)
      ? {'Authorization': 'Bearer ' + this._token}
      : {'Authorization': 'Basic ' + ENVIRONMENT.API.basicToken};
  }




  /**
   * XHR header
   */
  protected generateHeaders(contentType?: string, acceptType?: string): HttpHeaders {
    const headers = {
      'Content-Type': !_.isNil(contentType) ? contentType : 'application/json',
      'Accept': !_.isNil(acceptType) ? acceptType : 'application/json'
    };

    Object.assign(headers, this.getAuthorizationHeader());

    return new HttpHeaders(headers);
  }


  /**
   * Load the User language
   * @returns {boolean}
   */
  protected getLang(): boolean {
    this._lang = this._sessionSrv.getLangSession();

    return this._lang && this._lang !== undefined;
  }



  /**
   * Return a HttpParams instance of query object
   * @param {any} query
   */
  protected generateUrlParams(query?: object): HttpParams {
    let paramsQueryString = this.addHttpParams(query);

    if (ENVIRONMENT.API.enableLangParam && this.getLang()) {
      paramsQueryString = paramsQueryString.append('lang', this._lang);
    }

    return paramsQueryString;
  }


  /**
   * Return a object | FormData instance of query object
   * @param {any} query
   * @param {string} contentType
   */
  protected generateFormParams(query?: object, contentType?: string): any {
    if (_.isNil(contentType) || contentType === 'application/json') {
      return query;
    }

    return this.addHttpParams(query);
  }



  /**
   * Add a key-value to query instance. HttpParams require rewrite method
   * @param query
   */
  protected addHttpParams(query?: object): any {
    let paramsQueryString = new HttpParams();

    if (query) {
      _.forIn(query, (value, key) => {
        paramsQueryString = paramsQueryString.append(key, value);
      });
    }

    return paramsQueryString;
  }


  /**
   * Return a valid query how instance of object type
   * @param query
   */
  protected queryParser(query?: any): object {
    if (query === undefined) {
      return {};

    } else if (typeof query === 'object' || query instanceof Object) {
      return query;

    } else if (typeof query === 'string' || query instanceof String) {
      try {
        return JSON.parse(query.toString());
      } catch (e) {
        // console.log('HttpService: query param bad format. Required: object|JSON');
      }
    }

    return {};
  }


  // ********************* OBSERVER METHODS ************************* //
  /**
   * Cast the response
   * @param {HttpResponse<any>} response
   * @param {boolean} loaderShow
   * @param {boolean} showAlert
   * @returns {object}
   */
  protected castResponse(response: any, loaderShow: boolean, showAlert: boolean): object {
    if (loaderShow) {
      this._loaderSrv.stop();
    }

    if (showAlert) {
      if (response.status !== 200) {
        this._alertSrv.outOfService();

      } else if (response.body.hasOwnProperty('message')) {
        this._alertSrv.success('', response.body['message']);
      }
    }

    return response.body;
  }



  /**
   * Error handler
   * @param {HttpErrorResponse} response
   * @param {HttpServiceAbstract} http
   * @param {boolean} loaderShow
   * @param {boolean} alertShow
   * @returns {Observable<any>}
   */
  protected handleError(response: HttpErrorResponse | any, http: HttpServiceAbstract, loaderShow: boolean, alertShow: boolean): Observable<any> {
    if (loaderShow) {
      this._loaderSrv.stop();
    }

    if (response instanceof TimeoutError) {
      http._alertSrv.timeout();

    } else if (response.status === 401) {
      http._sessionSrv.expireSession();

    } else if (response.status === 403) {
      http._alertSrv.unauthorized();

    } else {
      if (!response.ok && alertShow) {
        if (response.status === 404) {
          http._alertSrv.serviceNoFound();
        }
      }

      return throwError(_.pick(response, this._errorKeys));
    }
  }

}
