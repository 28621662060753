import { ConstructorModel } from '../../core/models/constructor.model';
import { UserModel } from '../user/user.model';
import { ContractDeploymentSetupModel } from './contract-deployment-setup.model';



import moment from 'moment';
import _ from 'lodash';


export class ContractDeploymentModel extends ConstructorModel {
    _id: string;
    createdDate: string;
    createdBy: UserModel;
    updatedDate: string;
    updatedBy: UserModel;
    deployedDate: string;
    deployedBy: UserModel;
    setup: Array<ContractDeploymentSetupModel>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.createdDate = this.setDate('created_date', data) || this.setDate('createdDate', data);
        this.createdBy = this.setEntity('created_by', UserModel, data) || this.setEntity('createdBy', UserModel, data);
        this.setup = this.setEntityList('setup', ContractDeploymentSetupModel, data);

        this.updatedDate = this.setDate('updated_date', data) || this.setDate('updatedDate', data);
        this.updatedBy = this.setEntity('updated_by', UserModel, data) || this.setEntity('updatedBy', UserModel, data);
        this.deployedDate = this.setDate('deployed_date', data) || this.setDate('deployedDate', data);
        this.deployedBy = this.setEntity('deployed_by', UserModel, data) || this.setEntity('deployedBy', UserModel, data);
    }


    public isDeployed(): boolean {
        return !_.isNil(this.deployedDate);
    }


    public needUpdating(): boolean {
        return !_.isNil(this.deployedDate) && !_.isNil(this.updatedDate) && moment(this.updatedDate).isAfter(this.deployedDate);
    }

}
