import { ConstructorModel } from '../../core/models/constructor.model';

export class PaginatorSortModel  extends ConstructorModel {
    sort: string;
    orderBy: string;
    ascending: boolean;
    descending: boolean;
    ignoreCase: boolean;
    nullHandling: string;

    /** model constructor */
    constructor(data?: object) {
        super();
        this.sort = this.setString('direction', data);
        this.orderBy = this.setString('property', data);

        this.ascending = this.setBoolean('ascending', data);
        this.descending = this.setBoolean('descending', data);
        this.ignoreCase = this.setBoolean('ignoreCase', data);
        this.nullHandling = this.setString('nullHandling', data);
    }
}
