import { ConstructorModel } from '../../core/models/constructor.model';
import { WorkspaceMemberModel } from './workspace-member.model';

export class WorkgroupModel extends ConstructorModel {
    _id: string;
    name: string;
    domains: Array<string>;
    members: Array<WorkspaceMemberModel>;


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.domains = this.setList('domains', data);
        this.members = this.setEntityList('members', WorkspaceMemberModel, data);
    }
}
