import { ShapeGenType } from '@common/types/monitor.type';

/** infrastructure editor hardcoded params */
export const BlockchainFabricDefault = {
  type: 'fabric',
  version: '2.2.0',
  distribution: 'fabric',
};

export const BlockchainEthereumDefault = {
  type: 'ethereum',
  version: '1',
  distribution: 'ethereum',
};

/** infrastructure editor hardcoded params */
export const CADefault = {
  port: 7054
};


/** patterns for autocomplete shape names in infrastructure editor */
export const ShapeNameGenerator = {
  ca: {sufix: 'ca', method: 'isCAType', mType: 'blm:ca'} as ShapeGenType,
  org: {sufix: 'org', method: 'isOrgType', mType: 'blm:Org'} as ShapeGenType,
  channel: {sufix: 'channel', method: 'isChannelType', mType: 'blm:Channel'} as ShapeGenType,
  peer: {prefix: 'peer', method: 'isPeerType', mType: 'blm:Peer'} as ShapeGenType,
  orderingService: {prefix: 'ordering', method: 'isOrderingServiceType', mType: 'blm:OrderingService'} as ShapeGenType,
  orderer: {prefix: 'orderer', method: 'isOrdererType', mType: 'blm:Orderer'} as ShapeGenType
};
