import { ConstructorModel } from '@common/core/models/constructor.model';
import { CloudModel } from './cloud.model';

import _ from 'lodash';

export class CloudCatalogModel extends ConstructorModel {
    catalog: Array<CloudModel>;

    /** model constructor */
    constructor(data?: object | Array<object>) {
        super();

        this.catalog = [];

        if (_.isArray(data)) {
            _.forEach(data, (cloudData: object) => {
                this.catalog.push(new CloudModel(cloudData));
            });

        } else {
            _.forEach(data, (cloudData: object, name: string) => {
                const cloudDataExtend = Object.assign({name: name}, cloudData);
                this.catalog.push(new CloudModel(cloudDataExtend));
            });
        }
    }


    /****************** PUBLIC METHODS *************************/
    /**
     * Return a CloudModel according to cloud type
     * @param {string} value: cloud name or key
     * @returns {CloudModel}
     */
    public getCloud(value: string): CloudModel {
        return _.find(this.catalog, (cloud: CloudModel) => {
            const regExName = new RegExp('^' + (cloud.name || '') + '$', 'i');
            const regExKey = new RegExp('^' + (cloud.key || '') + '$', 'i');

            return regExName.test(value) || regExKey.test(value);
        });
    }


    /**
     * Returns if exists in the catalog the cloud
     * @param {string} value: cloud name or key or abbreviation
     * @returns {boolean}
     */
    public hasCloud(value: string): boolean {
        return !_.isNil(this.getCloud(value));
    }



    /**
     * Returns the cloud string list available in the catalog
     * @param {Array<CloudModel>} [catalog]
     * @returns {Array<string>}
     */
    public getCloudList(catalog?: Array<CloudModel>): Array<string> {
        const list = [];
        const tCatalog = catalog ? catalog : this.catalog;

        tCatalog.forEach((cloud: CloudModel) => {
            list.push(cloud.name);
        });

        return list;
    }
}
