import { ConstructorModel } from '@common/core/models/constructor.model';
import { MonitorBlockchainModel } from './monitor-blockchain.model';
import { MonitorCloudModel } from './monitor-cloud.model';


export class MonitorNetworkModel extends ConstructorModel {
    id: string;
    name: string;
    done: boolean;
    monoInstance: boolean;
    processMessage: string;
    cloudId: string;
    blockchainId: string;
    cloud: MonitorCloudModel;
    blockchain: MonitorBlockchainModel;

    /** model constructor */
    constructor(data?: object) {
        super();
        
        this.id = this.setString('id', data);
        this.name = this.setString('name', data);
        this.done = this.setBoolean('done', data);
        this.monoInstance = this.setBoolean('monoInstance', data)
        this.processMessage = this.setString('processMessage', data);
        this.cloud = this.setEntity('cloud', MonitorCloudModel, data);
        this.blockchain = this.setEntity('blockchain', MonitorBlockchainModel, data);
        this.cloudId = this.setString('cloudId', data);
        this.blockchainId = this.setString('blockchainId', data);
    }
}
