
import { Component, OnInit, Inject, Injector } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MonitorCloudModel } from '@common/models/monitor/monitor-cloud.model';
import { MonitorNetworkModel } from '@common/models/monitor/monitor-network.model';
import { MetaListComponent } from '@common/core/components/meta-list.component';
import { ProviderResponseType } from '@common/types/provider-response.type';


import _ from 'lodash';
import { WorkspaceProviderService } from '@common/services/providers/workspace-provider.service';
import { WorkspaceCloudModel } from '@common/models/workspace/workspace-cloud.model';

@Component({
  selector: 'app-popup-cloud',
  templateUrl: './popup-cloud.component.html',
  styleUrls: ['./popup-cloud.component.scss']
})
export class PopupCloudComponent extends MetaListComponent implements OnInit {

  public clouds: Array<WorkspaceCloudModel> = [];

  constructor(
    private _injector: Injector,
    public dialogRef: MatDialogRef<PopupCloudComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MonitorNetworkModel,
    private _contractSrv: WorkspaceProviderService) {
      super(_injector);
    }

  ngOnInit() {
    this.getCloudsFromApi()
  }

  submit() {
    this.dialogRef.close(this.data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addSshKey () {
    // this.data.cloud.sshKeys.push({
    //   name: '',
    //   value: '',
    //   usernames: [],
    // });
  }


  private getCloudsFromApi() {      
      this.subcriptionApi$['cloudList'] = this._contractSrv.getCloudsWorkspace(this.___sessionSrv.getWorkspaceSession()._id).subscribe(
        (response: ProviderResponseType) => {
          // const aux = response;
          // console.log(response);
          // this.table.loader = false;
          this.clouds = response.data;
          // this.setDataTable(response ? response.data : []);
          // this.unsubscribeApi('cloudList');
        },
        (error) => {
          //this.setDataTable([{cloud_id:"idcloud",type:"tipo",description:"descrip",name:"nombre"}]);
          // this.table.loader = false;
          // this.unsubscribeApi('cloudList');
          // this.showAlertModal('Cloud.Alert.FailLoadList', 'error', _.get(error, 'error.key'));
        }
      );
      
  }

}
