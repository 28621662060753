import { ConstructorModel } from '@common/core/models/constructor.model';

import { EMPTY_BPMN, BPMN_NATIVE_TYPES } from '@common/const/bpm.const';
import { BPElementModel } from './bp-element.model';
import { SCBPTemplateModel } from './sc-bp-template.model';
import { BPMNStructModel } from './bpmn-struct.model';
import { BPMNStructCatalogModel } from './bpmn-struct-catalog.model';
import { BPMNPropertyModel } from './bpmn-property.model';

import _ from 'lodash';


export class BPMNModel extends ConstructorModel {
    version: string;
    createdDate: string;
    updatedDate: string;
    source: string;
    elements: Array<BPElementModel>;
    templates: Array<SCBPTemplateModel>;
    structs: Array<BPMNStructModel>;
    dataInit: object;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.source =  this.setString('source', data) || EMPTY_BPMN;
        this.version = this.setString('version', data) || '0.0';
        this.createdDate = this.setDate('createdDate', data) || this.setDate('created_date', data);
        this.updatedDate = this.setDate('updatedDate', data) || this.setDate('updated_date', data);
        this.elements = this.setEntityList('elements', BPElementModel, data);
        this.templates = this.setEntityList('templates', SCBPTemplateModel, data);
        this.structs = this.setEntityList('structs', BPMNStructModel, data);
        this.dataInit = this.setObject('dataInit', data);
    }


    public getAvailableLanguages(): Array<string> {
      if (_.isNil(this.templates) || _.isEmpty(this.templates)) {
        return null;
      }

      return _.map(this.templates, (template: SCBPTemplateModel) => {
        return template.language;
      });
    }


    public isAvailbleLanguage(language: string): boolean {
      return _.findIndex(this.templates, (template: SCBPTemplateModel) => {
        return template.language === language;
      });
    }


    public getTemplateByLanguage(language: string): SCBPTemplateModel {
      for (const template of this.templates) {
        if (_.get(template, 'language') === language) {
          return template;
        }
      }
      return null;
    }



    /**
     * Returns an optimized object
     */
    export(): object {
      const eDiagram = _.cloneDeep(this);
      _.set(eDiagram, 'structs', _.map(this.structs, (struct: BPMNStructCatalogModel) => struct.export()));

      return eDiagram;
    }


    public getDataObjects(): Array<object> {
      const dataObject = [];

      for (const struct of this.structs) {
        const data = {};

        for (const property of struct.properties) {
          data[property.name] = {
            type: this.getTypeNameRef(property),
            getter: false,
            setter: false
          };
        }

        dataObject.push({
          name: _.get(struct, 'name'),
          data: data,
          init: this.getDataInit(struct)
        });
      }


      return dataObject;
    }



    public getBpElements(): Array<object> {
      return _.map(this.elements, (element: BPElementModel) => {
        return {id: element.element, parent: element.parent};
      });
    }





    /************************** PRIVATE METHODS *********************/

    private getTypeNameRef(property: BPMNPropertyModel): string {
      const name = _.findIndex(BPMN_NATIVE_TYPES, {'id': property.type.id}) !== -1
        ? property.type.id
        : _.get(_.find(this.structs, {id: property.type.id}), 'name');

      return property.isCollection ? `${name}[]` : name;
    }


    private getDataInit(struct: BPMNStructModel): object | boolean {
      return _.get(_.find(this.dataInit, {'typeId': struct.id}), 'data', false);
    }
}
