import { ConstructorModel } from '../../core/models/constructor.model';
import { WorkContractModel } from '../contract/work-contract.model';
import { DeploymentActionModel } from './deployment-action.model';
import { DeploymentInitArgsModel } from './deployment-init-args';

export class ContractDeploymentSetupModel extends ConstructorModel {
  workContract: WorkContractModel;
  workContractVersion: string;
  organization: string;
  peer: string;
  channel: string;
  createdDate: string;
  updatedDate: string;
  deployedDate: string;
  action: DeploymentActionModel;
  initArgs: Array<DeploymentInitArgsModel>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.workContract = this.setEntity('workContract', WorkContractModel, data, null)
          || this.setEntity('work_contract', WorkContractModel, data);
        this.workContractVersion = this.setString('workContractVersion', data) || this.setString('work_contract_version', data);
        this.organization = this.setString('organization', data);
        this.peer = this.setString('peer', data);
        this.channel = this.setString('channel', data);
        this.createdDate = this.setDate('createdDate', data) || this.setDate('created_date', data);
        this.updatedDate = this.setDate('updatedDate', data) || this.setDate('updated_date', data);
        this.deployedDate = this.setDate('deployedDate', data) || this.setDate('deployed_date', data);
        this.action = this.setEntity('action', DeploymentActionModel, data);
        this.initArgs = this.setEntityList('initArgs', DeploymentInitArgsModel, data, null)
          || this.setEntityList('init_args', DeploymentInitArgsModel, data);
    }
}
