import { Injectable, Injector } from '@angular/core';
import { MetaProviderService } from '@common/core/services/meta-provider.service';
import { Observable } from 'rxjs';

import { DeploymentModel } from '@common/models/deployment/deployment.model';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { ContractModel } from '@common/models/contract/contract.model';
import { WorkFabricInfraestructureModel } from '@common/models/infraestructure/work-infraestructure.model';


@Injectable({
    providedIn: 'root'
  })
  export abstract class AbstractBlockchainProviderService extends MetaProviderService {

    /** provider constructor */
    constructor(private _abstractInjector: Injector) {
      super(_abstractInjector);
    }


    /**
     * deploy contract
     * @param {DeploymentModel} deployment
     * @param {WorkFabricInfraestructureModel} workInfra
     * @returns {Observable<any>}
     */
    public abstract deployContract(deployment: DeploymentModel, workInfra: WorkFabricInfraestructureModel): Observable<any>;


    /**
     * invoke contract
     * @param {object} data
     * @param {WorkspaceModel} [workspace]
     * @returns {Observable<any>}
     */
    public abstract invokeContract(data: object, workspace?: WorkspaceModel): Observable<any>;


    /**
     * Returns if the contract is deployed in the workspace with version
     * @param {ContractModel} contract
     * @param {workspace} [workspace] if not defined: search first deployed valid
     * @param {string} [version] if not defined: search any version
     */
    public abstract hasContractDeployed(contract: ContractModel, workspace?: WorkspaceModel, version?: string): boolean;

}
