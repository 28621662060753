/** available input types */
export enum InputEnum {
    text, textarea, checkbox, datepicker, radio, select, slider, toggle
}

/** label interface for field */
export interface LabelFieldType {
    enable: boolean;
    text: string; // key for i18n
    css: string; // css classes
}

/** input response for field */
export interface InputValueType {
    name: string;
    value: any;
}

/** input interface for field */
export interface InputFieldType {
    type: InputEnum;
    css: string; // css classes
    placeholder: String;
    values?: Array<InputValueType>; // Array for select
}

export interface HtmlFieldType {
    input: InputFieldType;
    label: LabelFieldType;
    error: boolean;
    css: string;
}

export interface ControlFieldType {
    default: any;
    disabled: boolean;
    validations: Array<any>;
    provider?: string; // callback (provider method)
}


export interface FormFieldType {
    name: string;
    html: HtmlFieldType;
    control: ControlFieldType;
}
