import { ConstructorModel } from '../../core/models/constructor.model';
import { PeerModel } from './peer.model';
import { UserModel } from '../user/user.model';
import { CertificateAuthorityModel } from './certificate-authority.model';

import _ from 'lodash';


interface CertType {
    path: string;
}

export class OrganizationModel extends ConstructorModel{
    _id: string;
    name: string;
    mspid: string;
    domains: Array<string>;
    admins: Array<UserModel>;
    peers: Array<PeerModel>;
    infCredentials: object; // @TODO pendiente de definir cuando se haga en bbdd
    certificateAuthorities: Array<CertificateAuthorityModel>;
    adminPrivateKey: Array<CertType>; // @TODO pendiente de definir cuando se haga en bbdd
    signedCert: Array<CertType>; // @TODO pendiente de definir cuando se haga en bbdd


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.mspid = this.setString('mspid', data);
        this.domains = this.setList('domains', data);
        this.admins = this.setEntityList('admins', UserModel, data);
        this.peers = this.setEntityList('peers', PeerModel, data);
        this.infCredentials = this.setObject('infCredentials', data, null) || this.setObject('inf_credentials', data);

        this.certificateAuthorities = this.setEntityList('certificateAuthorities', CertificateAuthorityModel, data);
        this.adminPrivateKey = this.setList('adminPrivateKey', data);
        this.signedCert = this.setList('signedCert', data);
    }
}
