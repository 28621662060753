import { ConstructorModel } from '../../core/models/constructor.model';
import { UserModel } from '../user/user.model';
import { EnvironmentModel } from '../environment/environment.model';
import { PeerModel } from '../environment/peer.model';
import { ChannelModel } from '../environment/channel.model';
import { ContractFcnModel } from '../contract/contract-fcn.model';
import { ContractFcnArgModel } from '../contract/contract-fcn-arg.model';

enum EntryEnum {
    installation = 'installation',
    instantiation = 'instantiation',
    query = 'instantiation'
}

export interface EntryInstallationType {
    peers: Array<PeerModel>;
}

export interface EntryInstantiationType {
    channel: ChannelModel;
}

export interface EntryQueryType {
    fcn: ContractFcnModel;
    args: ContractFcnArgModel;
    channel: ChannelModel;
}


export class TransactionEntryModel extends ConstructorModel {
    type: string; //transaction_type
    startDate: string;
    endDate: string;
    user: UserModel;
    environment: EnvironmentModel;
    successfully: boolean;
    detail: object;
    error: Array<string>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.type = this.setString('transaction_type', data, null) || this.setString('type', data);
        this.startDate = this.setDate('start_date', data) || this.setDate('startDate', data);
        this.endDate = this.setDate('end_date', data) || this.setDate('endDate', data);
        this.user = this.setEntity('user', UserModel, data);
        this.environment = this.setEntity('environment', EnvironmentModel, data);
        this.successfully = this.setBoolean('successfully', data);
        this.detail = this.setObject('detail', data, null);
        this.error = this.setList('error', data, null);
    }
}
