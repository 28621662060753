import { ConstructorModel } from '@common/core/models/constructor.model';
import { UserModel } from '@common/models/user/user.model';
import { WorkInfraestructureHistoricModel } from './work-infraestructure-historic.model';
import { getBlockchainImage, getBlockchainIcon } from '@common/libs/blockchain';
import { EMPTY_BPMN } from '@common/const/bpm.const';
import { BlockchainFabricDefault } from '@common/const/infraestructure.const';
import { ContractDeploymentModel } from '../deployment/contract-deployment.model';



import _ from 'lodash';

export class WorkFabricInfraestructureModel extends ConstructorModel {
  _id: string;
  deploy_id: string;
  name: string;
  monoInstance: boolean;
  owner: UserModel;
  description: string;
  source: string;
  version: string;
  published: boolean;
  markAsTemplate: boolean;
  editable: boolean;
  createdDate: string;
  updatedDate: string;
  updatedBy: UserModel;

  deploymentDate: string;
  deploymentId: string;
  deploymentVersion: string;
  deploymentUser: UserModel;

  historic: Array<WorkInfraestructureHistoricModel>;
  reviewers: Array<UserModel>;
  logo: string;
  icon: string;

  blockchainType: string;
  blockchainDistribution: string;
  blockchainDistributionVersion: string;
  publicDns: string;

  contractDeployment: ContractDeploymentModel;

  /** constructor */
  constructor(data?: object) {
    super();

    this._id = this.setId('_id', data);
    this.deploy_id = this.setString('deploy_id', data);
    this.name = this.setString('name', data);
    this.monoInstance = this.setBoolean('monoInstance', data)
    this.owner = this.setEntity('owner', UserModel, data);
    this.description = this.setString('description', data);
    this.source = this.setString('source', data, null) || EMPTY_BPMN;
    this.version = this.setString('version', data);
    this.published = this.setBoolean('published', data);
    this.markAsTemplate = this.setBoolean('markAsTemplate', data) || this.setBoolean('mark_as_template', data);
    this.editable = this.setBoolean('editable', data);
    this.createdDate = this.setDate('createdDate', data) || this.setDate('created_date', data);
    this.updatedDate = this.setDate('updatedDate', data) || this.setDate('updated_date', data);
    this.updatedBy = this.setEntity('updatedBy', UserModel, data) || this.setEntity('updated_by', UserModel, data);
    this.reviewers = this.setEntityList('reviewers', UserModel, data);
    this.historic = this.setEntityList('historic', WorkInfraestructureHistoricModel, data);


    this.deploymentId = this.setString('deploymentId', data) || this.setString('deployment_id', data);
    this.deploymentVersion = this.setString('deploymentVersion', data) || this.setString('deployment_version', data);
    this.deploymentUser = this.setEntity('deploymentUser', UserModel, data) || this.setEntity('deployment_user', UserModel, data);
    this.deploymentDate = this.setDate('deploymentDate', data) || this.setDate('deployment_date', data);

    this.blockchainType = this.setString('blockchainType', data) || this.setString('blockchain_type', data, BlockchainFabricDefault.type);
    this.blockchainDistribution = this.setString('blockchainDistribution', data) || this.setString('blockchain_distribution', data, BlockchainFabricDefault.distribution);
    this.blockchainDistributionVersion = this.setString('blockchainDistributionVersion', data) || this.setString('blockchain_distribution_version', data, BlockchainFabricDefault.version);
    this.publicDns = this.setString('publicDns', data) || this.setString('public_dns', data);
    this.contractDeployment = this.setEntity('contractDeployment', ContractDeploymentModel, data, null)
      || this.setEntity('contract_deployment', ContractDeploymentModel, data)

    this.logo = getBlockchainImage(this.blockchainType);
    this.icon = getBlockchainIcon(this.blockchainType);
  }



  public getLogo(): string {
    return getBlockchainImage(_.get(this, 'blockchainType'));
  }


  public getIcon(): string {
    return getBlockchainIcon(_.get(this, 'blockchainType'));
  }


  /**
   * Returns a determinate work infraestructure version
   * @param {string} version
   *
   * @returns {WorkInfraestructureHistoricModel}
   */
  getVersion(version: string): WorkInfraestructureHistoricModel {
    return _.find(this.historic, (h: WorkInfraestructureHistoricModel) => h.version === version);
  }


  /**
   * Returns the version list of historic
   *
   * @returns {Array<string>}
   */
  getVersionList(): Array<string> {
    return _.keyBy(_.orderBy(this.historic, ['version'], ['desc']), 'version');
  }
}
