import { Component, OnInit, Injector, Input, OnDestroy, HostBinding, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MetaListComponent } from '@common/core/components/meta-list.component';

import { SessionService } from '@common/services/shared/session.service';

import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { SessionModel } from '@common/models/session/session.model';

import _ from 'lodash';


/**
 * show the logged user workspace list and highlights the current workspace
 */
@Component({
  selector: 'app-workspace-session',
  templateUrl: './workspace-session.component.html',
  styleUrls: ['./workspace-session.component.scss']
})
export class WorkspaceSessionComponent extends MetaListComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.popup-show') isActive = false;
  @Input() workspaceList: Array<WorkspaceModel> = [];
  @Output() changeWorkspace = new EventEmitter();

  public workspaceSelected: WorkspaceModel = new WorkspaceModel();
  public changed = false;
  /** constructor */
  constructor(private _injector: Injector,
              private _sessionSrv: SessionService) {
    super(_injector);

    this.table.clientSide = true;
    this.table.columns = ['checkbox', 'name', 'bp', 'workInfrastructure', 'workContracts'];
    this.onChangeSessionSubscription();
  }



  ngOnInit() {
    this.workspaceSelected = this._sessionSrv.getWorkspaceSession();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (!_.isNil(_.get(changes, 'workspaceList.currentValue'))) {
      this.setDataTable(this.workspaceList);
    }
  }


  ngOnDestroy() {
    this.unsubscribeOnDestroy();
  }


  /************************ PUBLIC METHODS ****************************/
  toggle() {
    this.isActive ? close() : open();
  }

  open() {
    this.isActive = true;
    this.workspaceSelected = this._sessionSrv.getWorkspaceSession();
    this.changed = false;
  }

  close() {
    this.isActive = false;
  }



  /************************ DOM METHODS ****************************/
  onSelect(workspace: WorkspaceModel) {
    this.changed = workspace._id !== _.get(this._sessionSrv.getWorkspaceSession(), '_id');
    this.workspaceSelected = workspace;
  }


  onAccept() {
    if (this.changed) {
      this.changeWorkspace.emit({value: this.workspaceSelected});
      this.close();
    }
  }



  /************************ PRIVATE METHODS ****************************/
  private onChangeSessionSubscription() {
    this.subcriptionOnDestroy$['session'] = this._sessionSrv.onChange().subscribe(
      (session: SessionModel) => {
        if (_.get(this.workspaceSelected, '_id') === _.get(session, 'workspace._id')) {
          return;
        }
        this.workspaceSelected = session.lastWorkspace;
      }
    );
  }
}
