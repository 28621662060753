import { Component, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '@common/services/shared/session.service';
import { UserModel } from '@common/models/user/user.model';
import { SessionModel } from '@common/models/session/session.model';


import _ from 'lodash';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
const swal = require('sweetalert2');

@Component({
  selector: 'app-meta-simple',
  template: ''
})
export class MetaSimpleComponent implements OnInit {
  public subcriptionOnDestroy$ = {};
  public subcriptionApi$ = {};
  public userLogged: UserModel;
  public ___sessionSrv: SessionService;

  private ___translateSrv: TranslateService;
  private ___router: Router;

  /** constructor */
  constructor(private injectorMetaSimple: Injector) {
    this.___translateSrv = injectorMetaSimple.get(TranslateService);
    this.___router = injectorMetaSimple.get(Router);
    this.___sessionSrv = injectorMetaSimple.get(SessionService);

    this.userLogged = this.___sessionSrv.getUserSession();

    this.subcriptionOnDestroy$['session'] = this.___sessionSrv.onChange().subscribe(
      (session: SessionModel) => {
        const userSession = this.___sessionSrv.getUserSession();

        if (!_.isNil(userSession) && userSession.getDifferences(this.userLogged).length > 0) {
          this.userLogged = userSession;
        }
      }
    );
  }

  ngOnInit() { }



  /************************ PROTECTED METHODS **************************/

  /**
   * Returns if a subscription is alive or not
   * @param idx
   */
  public isAliveSubscriptionDestroy(idx: string): boolean {
    return !_.isNil(_.get(this.subcriptionOnDestroy$, 'commandStackChanged')) && !<Subscription>this.subcriptionOnDestroy$['commandStackChanged'].closed;
  }


  /**
   * Unsubscribe subscriptions in destroy list
   */
  public unsubscribeOnDestroy() {
    this.unsubscribeList(this.subcriptionOnDestroy$);
  }


  /**
   * Unsubscribe the subscription in api list or all subscriptions if subscription is undefined
   * @param {string} subscription
   */
  public unsubscribeApi(subscription?: string) {
    this.unsubscribeList(
      !_.isNil(subscription)
        ? _.pick(this.subcriptionApi$, subscription)
        : this.subcriptionApi$
    );
  }



  /**
   * Show alert error
   * @param {string} path
   * @param {string} [type='error'] type
   * @param {string} [customText] customText
   */
  public showAlertModal(path: string, type: string = 'error', customText?: string, timeout?: number) {
    const text = this.___translateSrv.instant(customText || path + '.TextLabel');

    swal({
      title: this.___translateSrv.instant(path + '.TitleLabel'),
      html: text,
      type: type,
      confirmButtonText: this.___translateSrv.instant(path + '.CloseBtn'),
      confirmButtonClass: 'mat-raised-button mat-accent mx-1',
      buttonsStyling: false,
      onOpen: () => {
        if (timeout) {
          setTimeout(() => {
            swal.close();
          }, timeout);
        }
      }
    });
  }


  /**
   * Show alert modal without dismiss option and redirect by timeout
   * @param {string} path
   * @param {string} callback
   * @param {any} [paramsCallback] params: params for callback
   * @param {string} [type='error'] type
   * @param {string} [customText] customText
   */
  public showAlertModalCallback(path: string, callback: string, paramsCallback?: any, type: string = 'error', customText?: string) {
    const text = this.___translateSrv.instant(customText || path + '.TextLabel');

    swal({
      title: this.___translateSrv.instant(path + '.TitleLabel'),
      html: text,
      type: type,
      confirmButtonText: this.___translateSrv.instant(path + '.CloseBtn'),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      confirmButtonClass: 'mat-raised-button mat-accent mx-1',
      buttonsStyling: false,
    }).then((result) => {
      _.isNil(paramsCallback) ? this[callback]() : this[callback](paramsCallback);
    });
  }

  /**
   * Show modal error no dismissable with timeout redirect
   * @param {string} path
   * @param {string} url
   * @param {string} [type='error'] type
   * @param {string} [customText] customText
   */
  public showAlertModalTimeout(path: string, url: string, type: string = 'error', customText?: string) {
    const text = this.___translateSrv.instant(customText || path + '.TextLabel');
    const goTo = _.isNil(url)
      ? _.initial(this.___router.url.split('/')).join('/')
      : url;

    swal({
      title: this.___translateSrv.instant(path + '.TitleLabel'),
      html: text,
      type: type,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      onOpen: () => {
        swal.showLoading();

        setTimeout(() => {
          this.___router.navigateByUrl(goTo);
          swal.close();
        }, 5000);
      }
    });
  }


  /**
   * Show alert modal without dismiss option and redirect by timeout
   * @param {string} path
   * @param {string} url
   * @param {string} [type='error'] type
   * @param {string} [customText] customText
   */
  public showAlertModalRedirect(path: string, url: string, type: string = 'error', customText?: string) {
    const text = this.___translateSrv.instant(customText || path + '.TextLabel');

    swal({
      title: this.___translateSrv.instant(path + '.TitleLabel'),
      html: text,
      type: type,
      confirmButtonText: this.___translateSrv.instant(path + '.CloseBtn'),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCancelButton: false,
      confirmButtonClass: 'mat-raised-button mat-accent mx-1',
      buttonsStyling: false,
    }).then((result) => {
      this.___router.navigateByUrl(url);
    });
  }



  /**
   * Show confirm modal
   * @param {string} path
   * @param {string} callbackOk: Method (name) to call if confirm button is clicked
   * @param {any} [paramsOk] paramsOk: params for callback
   * @param {string} [callbackCancel] callbackDismiss: Method (name) to call if dismiss button is clicked
   * @param {any} [paramsCancel] paramsCancel: params for callback dismiss
   * @param {string} [customText] customText
   */
  public showConfirmModal(path: string, callbackOk: string, paramsOk?: any, callbackCancel?: string, paramsCancel?: any, customText?: string) {
    const text = this.___translateSrv.instant(customText || path + '.TextLabel');

    swal({
      title: this.___translateSrv.instant(path + '.TitleLabel'),
      html: text,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.___translateSrv.instant(path + '.YesBtn'),
      cancelButtonText: this.___translateSrv.instant(path + '.NoBtn'),
      confirmButtonClass: 'mat-raised-button mat-accent mx-1',
      cancelButtonClass: 'mat-raised-button mat-warn mx-1',
      buttonsStyling: false,
      reverseButtons: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCloseButton: false
    }).then((result) => {
      if (result.value) {
        _.isNil(paramsOk) ? this[callbackOk]() : this[callbackOk](paramsOk);
      } else if (!_.isNil(callbackCancel)) {
        _.isNil(paramsCancel) ? this[callbackCancel]() : this[callbackCancel](paramsCancel);
      }
    }, (dismiss) => {
      _.isNil(paramsCancel) ? this[callbackCancel]() : this[callbackCancel](paramsCancel);
    });
  }

  /**
   * Show modal with the details of the OTP so a generator like
   * Google Authenticator or FreeOTP can be used as OTP provider
   * for the login OTPs.
   *
   * @param {string} qrbk
   **/
  public showGenOTPDetailsModal(qr: object) {
    const txt = '<p>' + this.___translateSrv.instant('Account.Item.Alert.OTP.Desc') + '</p><br>' +
                this.___translateSrv.instant('Account.Item.Alert.OTP.Secret') + ': <b> ' + qr['secret'] + '</b><br><img witdh="' +
                qr['width'] + '" height="' + qr['height'] + '" src="data:' + qr['format'] + ';base64,' + qr['data'] + '" />';

    swal({
      title: this.___translateSrv.instant('Account.Item.Alert.OTP.Title'),
      html: txt,
      allowOutsideClick: false
    });
  }

  /*************************** PRIVATE METHODS ******************************/

  /**
   * Unsbuscribe a subscription list
   * @param {object} subscriptons$
   */
  private unsubscribeList(subscriptons$: object) {
    if (!_.isNil(subscriptons$) && !_.isEmpty(subscriptons$)) {
      _.forIn(subscriptons$, (subscriptor: Subscription) => {
        if (!_.isNil(subscriptor) && !subscriptor.closed) {
          subscriptor.unsubscribe();
        }
      });
    }
  }
}
