import { ConstructorModel } from '@common/core/models/constructor.model';
import { MonitorConfigTxModel } from './monitor-config-tx.model';
import { MonitorOrganizationModel } from './monitor-organization.model';
import { MonitorOrderingServiceModel } from './monitor-ordering-service.model';
import { MonitorDefaultsModel } from './monitor-defaults.model';


export class MonitorBlockchainModel extends ConstructorModel {
    id: string;
    name: string;
    type: string;
    done: boolean;
    version: string;
    fabricVersion: string;
    fabricCaVersion: string;
    defaults: MonitorDefaultsModel;

    consortiums: Array<Object>;
    orderer: MonitorOrderingServiceModel;
    channels: Array<string>;
    configtx: MonitorConfigTxModel;
    organizations: Array<MonitorOrganizationModel>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.id = this.setString('id', data);
        this.name = this.setString('name', data);
        this.type = this.setString('type', data);
        this.done = this.setBoolean('done', data);
        this.version = this.setString('version', data);
        this.fabricVersion = this.setString('fabricVersion', data);
        this.fabricCaVersion = this.setString('fabricCaVersion', data);

        this.defaults = this.setEntity('defaults', MonitorDefaultsModel, data);
                
        this.consortiums = this.setList('consortiums', data);
        this.orderer = this.setEntity('orderer', MonitorOrderingServiceModel, data);
        this.channels = this.setList('channels', data);
        this.configtx = this.setEntity('configtx', MonitorConfigTxModel, data);
        this.organizations = this.setEntityList('organizations', MonitorOrganizationModel, data);
    }
}
