import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/shared/session.service';


import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {
  /** constructor */
  constructor(private _router: Router,
              private _sessionSrv: SessionService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const regEx = new RegExp('^\/panel\/account\/\S*', 'g');
    if (regEx.test(state.url) && next.paramMap.has('id') && this._sessionSrv.existSession() && this._sessionSrv.getUserSession()._id === next.paramMap.get('id')) {
      this._router.navigate(['/panel/profile']);
    }

    return true;
  }
}
