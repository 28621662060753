/** pair list field error - key i18n */
export const VALIDATION_ERRORS = {
    required: {
        msg: 'FormError.Required'
    },
    requiredNonZero: {
        msg: 'FormError.Required'
    },
    requiredNumber: {
        msg: 'FormError.RequiredNumber'
    },
    requiredObject: {
        msg: 'FormError.RequiredSelection'
    },
    maxlength: {
        msg: 'FormError.MaxTextLengthReplace',
        replace: {
            char: 'errors.maxlength.requiredLength'
        }
    },
    minlength: {
        msg: 'FormError.MinTextLengthReplace',
        replace: {
            char: 'errors.minlength.requiredLength'
        }
    },
    whitespaces: {
        msg: 'FormError.WhiteSpaces'
    },
    samepass: {
        msg: 'FormError.SameNewPass'
    },
    samepassmemo: {
        msg: 'FormError.SamePassMemo'
    },
    differentpass: {
        msg: 'FormError.DifferentPass'
    },
    unknownpass: {
        msg: 'FormError.UnknownPass'
    },
    email: {
        msg: 'FormError.Email'
    },
    alphanumeric: {
        msg: 'FormError.Alphanumeric'
    },
    alphanumericHyphen: {
        msg: 'FormError.AlphanumericHyphen'
    },
    variableName: {
        msg: 'FormError.VariableName'
    },
    uniqueText: {
        msg: 'FormError.UniqueText'
    },
    uniqueCA: {
      msg: 'FormError.UniqueCA'
    },
    uniqueChannel: {
      msg: 'FormError.UniqueChannel'
    },
    uniqueOrg: {
      msg: 'FormError.UniqueOrg'
    },
    uniquePeer: {
        msg: 'FormError.UniquePeer'
    },
    uniqueOrdering: {
        msg: 'FormError.UniqueOrdering'
    },
    uniqueOrderer: {
        msg: 'FormError.UniqueOrderer'
    },
    uniqueAdmin: {
        msg: 'FormError.UniqueAdmin'
    },
    uniqueClient: {
      msg: 'FormError.UniqueClient'
    },
    uniqueNameConsortium: {
      msg: 'FormError.UniqueNameConsortium'
    },
    uniqueConsortium: {
      msg: 'FormError.UniqueConsortium'
    },
    uniqueMspid: {
      msg: 'FormError.UniqueMspid'
    },
    uniquePublicDns: {
      msg: 'FormError.UniquePublicDns'
    },
    default: {
        msg: 'FormError.Invalid'
    }
};
