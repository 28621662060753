import { ConstructorModel } from '@common/core/models/constructor.model';
import { BPMNPropertyModel } from './bpmn-property.model';
import { variableNameTest } from '@common/libs/validators';

import _ from 'lodash';


export class BPMNStructModel extends ConstructorModel {
    id: string;
    name: string;
    properties: Array<BPMNPropertyModel>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.id =  this.setString('id', data) || `type_${(+new Date).toString(36)}`;
        this.name = this.setString('name', data);
        this.properties = this.setEntityListFromJson('properties', BPMNPropertyModel, data);
    }



    isValidStruct(): boolean {
        if (_.isNil(_.get(this, 'id')) || _.trim(this.id) === '') {
            return false;
        }

        if (_.isNil(_.get(this, 'name')) || _.trim(this.name) === '' || !variableNameTest(this.name)) {
            return false;
        }

        for (const property of _.get(this, 'properties', [])) {
            if (_.isNil(property) || !property.isValidProperty()) {
                return false;
            }
        }

        return true;
    }


    getFiltered(): BPMNStructModel {
        const temp = new BPMNStructModel(this);

        _.set(temp, 'properties', _.filter(_.get(this, 'properties', []), (property: BPMNPropertyModel) => property instanceof BPMNPropertyModel && property.isValidProperty()));

        return temp;
    }


    isEmptyStruct(): boolean {
        return (_.isNil(this.name) || this.name === '') && _.isEmpty(this.properties);
    }


    /**
     * Returns an optimized object of struct
     */
    export(): object {
      const eStruct = _.cloneDeep(this);
      _.set(eStruct, 'properties', _.map(this.properties, (property: BPMNPropertyModel) => property.export()));

      return eStruct;
    }
}
