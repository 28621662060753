import { ConstructorModel } from '../../core/models/constructor.model';
import { PaginatorSortModel } from './paginator-sort.model';

export class PaginatorModel extends ConstructorModel {
    pages: number;
    limit: number;
    offset: number;
    total: number;
    elements: number;

    first: boolean;
    last: boolean;
    sort: Array<PaginatorSortModel>;


    /** model constructor */
    constructor(data?: object) {
        super();
        this.pages = this.setNumber('totalPages', data);
        this.total = this.setNumber('totalElements', data);
        this.limit = this.setNumber('size', data);
        this.offset = this.setNumber('number', data);
        this.elements = this.setNumber('numberOfElements', data);
        this.first = this.setBoolean('first', data);
        this.last = this.setBoolean('last', data);
        this.sort = this.setEntityList('sort', PaginatorSortModel, data);
    }
}
