import { ConstructorModel } from '../../core/models/constructor.model';


export class ContractAPIMethodLegendModel extends ConstructorModel {
    key: string;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.key = this.setString('key', data);
    }
}
