import { ConstructorModel } from '@common/core/models/constructor.model';
import { BPMNPropertyTypeModel } from './bpmn-property-type.model';
import { variableNameTest } from '@common/libs/validators';

import _ from 'lodash';


export class BPMNPropertyModel extends ConstructorModel {
    name: string;
    type: BPMNPropertyTypeModel;
    isCollection: boolean;
    default: any;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.name = this.setString('name', data);
        this.type = this.setEntityFromJson('type', BPMNPropertyTypeModel, data);
        this.default = this.setString('default', data);
        this.isCollection = this.setBoolean('isCollection', data);
    }


    isValidProperty(): boolean {
        if (_.isNil(this.name) || _.trim(this.name) === '' || !variableNameTest(this.name)) {
            return false;
        }

        if (_.isNil(this.type) || !(this.type instanceof BPMNPropertyTypeModel) || !this.type.hasValidId()) {
            return false;
        }

        return true;
    }



    export(): object {
      const property = {
        name: this.name,
        type: {id: this.type.id}
      };

      if (this.default !== '') {
        _.set(property, 'default', this.default);
      }

      if (this.isCollection) {
        _.set(property, 'isCollection', this.isCollection);
      }

      return property;
    }
}
