import { ConstructorModel } from '../../core/models/constructor.model';
import { PeerModel } from './peer.model';
import { OrdererModel } from './orderer.model';


import _ from 'lodash';


export class ChannelModel extends ConstructorModel {
    _id: string;
    name: string;
    peers: Array<PeerModel>;
    orderers: Array<OrdererModel>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.peers = this.setEntityList('peers', PeerModel, data);
        this.orderers = this.setEntityList('orderers', OrdererModel, data);
    }
}
