import { ConstructorModel } from '@common/core/models/constructor.model';

export class MonitorDefaultsModel extends ConstructorModel {
  caOrg: string;
  ordererOrg: string;
  
  /** constructor */

  constructor(data?: object) {
    super();

    this.caOrg = this.setString('caOrg', data);
    this.ordererOrg = this.setString('ordererOrg', data);
  }
}
