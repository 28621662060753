import { Injectable, Injector } from '@angular/core';
import { Observable, concat } from 'rxjs';
import { last } from 'rxjs/operators';
import { AbstractIdentitiesProviderService } from '../shared/abstract-identities.provider.service';
import { IDENTITIES_ENDPOINTS } from './hyperledger-fabric-endpoints.const';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class HyperledgerIdentitiesProviderService extends AbstractIdentitiesProviderService {

  /** provider constructor */  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }

  /**
     * Create identity on a Network
     * @param {string} network network id where to create the identity
     * @param {string} id identifier used for identity
     * @param {any} info additional data reqired to create identity (public key, csr,  etc)
     * @returns {Observable<any>}
     */
  public createIdentity(network: string, id: string, info: any): Observable<any> {
    const data = {
      networkid: network,
      id: id,
      params: {
        csr: info
      }
    };

    return concat(
      this.add(IDENTITIES_ENDPOINTS.createIdentity, data)
    ).pipe(
        last()
    );
  }

  /**
     * Get identities list from a Network
     * @param {string} network network id from to get the identities list
     * @returns {Observable<any>}
     */
   public listIdentity(network: string): Observable<any> {
    const data = {
      networkid: network,
    };

    return concat(
      this.get(IDENTITIES_ENDPOINTS.listIdentity, data)
    ).pipe(
        last()
    );
  }

  /**
     * Get identities list from a Network
     * @param {string} network network id from to get the identities list
     * @param {string} id identifier used for identity
     * @returns {Observable<any>}
     */
   public deleteIdentity(network: string, id: string): Observable<any> {
    const query = {
      networkid: network
    };

    return concat(
      this.delete(IDENTITIES_ENDPOINTS.deleteIdentity, id, null, false, false, query)
    ).pipe(
        last()
    );
  }
}
