import { Injectable, Injector } from '@angular/core';
import { MetaProviderService } from '@common/core/services/meta-provider.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export abstract class AbstractIdentitiesProviderService extends MetaProviderService {

    /** provider constructor */
    constructor(private _abstractInjector: Injector) {
      super(_abstractInjector);
    }

    /**
     * Create identity on a Network
     * @param {string} network network id where to create the identity
     * @param {string} id identifier used for identity
     * @param {any} info additional data reqired to create identity (public key, csr,  etc)
     * @returns {Observable<any>}
     */
    public abstract createIdentity(network: string, id: string, info: any): Observable<any>;


    /**
     * Save identity in platform
     * @param {string} network network id where to create the identity
     * @param {string} id identifier used for identity
     * @param {any} info additional data reqired to create identity (public key, csr,  etc)
     * @returns {Observable<any>}
     */
    public saveIdentity?(network: string, label: string, account: string, password: string): Observable<any>;
  
    /**
    * Unlock identity on a Network OPTIONAL
    * @param {string} network network id where to create the identity
    * @param {string} id identifier used for identity
    * @param {any} info additional data reqired to create identity (public key, csr,  etc)
    * @returns {Observable<any>}
    */
    public unlockIdentity?(network: string, account: string, password: string): Observable<any>;
  
    /**
    * Unlock identity on a Network OPTIONAL
    * @param {string} network network id where to create the identity
    * @param {string} id identifier used for identity
    * @param {any} info additional data reqired to create identity (public key, csr,  etc)
    * @returns {Observable<any>}
    */
    public sendTransaction?(network: string, sendfrom: string, sendto: string, ammount: number): Observable<any>;

    /**
     * Get identities list from a Network
     * @param {string} network network id from to get the identities list
     * @returns {Observable<any>}
     */
    public abstract listIdentity(network: string): Observable<any>;

    /**
     * Get identities list from platform backend
     * @param {string} network network id from to get the identities list
     * @returns {Observable<any>}
     */
     public listIdentityInPlatform?(network: string): Observable<any>;

     /**
     * Get identities list from platform backend
     * @param {string} network network id from to get the identities list
     * @returns {Observable<any>}
     */
      public getSecrets?(network: string): Observable<any>;
    
    /**
     * Delete identity from a Network
     * @param {string} network network id from to get the identities list
     * @param {string} id identifier used for identity
     * @returns {Observable<any>}
     */
     public abstract deleteIdentity(network: string, id: string): Observable<any>;
}
