import { ConstructorModel } from '../../core/models/constructor.model';

import _ from 'lodash';


export class OrdererModel extends ConstructorModel {
    _id: string;
    name: string;
    url: string;

    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.url = this.setString('url', data);
    }
}
