import { ConstructorModel } from '@common/core/models/constructor.model';

import _ from 'lodash';

export class DeploymentInitArgsModel extends ConstructorModel {
  name: string;
  type: string;
  value: any;
  index: number;

  /** constructor */
  constructor(data?: object) {
    super();

    this.value = this.setAny('value', data);
    this.name = this.setString('name', data);
    this.type = this.setString('type', data);
    this.index = this.setNumber('index', data);
  }
}
