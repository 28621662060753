import { Injectable, Injector } from '@angular/core';
import { Observable, concat } from 'rxjs';
import { last } from 'rxjs/operators';

import { AbstractBlockchainProviderService } from '../shared/abstract-blockchain.provider.service';

import { BLOCKCHAIN_ENDPOINTS } from './blockchain-endpoints.const';

import { ContractModel } from '@common/models/contract/contract.model';
import { DeploymentModel } from '@common/models/deployment/deployment.model';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { BlockchainDeployResponseModel } from '@common/models/blockchain/blockcahain-deploy-response.model';
import { WorkFabricInfraestructureModel } from '@common/models/infraestructure/work-infraestructure.model';


import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FabricBlockchainProviderService extends AbstractBlockchainProviderService {
  /** provider constructor */  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }

  /**
   * deploy contract
   * @param {DeploymentModel} deployment
   * @param {WorkFabricInfraestructureModel} workInfra
   * @returns {Observable<any>}
   */
  public deployContract(deployment: DeploymentModel, workInfra: WorkFabricInfraestructureModel): Observable<any> {
    const data = {
      type: workInfra.blockchainType,
      user_id: deployment.deployedBy._id,
      workspace_id: deployment.workspace._id,
      peers: [deployment.setup[0].peer._id],
      channels: [deployment.setup[0].channel._id]
    };


    return concat(
      // this.add(BLOCKCHAIN_ENDPOINTS.enrollAdmin, this.generateEnrollData(deployment.workspace), null, true),
      this.update(BLOCKCHAIN_ENDPOINTS.switchEnv, deployment.workspace._id, null, null, true),
      this.update(BLOCKCHAIN_ENDPOINTS.deploy, deployment.setup[0].smartcontract._id, data, BlockchainDeployResponseModel, true)
    ).pipe(
        last()
    );
  }




  /**
   * invoke contract
   * @param {object} data
   * @param {WorkspaceModel} [workspace]
   * @returns {Observable<any>}
   */
  public invokeContract(data: object, workspace?: WorkspaceModel): Observable<any> {
    if (_.has(data, 'args')) {
      data['args'] = _.isEmpty(data['args']) ? [""] : data['args'];
    }

    return concat(
      // this.add(BLOCKCHAIN_ENDPOINTS.enrollAdmin, this.generateEnrollData(workspace), null, true),
      this.update(BLOCKCHAIN_ENDPOINTS.switchEnv, _.get(workspace, '_id'), null, null, true),
      this.getByPost(BLOCKCHAIN_ENDPOINTS.invoke, data, null, true)
    ).pipe(
        last()
    );
  }



  /**
   * Returns if the contract is deployed in the workspace with version
   * @param {ContractModel} contract
   * @param {workspace} [workspace] if not defined: search first deployed valid
   * @param {string} [version] if not defined: search any version
   */
  public hasContractDeployed(contract: ContractModel, workspace?: WorkspaceModel, version?: string): boolean {
    if (_.isNil(contract) || !contract.hasValidId() || _.isEmpty(_.get(contract, 'instantiated'))) {
      return false;
    }

    if (_.isNil(workspace)) {
      return true; // instantiated length > 0 (previous if validation)

    }
      return _.isNil(version)
        ? _.filter(contract.instantiated, {'workspace._id': workspace._id}).length > 0
        : _.filter(contract.instantiated, {'workspace._id': workspace._id, 'version': version}).length > 0;

  }


  /*********************************** PRIVATE FUNCTIONS **************************/

  /**
   *
   * @param {WorkspaceModel} workspace
   */
  private generateEnrollData(workspace: WorkspaceModel): object {
    return {
      caURL: _.get(workspace, 'settings[0].certificateAuthorities[0].url'),
      mspid: _.get(workspace, 'settings[0].organizations[0].mspid')
    };
  }


}
