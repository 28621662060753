import { Component, OnInit, Injector } from '@angular/core';
import { MetaSimpleComponent } from './meta-simple.component';

import _ from 'lodash';


@Component({
  selector: 'app-meta-panel',
  template: ''
})
export class MetaPanelComponent extends MetaSimpleComponent implements OnInit {

  public navSetup = {
    header: {
      show: false
    },
    sideNav: {
      mode: 'side',
      opened: true,
      fixed: true,
      topGap: 0,
      bottomGap: 0
    }
  };

  /** constructor */
  constructor(private injectorMeta: Injector) {
    super(injectorMeta);
  }

  ngOnInit() {
  }



  /************************ DOM METHODS **************************/



  /************************ PROTECTED METHODS **************************/



  /************************ PRIVATE METHODS **************************/

}
