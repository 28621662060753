import { ConstructorModel } from '../../core/models/constructor.model';
import { ContractTemplateModel } from './contract-template.model';
import { UserModel } from '../user/user.model';
import { ContractAPIModel } from './contract-api.model';
import { getBlockchainImage, getBlockchainIcon } from '../../libs/blockchain';
import { ContractInstalledItemModel } from './contract-installed-item.model';
import { ContractInstantiatedItemModel } from './contract-instantiated-item.model';
import { PeerModel } from '../environment/peer.model';
import { ChannelModel } from '../environment/channel.model';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { WorkspaceListCardComponent } from '../../components/workspace-list-card/workspace-list-card.component';

import _ from 'lodash';

export class ContractModel extends ConstructorModel {
    _id: string;
    name: string;
    description: string;
    template: ContractTemplateModel;
    source: string;
    users: Array<UserModel>;
    api: ContractAPIModel;
    version: string;
    blockchain: string;
    type: string; // @TODO: por ahora indica el language no el tipo blockchain
    language: string;
    installed: Array<ContractInstalledItemModel>;
    instantiated: Array<ContractInstantiatedItemModel>;
    logo: string;
    icon: string;


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.description = this.setString('description', data);
        this.template = this.setEntity('template', ContractTemplateModel, data);
        this.source = this.setString('source', data);
        this.blockchain = this.setString('blockchain', data);
        this.type = this.setString('type', data);
        this.language = this.setString('language', data);
        this.users = this.setEntityList('users', UserModel, data);
        this.api = this.setEntity('api', ContractAPIModel, data);
        this.version = this.setString('version', data);
        this.installed = this.setEntityList('installed', ContractInstalledItemModel, data);
        this.instantiated = this.setEntityList('instantiated', ContractInstantiatedItemModel, data);
        this.logo = getBlockchainImage(this.blockchain);
        this.icon = getBlockchainIcon(this.blockchain);
    }



    /**
     * Returns if the indicated peers are installed
     * @param {Array<PeerModel> | Array<string>} peerList
     * @param {string} [version]
     * @returns {boolean}
     */
    public isInstalled(peerList: Array<PeerModel> | Array<string>, version?: string): boolean {
        if (_.isNil(peerList) || _.isEmpty(this, 'installed', [])) {
            return false;
        }

        for (const peer of peerList) {

            const peerInstalled = _.find(this.installed, (scPeer: PeerModel) => {
                return _.isNil(version)
                    ? _.get(scPeer, 'peer._id') === _.get(peer, '_id', peer)
                    : _.get(scPeer, 'peer._id') === _.get(peer, '_id', peer) && _.get(scPeer, 'version') === version
            });

            if (_.isNil(peerInstalled)) {
                return false;
            }
        }

        return true;
    }


    /**
     * Returns if the indicated channels are instantiated
     * @param {Array<ChannelModel> | Array<string>} channelList
     * @param {string} [version]
     * @returns {boolean}
     */
    public isInstantiated(channelList: Array<ChannelModel> | Array<string>, version?: string): boolean {
        if (_.isNil(channelList) || _.isEmpty(this, 'instantiated', [])) {
            return false;
        }

        for (const channel of channelList) {
            const channelInstantiated = _.find(this.instantiated, (scChannel: ChannelModel) => {
                return _.isNil(version)
                    ? _.get(scChannel, 'channel._id') === _.get(channel, '_id', channel)
                    : _.get(scChannel, 'channel._id') === _.get(channel, '_id', channel) && _.get(scChannel, 'version') === version
            });

            if (_.isNil(channelInstantiated)) {
                return false;
            }
        }

        return true;
    }



    public needUpdating() {
        if (!_.isNil(_.find(this.installed, (item: ContractInstalledItemModel) => item.version !== this.version))) {
            return true;
        }

        if (!_.isNil(_.find(this.instantiated, (item: ContractInstantiatedItemModel) => item.version !== this.version))) {
            return true;
        }

        return false;
    }



    public getInstalledByWorkspace(workspaceId: string): Array<ContractInstalledItemModel> {
        return _.filter(this.installed, (item: ContractInstalledItemModel) => _.get(item, 'workspace._id') === workspaceId);
    }


    public getInstantiatedByWorkspace(workspaceId: string): Array<ContractInstantiatedItemModel> {
        return _.filter(this.instantiated, (item: ContractInstantiatedItemModel) => _.get(item, 'workspace._id') === workspaceId);
    }
}
