import { ConstructorModel } from '../../core/models/constructor.model';

export class RoleModel extends ConstructorModel {
    _id: string;
    name: string;
    scope: string;


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.scope = this.setString('scope', data);
    }
}
