import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VALIDATION_ERRORS } from '@common/const/form.const';
import { MetaSimpleComponent } from './meta-simple.component';
import { ConstructorModel } from '../models/constructor.model';
import { FormFieldType, InputEnum } from '@common/types/form-field.type';
import { Subscription } from 'rxjs';


import _ from 'lodash';


export interface ControlDifferenceType {
  cname: string; // control name to comparate
  value: any; // value to comparate
  data?: any; // data to insert in query
}


@Component({
  selector: 'app-meta-form',
  template: ''
})
export class MetaFormComponent extends MetaSimpleComponent implements OnInit {
  public item: any;
  public fullEdit = false;
  public isNewItem = false;
  public editionMode = false;
  public forma: FormGroup;

  public inputTypeList = InputEnum;
  public extendFormFields: Array<FormFieldType> = [];
  public extendComboOptions: object = {language: []};
  public firstTry: boolean;

  protected itemId = null;
  protected itemBackup: any;

  protected initialFormFields: Array<string>;

  private __translateSrv: TranslateService;
  /** constructor */
  constructor(private injectorMeta: Injector) {
    super(injectorMeta);
    this.__translateSrv = injectorMeta.get(TranslateService);
  }

  ngOnInit() { }



  /************************ DOM METHODS **************************/

  /**
   * Return if a input has error and must be showed
   * @param {string} cName
   * @param {FormGroup} [fGroup]
   */
  hasError(cName: string, fGroup?: FormGroup): boolean {
    const fg = _.isNil(fGroup) ? this.forma : fGroup;

    if (_.isNil(_.get(fg.controls, cName))) {
      return false;
    }

    return _.get(fg.controls, cName).invalid;
  }

   /**
   * Return if a input has error and must be showed
   * @param {string} cName
   */
  hasErrorExtended(fGroup: FormGroup, cName: string): boolean {
    if (_.isNil(_.get(fGroup, ['controls', cName]))) {
      return false;
    }


    return _.get(fGroup, ['controls', cName, 'invalid']);
  }


  /**
   * Return the error text to validator associated rule
   * @param {string} cName
   */
  showError(cName: string, fGroup?: FormGroup): string {
    const fg = _.isNil(fGroup) ? this.forma : fGroup;

    if (!this.hasError(cName, fGroup)) {
      return '';
    }

    const input = <AbstractControl>_.get(fg.controls, cName);
    const error = VALIDATION_ERRORS[_.get(_.keys(input.errors), '[0]')] || VALIDATION_ERRORS['default'];
    const toReplace = _.has(error, 'replace')
      ? _.mapValues(error.replace, (replaceKey) =>  _.get(input, replaceKey, ''))
      : undefined;

    return this.__translateSrv.instant(error.msg, toReplace);
  }


  /**
   * Return the error text to validator associated rule
   * @param {string} cName
   */
  showErrorExtended(fGroup: FormGroup, cName: string): string {
    if (!this.hasErrorExtended(fGroup, cName)) {
      return '';
    }

    const input = <AbstractControl>_.get(fGroup.controls, cName);
    const error = VALIDATION_ERRORS[_.get(_.keys(input.errors), '[0]')] || VALIDATION_ERRORS['default'];
    const toReplace = _.has(error, 'replace')
      ? _.mapValues(error.replace, (replaceKey) =>  _.get(input, replaceKey, ''))
      : undefined;

    return this.__translateSrv.instant(error.msg, toReplace);
  }


  /**
   * Return if input value exist
   * @param {string} cName
   */
  hasValue(cName: string): boolean {
    if (_.isNil(_.get(this.forma.controls, cName))) {
      return false;
    }

    return !_.isNil(_.get(this.forma.controls, cName).value) && _.get(this.forma.controls, cName).value !== '';
  }



  /**
   * Reset the input value
   * @param {string} cName
   */
  clearInput(cName: string) {
    if (!_.isNil(_.get(this.forma.controls, cName))) {
      _.get(this.forma.controls, cName).setValue('');
    }
  }



  /**
   * Returns the input enable status
   * @param {string} cName
   */
  isEnabled(cName: string): boolean {
    if (_.isNil(_.get(this.forma.controls, cName))) {
      return false;
    }

    return _.get(this.forma.controls, cName).enabled;
  }

  /************************ PROTECTED METHODS **************************/

  /**
   * Enable edition mode
   */
  protected startEdition() {
    if (!_.isNil(this.item) && this.item instanceof ConstructorModel) {
      this.itemBackup = this.item.clone();
    }

    this.firstTry = false;
    this.editionMode = true;
  }



  /**
   * Disable edition mode and restore backup optionally
   * @param {boolean} [restore]
   */
  protected stopEdition(restore?: boolean) {
    if (restore && this.item instanceof ConstructorModel) {
      this.item = this.itemBackup.clone();
    }

    this.editionMode = false;
  }



  /**
   * Set flags for new item
   */
  protected setNewMode() {
    this.isNewItem = true;
    this.fullEdit = true;
    this.itemId = null;
    this.startEdition();
  }


  /**
   * Enable or disable all form inputs
   * @param enable
   */
  protected updateFormDisabledStandard(enable: boolean = true) {
    if (enable) {
      _.forIn(this.forma.controls, (inputControl: FormControl, name) => {
        inputControl.enable();
      });

    } else {
      _.forIn(this.forma.controls, (inputControl: FormControl, name) => {
        inputControl.disable();
      });
    }
  }


  /**
   * Fill the form with model property that are called equal
   * @param {string} [defaultValue = '-']
   */
  protected updateFormDataStandard(defaultValue: string = '-') {
    if (this.editionMode) {
      defaultValue = '';
    }

    this.updateControlDataStandard(this.forma, null, defaultValue);
  }


  /**
   * Recursive method to set values in formControls
   * @param {AbstractControl | any} control
   * @param {string} [path]
   * @param {string} [defaultValue+
   */
  private updateControlDataStandard(control: AbstractControl | any, path?: string, defaultValue?: string) {
    if (control instanceof FormControl) {
      const value = _.isNil(_.get(this.item, path)) || _.get(this.item, path) === '' ? defaultValue : _.get(this.item, path);
      control.setValue(value);

    } else {
      _.forEach(_.get(control, 'controls'), (gControl: AbstractControl | any, key: string | number) => {
        const gKey = _.isNumber(key) ? {path: '[:k]', sep: ''} : {path: ':k', sep: '.'};
        const cPath = _.filter([path, gKey.path.replace(':k', key.toString())], (p) => !_.isNil(p) && p !== '');

        this.updateControlDataStandard(gControl, cPath.join(gKey.sep), defaultValue);
      });
    }
  }


  /**
   * Initializa form with item values
   * @param {string} [defaultValue]
   */
  protected initFormStandard(defaultValue?: string) {
    this.updateFormDataStandard(defaultValue);
    this.updateFormDisabledStandard(false);
  }



  /**
   * Forces to mark as touched the indicated inputs
   * @param {Array<string>} cNameList
   */
  protected forceMarkAsTouched(cNameList: Array<string> = []) {
    _.forIn(this.forma.controls, (inputControl: FormControl, name) => {
      if (_.includes(cNameList, name)) {
        inputControl.markAsTouched();
      }
    });
  }



  /**
   * Store the formControl names
   */
  protected backupFormControlNames() {
    this.initialFormFields = _.keys(this.forma.controls);
  }



  /**
   * Extends the form with extra formControls and update the indicated initial formControls.
   * It requires to make the backup of formControlNames previously
   *
   * @param {Array<FormFieldType>} [fields = []]
   */
  protected extendFormStandard(fields: Array<FormFieldType> = []) {
    // delete no initial FormControls
    _.forEach(this.forma.controls, (control, cName: string) => {
      if (this.initialFormFields.indexOf(cName) === -1) {
        this.forma.removeControl(cName);
      }
    });


    // updated initial FormControls if exists in extend fields, add extend fields to formGroup
    this.extendFormFields = _.filter(fields, (field: FormFieldType) => {
      if (_.isNil(field)) {
        return false;
      }

      const control = <FormControl>_.get(this.forma.controls, field.name);

      if (!_.isNil(control)) {
        control.setValue(field.control.default);

        if (field.html.input.type === InputEnum.select) {
          this.extendComboOptions[field.name] = _.get(field.html.input, 'values', []);
        }

        return false;
      }

      this.forma.addControl(field.name, new FormControl({value: field.control.default, disabled: field.control.disabled},  field.control.validations));
      return true;
    });
  }


  /**
   * Returns an array that contains the keys of values updated
   * @param {Array<ControlDifferenceType>} [extraList]
   * @returns {object}
   */
  protected getDifferencesFromItem(extraList?: Array<ControlDifferenceType>): object {
    const formData = this.forma.getRawValue();
    const diffList = this.itemBackup.getDifferences(formData);
    let query = _.pick(formData, diffList);

    if (_.isNil(extraList)) {
      return query;
    }


    _.forEach(extraList, (extra: ControlDifferenceType) => {
      if (this.forma.controls[extra.cname].value !== extra.value) {
        query = Object.assign(query, extra.data ? extra.data : {cname: this.forma.controls[extra.cname].value});
      }
    });

    return query;
  }


  protected isAliveSubscription(subscription$: Subscription) {
    return !_.isNil(subscription$) && !subscription$.closed;
  }
}
