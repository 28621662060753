import { Pipe, PipeTransform } from '@angular/core';

import _ from 'lodash';

/** Pipe for texts */
@Pipe({
  name: 'BLMtext'
})
export class BLMTextPipe implements PipeTransform {

  /**
   * Returns text string formatted
   * @example {{text | BLMtext:''}}
   * @param {string } value: date to format
   * @param {string} [defaultValue=-] default value for empty text
   */
  transform(value: string, defaultValue: string = '-'): string {
    return !_.isNil(value) && value !== '' ? value : defaultValue;
  }

}
