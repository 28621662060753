import { ConstructorModel } from '../../core/models/constructor.model';
import { getCloudImage } from '../../libs/blockchain';


export class CloudModel extends ConstructorModel {
    name: string;
    key: string;
    logo: string;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.name = this.setString('name', data);
        this.key = this.setString('key', data);
        this.logo = getCloudImage(this.key);
    }
}
