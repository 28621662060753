import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';

import { environment } from '@environments/environment';
import { PERSISTENCE_KEYS } from '@common/const/persistence.const';

import { SessionModel } from '@common/models/session/session.model';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { RoleModel } from '@common/models/role/role.model';
import { UserModel } from '@common/models/user/user.model';
import { MenuModel } from '@common/models/navigation/menu.model';
import { APP_MENU } from '@common/const/menu.const';


/** @ignore */
const ENVIRONMENT = environment;
import _ from 'lodash';


@Injectable()
export class SessionService {

  private _workspaceList: Array<WorkspaceModel> = [];
  private _roleTypeList: Array<RoleModel> = [];

  private _subject: BehaviorSubject<SessionModel> = new BehaviorSubject<SessionModel>(this.getSession());
  private _subjectWorkspace: BehaviorSubject<Array<WorkspaceModel>> = new BehaviorSubject<Array<WorkspaceModel>>([]);
  private _subjectRoleTypes: BehaviorSubject<Array<RoleModel>> = new BehaviorSubject<Array<RoleModel>>([]);
  /** constructor */
  constructor( private _alertSrv: AlertService,
               private _translateSrv: TranslateService) { }


  /**
   * Returns an observable instance for session data
   * @returns {Observable<object>}
   */
  onChange(): Observable<SessionModel> {
    return this._subject.asObservable();
  }


  /**
   * Returns an observable instance for workspace list
   * @returns {Observable<Array<WorkspaceModel>>}
   */
  onChangeWorkspaces(): Observable<Array<WorkspaceModel>> {
    return this._subjectWorkspace.asObservable();
  }


  /**
   * Returns an observable instance for role types list
   * @returns {Observable<Array<RoleModel>>}
   */
  onChangeRoleTypes(): Observable<Array<RoleModel>> {
    return this._subjectRoleTypes.asObservable();
  }



  /**
   * Save data session in localstorage
   * @param {SessionModel} session
   * @returns {boolean}
   */
  startSession(session: SessionModel): boolean {
    if (!this.validateSession(session)) {
      return false;
    }

    this.updateLangSession(session.language);
    localStorage.setItem(PERSISTENCE_KEYS.session, JSON.stringify(session));
    this._subject.next(session);

    return true;
  }



  /**
   * Return the user data session
   * @returns {SessionModel}
   */
  getSession(): SessionModel {
    const session = this.loadSession();

    if (!this.validateSession(session)) {
      return null;
    }

    return session;
  }



  /**
   * Delete user data session
   */
  endSession() {
    localStorage.removeItem(PERSISTENCE_KEYS.session);
  }


  /**
   * Delete user data session and show alert
   */
  expireSession() {
    localStorage.removeItem(PERSISTENCE_KEYS.session);
    this._alertSrv.expired();
  }


  /**
   * Return if exist a valid session
   * @returns {boolean}
   */
  existSession(): boolean {
    return this.validateSession(this.loadSession());
  }



  /**
   * Return the app language
   * @returns {string}
   */
  getLangSession(): string {
    let lang = _.get(this.getSession(), 'language');
    if (!_.isNil(lang) && lang !== '') {
      return lang;
    }

    lang = this._translateSrv.getBrowserLang();
    if (!_.isNil(lang) && lang !== '') {
      return lang.substring(0, 2);
    }

    return ENVIRONMENT.language.default;
  }



  /**
   * Set the translation language
   * @param {string} [lang] lang
   * @returns {boolean}
   */
  updateLangSession(lang?: string): boolean {
    const appLang = lang || this.getLangSession();
    const langsRegExp = new RegExp(this._translateSrv.getLangs().join('|'), 'i');
    const validLang = langsRegExp.test(appLang);

    this._translateSrv.use(validLang ? appLang : ENVIRONMENT.language.default);
    this._subject.next(this.getSession());
    return validLang;
  }



  /**
   * Return the available language translations
   * @returns {Array<string>}
   */
  getLangList(): Array<string> {
    return this._translateSrv.getLangs();
  }



  /**
   * Returns the user session last workspace
   * @returns {WorkspaceModel}
   */
  getWorkspaceSession(): WorkspaceModel {
    return _.get(this.getSession(), 'lastWorkspace', new WorkspaceModel({_id: null}));
  }



  /**
   * Update last workspace in session storage
   * @param {WorkspaceModel} workspace
   * @returns {boolean}
   */
  updateWorkspaceSession(workspace: WorkspaceModel): boolean {
    if (!this.existSession() || _.isNil(workspace)) {
      return false;
    }

    const session = this.getSession();
    session.lastWorkspace = workspace;
    return this.startSession(session);
  }



  /**
   * Returns the userModel session
   * @returns {UserModel}
   */
  getUserSession(): UserModel {
    return this.existSession()
      ? this.getSession().getUser()
      : null;
  }



  /**
   * Update user in session storage
   * @param {WorkspaceModel} env
   * @returns {boolean}
   */
  updateUserSession(user: UserModel): boolean {
    if (!this.existSession() || _.isNil(user)) {
      return false;
    }

    const session = this.getSession();
    session.updateUser(user);
    return this.startSession(session);
  }



  /**
   * Returns the user workspace list
   * @returns {Array<WorkspaceModel>}
   */
  getWorkspaceList(): Array<WorkspaceModel> {
    return this._workspaceList;
  }



  /**
   * Update workspace list
   * @param {Array<WorkspaceModel>} list
   */
  setWorkspaceList(list: Array<WorkspaceModel>) {
    this._workspaceList = _.isNil(list) || _.isEmpty(list) ? [] : list;
    this._subjectWorkspace.next(this._workspaceList);

    // @TODO: la API deberia devolver el usuario con role y workspace, quitar metodo cuando sea asi
    this._subject.next(this.getSession());
  }


  /**
   * Returns the user role type list available
   * @returns {Array<RoleModel>}
   */
  getRoleTypeList(): Array<RoleModel> {
    return this._roleTypeList;
  }


  /**
   * Returns the roleModel by ID
   */
  getRoleById(roleId: string): RoleModel {
    return _.find(this._roleTypeList, {_id: roleId});
  }



  /**
   * Update role type list
   * @param {Array<WorkspaceModel>} list
   */
  setRoleTypeList(list: Array<RoleModel>) {
    this._roleTypeList = _.isNil(list) || _.isEmpty(list) ? [] : list;
    this._subjectRoleTypes.next(this._roleTypeList);

    // @TODO: la API deberia devolver el usuario con role y workspace, quitar metodo cuando sea asi
    this._subject.next(this.getSession());
  }



  /**
   * Returns the user logged menu
   * @returns {MenuModel}
   */
  getMenu(): MenuModel {
    if (!this.existSession()) {
      return new MenuModel();
    }

    return APP_MENU;
  }


  /**************************** PRIVATE METHODS *********************************/

  /**
   * Return if data is a valid DataSSessionModelession
   * @param {SessionModel} session
   */
  private validateSession(session: SessionModel): boolean {
    return !_.isNil(session) && !_.isNil(session.token) && session.token !== '' && !_.isNil(_.get(session.getUser(), '_id'));
  }



  /**
   * Return the Session localStorage
   */
  private loadSession(): SessionModel {
    return new SessionModel(JSON.parse(localStorage.getItem(PERSISTENCE_KEYS.session)));
  }
}
