import { ConstructorModel } from '@common/core/models/constructor.model';
import { UserModel } from '@common/models/user/user.model';
import { BPMNModel } from './bpmn/bpmn.model';

import _ from 'lodash';

export class BusinessProcessModel extends ConstructorModel {
    _id: string;
    name: string;
    owner: UserModel;
    description: string;
    template: BusinessProcessModel;
    markAsTemplate: boolean;
    group: string;

    published: boolean;
    editable: boolean;
    createdDate: string;

    diagram: BPMNModel;
    historic: Array<BPMNModel>;
    reviewers: Array<UserModel>;
    thumbnail: string;


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.owner = this.setEntity('owner', UserModel, data);
        this.description = this.setString('description', data);
        this.template = this.setEntity('template', BusinessProcessModel, data);

        this.published = this.setBoolean('published', data);
        this.editable = this.setBoolean('editable', data);
        this.createdDate = this.setDate('createdDate', data) || this.setDate('created_date', data);
        this.diagram = this.setEntity('diagram', BPMNModel, data);
        this.historic = this.setEntityList('historic', BPMNModel, data);
        this.reviewers = this.setEntityList('reviewers', UserModel, data);

        this.thumbnail = this.setThumbnail(data);
        this.markAsTemplate = this.setBoolean('markAsTemplate', data) || this.setBoolean('mark_as_template', data);
        this.group = this.setString('group', data);
    }


    private setThumbnail(data?: object): string {
        const image = this.setString('thumbnail', data);

        return _.isNil(image) || image === ''
            ? './assets/images/logo_thumbnail.png'
            : image;
    }
}
