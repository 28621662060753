import { ConstructorModel } from '../../core/models/constructor.model';
import { getBlockchainImage, getBlockchainIcon } from '../../libs/blockchain';


export class BlockchainModel extends ConstructorModel {
    name: string;
    key: string;
    abbreviation: string;
    languages: Array<string>;
    logo: string;
    icon: string;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.name = this.setString('name', data);
        this.key = this.setString('key', data);
        this.abbreviation = this.setString('abbreviation', data);
        this.languages = this.setList('languages', data);
        this.logo = getBlockchainImage(this.key);
        this.icon = getBlockchainIcon(this.key);
    }
}
