import { ConstructorModel } from '@common/core/models/constructor.model';
import { BPMNPropertyModel } from './bpmn-property.model';

import _ from 'lodash';


export class BPMNPropertyTypeModel extends ConstructorModel {
    id: string;
    name: string;
    native: boolean;
    properties: Array<BPMNPropertyModel>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.id =  this.setString('id', data);
        this.name = this.setString('name', data);
        this.native = this.setBoolean('native', data);
        this.properties = this.setEntityListFromJson('properties', BPMNPropertyModel, data);
    }


    export(): object {
        return {
            id: this.id
        };
    }


    generateId() {
        this.id = `type_${(+new Date).toString(36)}`;
    }
}
