import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractBlockchainProviderService } from '../shared/abstract-blockchain.provider.service';

import { BLOCKCHAIN_ENDPOINTS } from './blockchain-endpoints.const';

import { ContractModel } from '@common/models/contract/contract.model';
import { DeploymentModel } from '@common/models/deployment/deployment.model';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { BlockchainDeployResponseModel } from '@common/models/blockchain/blockcahain-deploy-response.model';
import { WorkFabricInfraestructureModel } from '@common/models/infraestructure/work-infraestructure.model';



import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EthereumBlockchainProviderService extends AbstractBlockchainProviderService {

  /** provider constructor */  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }


  /**
   * deploy contract
   * @param {DeploymentModel} deployment
   * @param {WorkFabricInfraestructureModel} workInfra
   * @returns {Observable<any>}
   */
  public deployContract(deployment: DeploymentModel, workInfra: WorkFabricInfraestructureModel): Observable<any> {
    const data = {
      type: workInfra.blockchainType,
      user_id: deployment.deployedBy._id,
      workspace_id: deployment.workspace._id
    };

    return this.update(BLOCKCHAIN_ENDPOINTS.deploy, deployment.setup[0].smartcontract._id, data, BlockchainDeployResponseModel, true);
  }


  /**
  * invoke contract
  * @param {object} data
  * @param {WorkspaceModel} workspace
  * @returns {Observable<any>}
  */
 public invokeContract(data: object, workspace?: WorkspaceModel): Observable<any> {
    return this.getByPost(BLOCKCHAIN_ENDPOINTS.invoke, data, null, true);
  }



  /**
   * Returns if the contract is deployed in the workspace with version
   * @param {ContractModel} contract
   * @param {workspace} [workspace] if not defined: search first deployed valid
   * @param {string} [version] if not defined: search any version
   */
  public hasContractDeployed(contract: ContractModel, workspace?: WorkspaceModel, version?: string): boolean {
    if (_.isNil(contract) || !contract.hasValidId() || _.isEmpty(_.get(contract, 'installed'))) {
      return false;
    }

    if (_.isNil(workspace)) {
      return true; // installed length > 0 (previous if validation)
    }

    return _.isNil(version)
        ? _.filter(contract.installed, {'workspace._id': workspace._id}).length > 0
        : _.filter(contract.installed, {'workspace._id': workspace._id, 'version': version}).length > 0;
  }


  /******************* PRIVATE METHODS ***********************/


}
