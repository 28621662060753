
import { environment } from '../../../../environments/environment';

import _ from 'lodash';
import { HttpServiceAbstract } from './abstract.http.service';
import { HttpHeaders } from '@angular/common/http';

/** @ignore */
const ENVIRONMENT = environment;


/**
 * Custom http Service.
 * Use the environment configuration parameters to build the requests (API url, token, timeout, etc) as well to parse the response
 */
// @Injectable()
export class HttpDeployModuleService extends HttpServiceAbstract {
  /** @ignore */
  protected _urlServer: string = ENVIRONMENT.API_MODULE_DEPLOY.url;

  /**
   * XHR header
   */
  protected generateHeaders(contentType?: string, acceptType?: string): HttpHeaders {
    const headers = {
      'Content-Type': !_.isNil(contentType) ? contentType : 'application/json',
      'Accept': !_.isNil(acceptType) ? acceptType : 'application/json',
      'authorization': 'Bearer '+ _.get(this._sessionSrv.getSession(), 'token')
    };

    // Object.assign(headers, this.getAuthorizationHeader());

    return new HttpHeaders(headers);
  }
}
