import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule, MatCardModule, MatFormFieldModule, MatSelectModule, MatTableModule, MatSortModule, MatPaginatorModule,
  MatProgressBarModule, MatAutocompleteModule, MatInputModule, MatIconModule} from '@angular/material';
import { BLMPipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';

/** @ignore */
const MODULE_LIST = [
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatButtonModule,
  MatCardModule,
  DirectivesModule,
  BLMPipesModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatInputModule,
  MatIconModule
];

@NgModule({
  declarations: [],
  imports: MODULE_LIST,
  exports: MODULE_LIST
})
export class TableMaterialModule { }
