import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarHeaderComponent } from './navbar-header.component';
import { MatToolbarModule } from '@angular/material/toolbar';


@NgModule({
  declarations: [NavbarHeaderComponent],
  imports: [
    CommonModule,
    MatToolbarModule
  ],
  exports: [NavbarHeaderComponent]
})
export class NavbarHeaderModule { }
