import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
/** @ignore */
const ENVIRONMENT = environment;

import _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class FeaturesProviderService {
  /** constructor */
  constructor() { }


  isFeatureEnabled(feature: string): boolean {
    return _.get(ENVIRONMENT, `${feature}.enable`, true);
  }

  isFeatureDeveloping(feature: string): string {
    return _.get(ENVIRONMENT, `${feature}.developing`, false);
  }


  getFeatureTooltip(feature): string {
    return this.isFeatureDeveloping(feature)
        ? 'System.Common.DevelopingTooltip'
        : 'System.Common.DisabledTooltip';
  }


  ///////////////////// SMART CONTRACT MODULE ///////////////////////////////////////////

  isEnableSCDeploy(): boolean {
    return this.isFeatureEnabled('features.deployment.smartcontract');
  }

  getSCDeployTooltip(): string {
    return this.isFeatureEnabled('features.deployment.smartcontract')
      ? ''
      : this.getFeatureTooltip('features.deployment.smartcontract');
  }


  ///////////////////// DIAGRAM MODULE ///////////////////////////////////////////

  isEnableDiagramEdition(): boolean {
    return this.isFeatureEnabled('features.edition.diagram');
  }

  isEnableDiagramDeploy(): boolean {
    return this.isFeatureEnabled('features.deployment.diagram');
  }

  getDiagramDeployTooltip(): string {
    return this.isFeatureEnabled('features.deployment.diagram')
      ? ''
      : this.getFeatureTooltip('features.deployment.diagram');
  }


  ///////////////////// MONITOR MODULE ///////////////////////////////////////////


  isEnableMonitorEdition(): boolean {
    return this.isFeatureEnabled('features.edition.monitor');
  }

  isEnableMonitorDeploy(): boolean {
    return this.isFeatureEnabled('features.deployment.monitor');
  }

  getMonitorDeployTooltip(): string {
    return this.isFeatureEnabled('features.deployment.monitor')
      ? ''
      : this.getFeatureTooltip('features.deployment.monitor');
  }


  ///////////////////// INFRAESTRUCTURE MODULE ///////////////////////////////////////////

  isEnableInfraEdition(): boolean {
    return this.isFeatureEnabled('features.edition.infraestructure');
  }

  isEnableInfraCreation(): boolean {
    return this.isFeatureEnabled('features.creation.infraestructure');
  }

  getInfraEditionTooltip(): string {
    return this.isFeatureEnabled('features.edition.infraestructure')
      ? ''
      : this.getFeatureTooltip('features.edition.infraestructure');
  }

  getInfraCreationTooltip(): string {
    return this.isFeatureEnabled('features.creation.infraestructure')
      ? ''
      : this.getFeatureTooltip('features.creation.infraestructure');
  }

}
