import { ConstructorModel } from '../../core/models/constructor.model';
import _ from 'lodash';

export class WorkTreeModel extends ConstructorModel {
    _id: string;
    name: string;
    parent: WorkTreeModel;
    children: Array<WorkTreeModel>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.parent = this.setEntity('parent', WorkTreeModel, data);
        this.children = this.setEntityList('children', WorkTreeModel, data);
    }
}
