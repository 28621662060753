import { ConstructorModel } from '@common/core/models/constructor.model';
import { RoleModel } from '../role/role.model';
import { UserModel } from '../user/user.model';


export class WorkspaceCloudModel extends ConstructorModel {
    _id: string;
    cloud_id: String;
    name: String;
    type: String;
    description: String;

    /** model constructor */
    constructor(data?: object) {
        super();
        this._id = this.setId('_id', data);
        this.cloud_id = this.setString('cloud_id', data);
        this.name = this.setString('name', data);
        this.type = this.setString('type', data);
        this.description = this.setString('description', data);
    }
}