import { ConstructorModel } from '../../core/models/constructor.model';
import { ItemMenuModel } from './item-menu.model';


export class MenuModel extends ConstructorModel {
    items: Array<ItemMenuModel>;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.items = this.setEntityList('items', ItemMenuModel, data);
    }
}
