import { ConstructorModel } from '@common/core/models/constructor.model';
import { MonitorAnchorPeerModel } from './monitor-anchor-peer.model';
import { MonitorChannelModel } from './monitor-channel.model';


export class MonitorOrganizationModel extends ConstructorModel {
  name: string;
  num: number;
  domain: string;
  mspid: string;
  admins: Array<string>;
  clients: Array<string>;
  ca: string;
  ordererEndpoints: Array<string>;
  anchorPeers: Array<string>;
  peersExternalBuilders: Array<string>;
  peers: Array<string>;
  channels: Array<MonitorChannelModel>;

  /** constructor */
  constructor(data?: object) {
    super();

    this.name = this.setString('name', data);
    this.num = this.setString('num', data);
    this.domain = this.setString('domain', data);
    this.mspid = this.setString('mspid', data);
    this.admins = this.setList('admins', data);
    this.clients = this.setList('clients', data);
    this.ca = this.setString('ca', data);
    this.ordererEndpoints = this.setList('ordererEndpoints', data);
    this.anchorPeers = this.setList('anchorPeers', data);
    this.peersExternalBuilders = this.setList('peersExternalBuilders', data);
    this.peers = this.setList('peers', data);
    this.channels = this.setEntityList('channels', MonitorChannelModel, data);
  }
}
