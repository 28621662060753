export interface PersistenceBackParams {
    url: string;
    persistenceKey: string;
    value: any;
}

export const PERSISTENCE_KEYS = {
    session: 'usession'
};

