import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/shared/session.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  /** constructor */
  constructor(private _router: Router,
              private _sessionSrv: SessionService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this._sessionSrv.existSession()) {
      this._router.navigate(['/panel']);
    }

    return true;
  }
}
