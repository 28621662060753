import { ConstructorModel } from '../../core/models/constructor.model';

import _ from 'lodash';

export class ItemMenuModel extends ConstructorModel {
    icon: string;
    title: string;
    link: string;
    callback: string;
    subMenu: Array<ItemMenuModel>;
    enable: boolean;
    active: boolean;

    /** model constructor */
    constructor(data?: object) {
        super();

        this.icon = this.setString('icon', data);
        this.title = this.setString('title', data);
        this.link = this.setString('link', data);
        this.callback = this.setString('callback', data);
        this.subMenu = this.setEntityList('subMenu', ItemMenuModel, data);
        this.enable = this.setBoolean('enable', data);
        this.active = this.setBoolean('active', data);

        this.parseSubMenuLinks();
    }


    public hasSubMenu(): boolean {
        return !_.isNil(this.subMenu) && !_.isEmpty(this.subMenu);
    }


    public hasCallback(): boolean {
        return !_.isNil(this.callback) && this.callback !== '';
    }

    /********************* PRIVATE METHODS *********************/
    private parseSubMenuLinks() {
        if (!this.hasSubMenu()) {
            return;
        }

        _.forEach(this.subMenu, (item: ItemMenuModel) => {
            if (_.startsWith(item.link, './')) {
                item.link = item.link.replace('.', this.link);
            }
        });
    }
}
