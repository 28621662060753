import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';

import { PaginatorModel } from '../../models/paginator/paginator.model';
import { date4Api } from '../meta-functions';
import { SYSTEM_API, NO_REST_KEYS } from '../../const/system.const';
import { ProviderResponseType } from '../../types/provider-response.type';
import { Constructable } from '@common/interfaces/constructable.interface';


/**
 *  @Author Elías Romero <elias.romero@kolokium.com>
 *  @description: provider service for API restFull - MVC
 *  Methods: get, getById, add, update, updateByPost, delete
 */


import _ from 'lodash';
import { HttpServiceAbstract } from './abstract.http.service';
import { AbstractMetaProviderService } from './abstract-meta-provider.service';

/**
 * Provider service to extend. Contains a collection of methods to make API rest calls
 */
// @Injectable()
export class MetaProviderService extends AbstractMetaProviderService {
  constructor(protected injectorMeta: Injector) {
    super();
    this._http = injectorMeta.get(HttpService);
  }
}
