import { ConstructorModel } from '../../core/models/constructor.model';
import { PeerModel } from './peer.model';
import { ChannelModel } from './channel.model';
import { OrganizationModel } from './organization.model';
import { OrdererModel } from './orderer.model';
import { CertificateAuthorityModel } from './certificate-authority.model';

import _ from 'lodash';


export class SettingModel extends ConstructorModel {
    _id: string;
    name: string;
    peers: Array<PeerModel>;
    version: string;
    channels: Array<ChannelModel>;
    organizations: Array<OrganizationModel>;
    orderers: Array<OrdererModel>;
    certificateAuthorities: Array<CertificateAuthorityModel>;
    client: object; // @TODO pendiente de confirmar


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.peers = this.setEntityList('peers', PeerModel, data);
        this.version = this.setString('version', data);
        this.channels = this.setEntityList('channels', ChannelModel, data);
        this.organizations = this.setEntityList('organizations', OrganizationModel, data);
        this.orderers = this.setEntityList('orderers', OrdererModel, data);
        this.certificateAuthorities = this.setEntityList('certificateAuthorities', CertificateAuthorityModel, data);
        this.client = this.setObject('client', data);
    }
}
