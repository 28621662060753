import { ConstructorModel } from '@common/core/models/constructor.model';


export class MonitorOrderingServiceModel extends ConstructorModel {
  type: string;

  /** constructor */
  constructor(data?: object) {
    super();

    this.type = this.setString('type', data);
  }
}
