import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BLMTextPipe } from './text.pipe';
import { BLMdatePipe } from './date.pipe';
import { BLMdatetimePipe } from './datetime.pipe';
import { BLMtimePipe } from './time.pipe';
import { BLMCellPipe } from './cell.pipe';
import { BLMAmountPipe } from './amount.pipe';
import { BLMNumberPipe } from './number.pipe';

/** @ignore */
const PIPE_LIST = [
  BLMTextPipe,
  BLMdatePipe,
  BLMdatetimePipe,
  BLMtimePipe,
  BLMCellPipe,
  BLMAmountPipe,
  BLMNumberPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: PIPE_LIST,
  exports: PIPE_LIST
})
export class BLMPipesModule { }
