import { date4Api } from '../meta-functions';
import { Constructable } from '@common/interfaces/constructable.interface';
import { ParamsTypes } from '@common/types/model-param.type';

import _ from 'lodash';


export abstract class ConstructorModel {

    // ********************* PUBLIC METHODS ********************* //

    /**
     * Returns if exist an valid id field
     */
    public hasValidId(): boolean {
        const id = this.setString(this.existParam('_id', this) ? '_id' : 'id', this) ;

        return !_.isNil(id) && id !== '';
    }


    /**
     * Clone method
     */
    public clone(): any {
        return _.cloneDeep(this);
    }


    /**
     * return a date in string format valid for API
     * @param {any} date
     */
    public encodeDate(date: any): string {
        return date4Api(date);
    }



    /**
      * Return an array list with param names that value is different
      * @param {object} data
      * @returns {Array<string>}
      */
     public getDifferences(data: object): Array<string> {
      const dif = [];
      const params = this.getParamMap();

      if (this.isValidParamsArray(params.nil)) {
          params.nil.forEach(key => {
              if (!_.isNil(_.get(data, key))) { dif.push(key); }
          });
      }

      if (this.isValidParamsArray(params.basic)) {
          params.basic.forEach(key => {
              if (!this.isEqualParam(key, data)) { dif.push(key); }
          });
      }

      if (this.isValidParamsArray(params.object)) {
          params.object.forEach(key => {
              if (!this.isEqualObjectParam(key, data)) { dif.push(key); }
          });
      }


      if (this.isValidParamsArray(params.entity)) {
          params.entity.forEach(key => {
              if (!this.isEqualEntityParam(key, data)) { dif.push(key); }
          });
      }


      if (this.isValidParamsArray(params.basicList)) {
          params.basicList.forEach(key => {
              if (!this.isEqualListParam(key, data)) { dif.push(key); }
          });
      }


      if (this.isValidParamsArray(params.entityList)) {
          params.entityList.forEach(key => {
              if (!this.isEqualEntityListParam(key, data)) { dif.push(key); }
          });
      }

      return dif;
    }


    /**
     * Return if two instances are equals
     * @param {object} data
     * @returns {boolean}
     */
    public isEqual(data: object): boolean {
        const params = this.getParamMap();

        for (const param of params.nil) {
            if (_.isNil(this[param]) !== _.isNil(_.get(data, param))) { return false; }
        }

        for (const param of params.basic) {
            if (!this.isEqualParam(param, data)) { return false; }
        }

        for (const param of params.basicList) {
            if (!this.isEqualListParam(param, data)) { return false; }
        }

        for (const param of params.object) {
            if (!this.isEqualObjectParam(param, data)) { return false; }
        }

        for (const param of params.entity) {
            if (!this.isEqualEntityParam(param, data)) { return false; }
        }

        for (const param of params.entityList) {
            if (!this.isEqualEntityListParam(param, data)) { return false; }
        }

        return true;
    }


    /**
     * Returns the class as object
     * @param [strict] skip empty attributes
     * @param [omitted] attribute collection to ommit
     */
    asObject(strict?: boolean, omitted?: Array<string>): object {
      const dataObject = {};

      for (const t in this) {
        if (!this.hasOwnProperty(t) || this[t] instanceof Function || (omitted || []).includes(t)) {
          continue;
        }

        if (strict) {
          if (!(this[t]) || ((_.isArray(this[t]) || _.isObject(this[t])) && _.isEmpty(this[t])) || /^_/.test(t)) {
            continue;
          }
        }

        if (this.isEntityList(this[t])) {
          _.forEach(_.get(this, t, []), (item: ConstructorModel, index) => {
            _.set(dataObject, [t, index], item.asObject(strict));
          });

        } else if (this.isEntity(this[t])) {
          const temp = _.cloneDeep(this[t]) as ConstructorModel;
          _.set(dataObject, [t], temp.asObject(strict));

        } else {
          _.set(dataObject, [t], this[t]);
        }
      }

      return dataObject;
    }


    // ********************* PROTECTED METHODS ********************* //

    /**
     * Return a validate any type for a data-name
     * @param {string} name: param name
     * @param {object} [data] data
     * @param {any} [defaultValue=''] defaultValue
     * @returns {string}
     */
    protected setAny(name: string, data?: object, defaultValue: any = null): any {
      return this.setParam(name, defaultValue, data);
    }

    /**
     * Return a validate id type for a data-name
     * @param {string} name: param name
     * @param {object} [data] data
     * @param {any} [defaultValue=''] defaultValue
     * @returns {string}
     */
    protected setId(name: string, data?: object, defaultValue: string = ''): any {
        return _.isString(data) ? data : this.setParam(name, defaultValue, data);
    }


    /**
     * Return a validate string type for a data-name
     * @param {string} name: param name
     * @param {object} [data] data
     * @param {any} [defaultValue=''] defaultValue
     * @returns {string}
     */
    protected setString(name: string, data?: object, defaultValue: string = ''): any {
        return this.setParam(name, defaultValue, data);
    }


    /**
     * Return a validate number type for a data-name
     * @param {string} name: param name
     * @param {object} [data] data
     * @param {number} [defaultValue=0] defaultValue
     * @returns {Number}
     */
    protected setNumber(name: string, data?: object, defaultValue: number = 0): any {
        const value = this.setParam(name, defaultValue, data);

        return Number(_.isString(value) ? value.replace(',', '.') : value);
    }


     /**
     * Return a validate boolean type for a data-name
     * @param {string} name: param name
     * @param {object} [data] data
     * @param {boolean} [defaultValue=false] defaultValue
     * @returns {boolean}
     */
    protected setBoolean(name: string, data?: object, defaultValue: boolean = false): any {
        return this.setParam(name, defaultValue, data);
    }


    /**
     * Return a validate date type for a data-name
     * @param {string} name: param name
     * @param {object} [data] data
     * @param {any} [defaultValue=null] defaultValue
     * @returns {Date}
     */
    protected setDate(name: any, data?: object, defaultValue: any = null): any {
        const newDate = this.setParam(name, defaultValue, data);

        return _.isNil(newDate)
            ? null
            : _.isNumber(data[name]) || /^\d+$/.test(newDate)
                ? new Date(parseInt(newDate, 10))
                : newDate;
    }



    /**
     * Return a validate collection
     * @param {string} name: param name
     * @param {object} [data] data
     * @param {any} [defaultValue=[]] defaultValue
     * @returns {Array<any>}
     */
    protected setList(name: string, data: object = {}, defaultValue: any = []): Array<any> {
        return _.isNil(data) || !data.hasOwnProperty(name) || !Array.isArray(data[name])
            ? defaultValue
            : data[name];
    }



    /**
     * Return a validate object
     * @param {string} name: param name
     * @param {object} [data] data
     * @param {any} [defaultValue=[]] defaultValue
     * @returns {object}
     */
    protected setObject(name: string, data: object = {}, defaultValue: any = {}): object {
        return _.isNil(data) || !data.hasOwnProperty(name) || !(typeof data[name] === 'object')
            ? defaultValue
            : data[name];
    }


    /**
     * Return a validate model for a data-name
     * @param {string} name: param name
     * @param {any} modelClass: class to modelate
     * @param {object} [data] data
     * @param {any} [defaultValue=null] defaultValue
     * @param {number} [defaultValue=null] depth
     * @returns {modelClass}
     */
    protected setEntity(name: string, modelClass: any, data?: object, defaultValue: any = null, depth?: number): any {
        return _.isNil(data) || !data.hasOwnProperty(name) || depth === 0
            ? defaultValue ? this.createClass(modelClass, defaultValue) : null
            : data[name] instanceof modelClass
                ? data[name]
                : this.createClass(modelClass, data[name], depth - 1);
    }


    /**
     * Return a validate model for a data-name (json maybe)
     * @param {string} name: param name
     * @param {any} modelClass: class to modelate
     * @param {object} [data] data
     * @param {any} [defaultValue=null] defaultValue
     * @param {number} [defaultValue=null] depth
     * @returns {modelClass}
     */
    protected setEntityFromJson(name: string, modelClass: any, data: object, defaultValue: any = null, depth?: number): any {
      const eData = _.get(data, name);

      return this.setEntity(
        name,
        modelClass,
        _.isString(eData) && _.trim(eData) !== ''
            ? {[name]: JSON.parse(_.get(data, name, '{}'))}
            : data,
        defaultValue,
        depth
      );
    }



    /**
     * Return a validate model for a data-name collection
     * @param {string} name: param name
     * @param {any} modelClass: class to modelate
     * @param {object} [data] data
     * @param {any} [defaultValue=[]] defaultValue
     * @param {number} [defaultValue=null] depth
     * @returns {Array<modelClass>}
     */
    protected setEntityList(name: string, modelClass: any, data: object = {}, defaultValue: any = [], depth?: number): Array<any> {
        return _.isNil(data) || !data.hasOwnProperty(name) || !Array.isArray(data[name]) || depth === 0
            ? defaultValue
            : data[name].map((item) => {
                if (item instanceof modelClass) {
                    return item;
                }

                return this.createClass(modelClass, item, depth - 1);
            });
    }


    /**
     * Return a validate model for a data-name collection (json maybe)
     * @param {string} name: param name
     * @param {any} modelClass: class to modelate
     * @param {object} [data] data
     * @param {any} [defaultValue=null] defaultValue
     * @param {number} [defaultValue=null] depth
     * @returns {modelClass}
     */
    protected setEntityListFromJson(name: string, modelClass: any, data: object, defaultValue: any = [], depth?: number): any {
      const eData = _.get(data, name);

      return this.setEntityList(
        name,
        modelClass,
        _.isString(eData) && _.trim(eData) !== ''
            ? {[name]: JSON.parse(_.get(data, name, '{}'))}
            : data,
        defaultValue,
        depth
      );
    }


    /**
     * Return if exist model for a data-name
     * @param {string | Array<string>} name: param name
     * @param {object} [data] data
     * @returns {boolean}
     */
    protected existParam(name: string | Array<string>, data?: object): boolean {
        if (name instanceof Array) {
            return -1 !== _.findIndex(name, (n: string) => !_.isNil(data) && (data.hasOwnProperty(n) || data.hasOwnProperty(_.snakeCase(n))));
        }

        return !_.isNil(data) && (data.hasOwnProperty(name.toString()) || data.hasOwnProperty(_.snakeCase(name)));
    }


    /**
     * Return if exsit model for a data-name
     * @param {string} name: param name
     * @param {object} [data] data
     * @returns {boolean}
     */
    protected existParamList(name: string, data?: object): boolean {
        return this.existParam(name, data) && (Array.isArray(data[name]) || Array.isArray(data[_.snakeCase(name)]));
    }


    /**
     * Return if a basic param is equal
     * @param {string} name
     * @param {object} data
     * @returns {boolean}
     */
    protected isEqualParam(name: string, data: object): boolean {
        return this.existParam(name, this) && this.existParam(name, data) && this[name] === data[name];
    }


    /**
     * Return if an entity param is equal
     * @param {string} name
     * @param {object} data
     * @returns {boolean}
     */
    protected isEqualEntityParam(name: string, data: object): boolean {
        if (!this.existParam(name, this) || !this.existParam(name, data) || _.isNil(this[name]) !== _.isNil(data[name])) {
            return false;

        } else if (!_.isNil(this[name]) && !_.isNil(data[name])) {
            return  this[name].isEqual(data[name]);
        }

        return true;
    }

    /**
     * Return if an object param is equal
     * @param {string} name
     * @param {object} data
     * @returns {boolean}
     */
    protected isEqualObjectParam(name: string, data: object): boolean {
        if (!this.existParam(name, this) || !this.existParam(name, data) || _.isNil(this[name]) !== _.isNil(data[name])) {
            return false;
        }

        return _.isEmpty(this.getDifferences(data[name]));
    }



    /**
     * Return if an basicList param is equal
     * @param {string} name
     * @param {object} data
     * @returns {boolean}
     */
    protected isEqualListParam(name: string, data: object): boolean {
        if (!this.existParamList(name, this) || !this.existParamList(name, data) || !this.comparableArray(this[name], data[name])) {
          if (name === 'domains') {
            console.log('fallo 1')
          }
            return false;
        }

        if (_.isEmpty(this[name]) && _.isEmpty(data[name])) {
          return true;
        }

        for (let i = 0, nloop = this[name].length; i < nloop; i++) {
            if (this[name][i] !== data[name][i]) {
                return false;
            }
        }

        return true;
    }


    /**
     * Return if an entityList param is equal
     * @param {string} name
     * @param {object} data
     * @returns {boolean}
     */
    protected isEqualEntityListParam(name: string, data: object): boolean {
        if (!this.existParamList(name, this) || !this.existParamList(name, data) || !this.comparableArray(this[name], data[name])) {
            return false;
        }

        for (let i = 0, nloop = this[name].length; i < nloop; i++) {
            if (_.isNil(this[name][i]) !== _.isNil(data[name][i])) {
                return false;

            } else if (!_.isNil(this[name][i]) && !_.isNil(data[name][i]) && !this[name][i].isEqual(data[name][i])) {
                return false;
            }
        }

        return true;
    }


    /**
     * Return the params map for a determinate instance of any class
     * @returns {ParamsTypes}
     */
    protected getParamMap(): ParamsTypes {
        const params = <ParamsTypes>{
            basic: [],
            basicList: [],
            object: [],
            entity: [],
            entityList: [],
            nil: []
        };


        for (const t in this) {
            if (this.hasOwnProperty(t) && !(<any>this[t] instanceof Function)) {

                if (_.isNil(this[t])) {
                    params.nil.push(t);

                } else if (this.isEntityList(this[t])) {
                    params.entityList.push(t);

                } else if (this.isArray(this[t])) {
                    params.basicList.push(t);

                } else if (this.isEntity(this[t])) {
                    params.entity.push(t);

                } else if (this.isObject(this[t])) {
                    params.object.push(t);

                } else {
                    params.basic.push(t);
                }
            }
        }

        return params;
    }


    // ********************* PRIVATE METHODS ********************* //
    /**
     * Return a validate type for a data-name
     * @param {object} [data] data
     * @param {any} defaultValue: value to asign how default in other case
     * @param {string} name: param name
     * @returns {any}
     */
    private setParam(name: string, defaultValue: any, data?: object): any {
        if (!_.isNil(data)) {
            if (data.hasOwnProperty(name)) {
                return data[name];
            } else if (data.hasOwnProperty(_.snakeCase(name))) {
                return data[_.snakeCase(name)];
            }
        }

        return defaultValue;
    }


    /**
     *
     * @param ctor: class to instantiate
     * @param params: class data
     * @param depth: instantiation level
     */
    private createClass<T>(ctor: Constructable<T>, params?: object, depth?: number): T {
      if (depth === 0) {
        return null;
      }

      return new ctor(params, depth);
    }


    /**
     * Returns if is a valid array: array not null and no empty
     * @param {Array<string>} params
     * @returns {boolean}
     */
    private isValidParamsArray(params: Array<string>): boolean {
        return !_.isNil(params) && _.isArray(params);
    }


    /**
     * Returns if two array are comparable
     * @param {Array<any>} array1
     * @param {Array<any>} array2
     * @returns {boolean}
     */
    private comparableArray(array1: Array<any>, array2: Array<any>): boolean {
        if (_.isNull(array1) !== _.isNull(array2)
            || !(_.isArray(array1) && _.isArray(array2) && array1.length === array2.length)) {
            return false;
        }

        return true;
    }


     /**
     * Returns if item is an Object (native) or not
     * @param {any} item
     * @returns {boolean}
     */
    private isObject(item: any): boolean {
        return typeof item === 'object' && !(<any>item instanceof Array) && !(<any>item instanceof Date) && !(item instanceof ConstructorModel);
    }


    /**
     * Returns if item is an Entity class or not
     * @param {any} item
     * @returns {boolean}
     */
    private isEntity(item: any): boolean {
        return typeof item === 'object' && !(<any>item instanceof Array) && !(<any>item instanceof Date) && item instanceof ConstructorModel;
    }


    /**
     * Returns if item is an Entity List or not
     * @param {any} item
     * @returns {boolean}
     */
    private isEntityList(item: any): boolean {
        return <any>item instanceof Array && !_.isEmpty(item) && typeof item[0]  === 'object';
    }



    /**
     * Returns if item is an array or not
     * @param {any} item
     * @returns {boolean}
     */
    private isArray(item: any): boolean {
        return <any>item instanceof Array && !(!_.isEmpty(item) && typeof item[0]  === 'object');
    }
}
