export const ENDPOINTS = {
  SIGN: {
    login: { post: 'session/login' },
    logout: { post: 'session/logout' },
    resetPass: { post: 'user/password' },
  },
  SYSTEM: {
    languages: 'system/languages',
    blockchains: 'system/blockchains',
    roles: 'system/roles',
    clouds: ''
  },
  WORKSPACE: {
    get: 'workspace',
    getById: 'workspace/:id',
    post: 'workspace',
    put: 'workspace/:id',
    delete: 'workspace/:id',
    members: {
      get: 'workspace/:id/member',
      post: { 'member': 'workspace/member', 'id/member': 'workspace/:id/member' },
      put: { 'id/member/mid': 'workspace/:id/member/:mid', 'id/member': 'workspace/:id/member' },
      delete: 'workspace/:id/member/:mid',
    },
    clouds: {
      get: 'workspace/:id/cloud',
      put: { 'id/cloud': 'workspace/:id/cloud' },
      delete: 'workspace/:id/cloud',
    },
    bp: {
      post: 'workspace/:id/bp'
    },
    infra: {
      post: 'workspace/:id/infrastructure'
    },
    contracts: {
      post: 'workspace/:id/contracts'
    },
  },
  USER: {
    get: 'user',
    getById: 'user/:id',
    post: 'user',
    put: 'user/:id',
    delete: 'user/:id', // @TODO: pendiente en API,
    changeWorkspace: 'user/:id/lastworkspace',
    changePass: 'user/:id/password',
    changeMemo: 'user/:id/memorable',
    detailsOTP: 'user/OTP'
  },
  BUSINESS_PROCESS: {
    getById: 'bp/:id',
    get: 'bp',
    post: 'bp',
    put: 'bp/:id',
    delete: '',
    getByUser: '',
    getTemplates: 'bp/template',
    diagram: {
      put: 'bp/:id/diagram',
      version: {
        post: 'bp/:id/diagram/version'
      }
    },
    reviewer: {
      add: 'bp/:id/reviewer', // @TODO: servicio API no operativo
      delete: 'bp/:id/reviewer' // @TODO: servicio API no operativo
    },
    getElementById: 'k1/bpelement/getElement/:id'
  },
  DEPLOYMENTS: {
    getById: 'deployment/:id',
    getByBPAndEnv: 'deployment/byBpEnv',
    getByScAndEnv: 'deployment/byScEnv',
    get: 'deployment',
    post: 'deployment',
    postBySmartcontract: 'deployment/bySC',
    put: 'deployment/:id',
    putBySmartcontract: 'deployment/bySC/:id',
    markAsDeployed: 'deployment/:id/deployed',
    delete: 'deployment/:id'
  },
  BPMN: {
    get: 'bpmn',
    groups: {
      get: 'bpmn/group',
      getById: 'bpmn/group/:id',
      post: 'bpmn/group',
      put: 'bpmn/group/:id',
      delete: 'bpmn/group/:id'
    },
    process: {
      get: 'bpmn/process',
      getById: 'bpmn/process/:id',
      post: 'bpmn/process',
      put: 'bpmn/process/:id',
      delete: 'bpmn/process/:id'
    },
    component: {
      get: 'bpmn/component',
      getById: 'bpmn/component/:id',
      post: 'bpmn/component',
      put: 'bpmn/component/:id',
      delete: 'bpmn/component/:id'
    },
    connection: {
      get: 'bpmn/connection',
      getById: 'bpmn/connection/:id',
    }
  },
  WORK_INFRAESTRUCTURE: {
    getById: 'workinfraestructure/:id',
    get: 'workinfraestructure',
    post: 'workinfraestructure',
    put: 'workinfraestructure/:id',
    delete: 'workinfraestructure/:id',
    template: {
      getById: 'workinfraestructure/:id',
      get: 'workinfraestructure/template',
    },
    diagram: {
      put: 'workinfraestructure/:id/source'
    },
    version: {
      post: 'workinfraestructure/:id/version'
    },
    reviewer: {
      post: 'workinfraestructure/:id/reviewer',
      delete: 'workinfraestructure/:id/reviewer'
    },
    deploy: { // https://web.juan.net-f424d2a94b8b3cdd50e46ea7537e2d01.kdeploy.me/api/deploy
      post: 'network',
      put: 'network',
      get: 'network',
      getById: 'network/:id',
      delete: 'network/:id',
      getMetricsById: 'metrics/:id',
    },
    blockchain: { // https://web.juan.net-f424d2a94b8b3cdd50e46ea7537e2d01.kdeploy.me/api/deploy
      post: 'fabric',
      put: 'fabric',
    },
    blockchainEthereum: {
      get: "blockchain/:id",
      post: "ethereum",
      put: "ethereum"
    },
    gcp: {
      post: 'gcp',
      put: 'gcp',
      delete: 'gcp/:id',
      get: 'cloud/:id'
    },
    aws: {
      post: 'aws',
      put: 'aws',
      delete: 'aws/:id',
      get: 'cloud/:id'
    }
    // deploy: { // https://web.juan.net-f424d2a94b8b3cdd50e46ea7537e2d01.kdeploy.me/api/deploy
    //   post: 'https://web.juan.net-f424d2a94b8b3cdd50e46ea7537e2d01.kdeploy.me/api/deploy',
    //   put: 'https://web.juan.net-f424d2a94b8b3cdd50e46ea7537e2d01.kdeploy.me/api/deploy/:id',
    //   getById: 'https://web.juan.net-f424d2a94b8b3cdd50e46ea7537e2d01.kdeploy.me/api/deploy/:id',
    //   delete: 'https://web.juan.net-f424d2a94b8b3cdd50e46ea7537e2d01.kdeploy.me/api/deploy/:id'
    // }
  },
  WORK_CONTRACT: {
    getById: 'workcontract/:id',
    get: 'workcontract',
    post: 'workcontract',
    put: 'workcontract/:id',
    delete: 'workcontract/:id',
    clone: 'workcontract/clone',
    version: {
      getById: 'workcontract/:id/version/:vid',
      post: 'workcontract/:id/version',
    },
    template: {
      getById: 'workcontract/:id',
      get: 'workcontract/template',
      post: 'workcontract',
      put: 'workcontract/:id',
      delete: 'workcontract/:id',
      clone: 'workcontract/clone'
    },
    reviewer: {
      get: 'workcontract/:id/reviewer',
      post: 'workcontract/:id/reviewer',
      put: 'workcontract/:id/reviewer/:mid',
      delete: 'workcontract/:id/reviewer/:mid'
    },
    deploy: { // https://web.juan.net-f424d2a94b8b3cdd50e46ea7537e2d01.kdeploy.me/api/deploy
      post: 'smartcontract/install',
      getById: 'smartcontract/:id',
      put: 'smartcontract'
    },
    deployments: {
      post: 'workcontract/:id/deployment',
      getByContractId: 'workcontract/:id/deployment',
      updateState: 'workcontract/deployment/:id'
    }
  }
};
