import { ConstructorModel } from '../../core/models/constructor.model';

import _ from 'lodash';

export class ContractAPIMethodModel extends ConstructorModel {
    name: string;
    numArgs: number;
    type: string;
    legend: object;


    /** model constructor */
    constructor(data?: object) {
        super();

        this.name = this.setString('name', data);
        this.numArgs = this.setNumber('numArgs', data) || this.setNumber('num_args', data);
        this.type = this.setString('type', data);
        this.legend = this.setObject('legend', data);
    }


    public getLegend(): string {
        return _.get(this.legend, 'key');
    }
}
