import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';

import { MetaProviderService } from '@common/core/services/meta-provider.service';

import { ENDPOINTS } from '@common/const/endpoint.const';
import { SessionModel } from '@common/models/session/session.model';
import { UserModel } from '@common/models/user/user.model';


import _ from 'lodash';


/**
 * API Authentication provider
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationProviderService extends MetaProviderService {
  /** constructor */
  constructor(private _injector: Injector) {
    super(_injector);
  }


  /**
   * Return the user data session if login ok
   * @param {string} user
   * @param {string} pwd
   */
  login(user: string, pwd: string, otp: string): Observable<any> {
    const tokenParams = {
      username: _.trim(user),
      password: _.trim(pwd),
      otp: _.trim(otp)
    };

    return this.getByPost(ENDPOINTS.SIGN.login.post, tokenParams, SessionModel, true);
  }



  /**
   * End user session
   */
  logout(): Observable<any> {
    return this.getByPost(ENDPOINTS.SIGN.logout.post, null, null, true);
  }

  /**
   * Restore password
   * @param {string} user
   */
  restorePassword(user: string): Observable<any> {
    return this.getByPost(ENDPOINTS.SIGN.resetPass.post, {user: _.trim(user)}, null, true);
  }


  /**
   * Change password
   * @param {string} userId
   * @param {string} oldPass
   * @param {string} newPass
   */
  public changePassword(userId: string, oldPass: string, newPass: string): Observable<any> {
    const query = {
      old_password: oldPass,
      new_password: newPass
    };
    return this.update(ENDPOINTS.USER.changePass, userId, query, UserModel, true);
  }

/**
   * Change Memorable sentence. You need to specify the correct password
   * @param {string} userId
   * @param {string} curPass
   * @param {string} newMemo
   */
  public changeMemorable(userId: string, curPass: string, newMemo: string): Observable<any> {
    const query = {
      cur_pass: curPass,
      new_memo: newMemo
    };
    return this.update(ENDPOINTS.USER.changeMemo, userId, query, UserModel, true);
  }
}
