import { ConstructorModel } from '../../core/models/constructor.model';
import { getBlockchainImage, getBlockchainIcon } from '../../libs/blockchain';

export class ContractTemplateModel extends ConstructorModel {
    _id: string;
    name: string;
    description: string;
    documentation: string;
    source: string;
    version: string;
    language: string;
    blockchain: string;
    logo: string;
    icon: string;


    /** model constructor */
    constructor(data?: object) {
        super();

        this._id = this.setId('_id', data);
        this.name = this.setString('name', data);
        this.description = this.setString('description', data);
        this.documentation = this.setString('documentation', data);
        this.source = this.setString('source', data);
        this.version = this.setString('version', data);
        this.language = this.setString('language', data);
        this.blockchain = this.setString('blockchain', data);
        this.logo = getBlockchainImage(this.blockchain);
        this.icon = getBlockchainIcon(this.blockchain);
    }
}
