import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';

import { PaginatorModel } from '../../models/paginator/paginator.model';
import { date4Api } from '../meta-functions';
import { SYSTEM_API, NO_REST_KEYS } from '../../const/system.const';
import { ProviderResponseType } from '../../types/provider-response.type';
import { Constructable } from '@common/interfaces/constructable.interface';


/**
 *  @Author Elías Romero <elias.romero@kolokium.com>
 *  @description: provider service for API restFull - MVC
 *  Methods: get, getById, add, update, updateByPost, delete
 */


import _ from 'lodash';
import { HttpServiceAbstract } from './abstract.http.service';
import { query } from '@angular/animations';

/**
 * Provider service to extend. Contains a collection of methods to make API rest calls
 */
@Injectable()
export abstract class AbstractMetaProviderService {
  /** @ignore */
  protected _http: HttpServiceAbstract;
  /** @ignore */
  protected _ResponseKeys = SYSTEM_API.Response;
  /** constructor */
  // constructor(protected injectorMeta: Injector) {
  //   this._http = injectorMeta.get(HttpService);
  // }
  // constructor() {}


  /**
   * Return random delay in ms
   * @param {number} [max = 200]
   */
  protected delayRandom(max: number = 200): number {
    return Math.floor(Math.random() * max) + 1;
  }



  /**
   * GET method provider.
   * Set modelClass param to null for object response or "Class" needed to modelate response.
   * @param {string} endpoint: url to call
   * @param {object} query: params to inject in URL. Optional
   * @param {Class} modelClass: (Optional) class to instanciate in return method. Null for object response
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   *
   * @returns {Observer<object|class|null|undefined}: null: empty data. undefined: error on SuccessResponse
   */
  protected get(endpoint: string,
    query?: object,
    modelClass?: any,
    loaderShow: boolean = false,
    alertShow: boolean = false): Observable<any> {
    return this._http.getXHR(endpoint, query, loaderShow, alertShow).pipe(
      map(response => {
        return this.onSuccess(response, modelClass);
      })
    );
  }


  /**
   * GET by id method provider
   * Set modelClass param to null for object response or "Class" needed to modelate response
   * @param {string} endpoint: url to call (without id injected in string)
   * @param {any} id: id entity
   * @param {object} query: params to inject in URL. Optional
   * @param {Class} modelClass: (Optional) class to instanciate in return method. Null for object response
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  protected getById(endpoint: string,
    id: any,
    query?: object,
    modelClass?: any,
    loaderShow: boolean = false,
    alertShow: boolean = false): Observable<any> {
    return this.get(this.getUrlInjectingId(endpoint, id), query, modelClass, loaderShow, alertShow);
  }


  /**
   * POST method provider
   * Set modelClass param to null for object response or "Class" needed to modelate response
   * @param {string} endpoint: url to call
   * @param {object} query: data params. Optional
   * @param {Class} modelClass: (Optional) class to instanciate in return method. Null for object response
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  protected getByPost(endpoint: string,
    query?: object,
    modelClass?: any,
    loaderShow: boolean = false,
    alertShow: boolean = false): Observable<any> {
    return this.add(endpoint, query, modelClass, loaderShow, alertShow);
  }



  /**
   * ADD method provider
   * Set modelClass param to null for object response or "Class" needed to modelate response
   * @param {string} endpoint: url to call
   * @param {object} query: data params. Optional
   * @param {Class} modelClass: (Optional) class to instanciate in return method. Null for object response
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  protected add(endpoint: string,
    query?: object,
    modelClass?: any,
    loaderShow: boolean = false,
    alertShow: boolean = false): Observable<any> {

    return this._http.postXHR(endpoint, query, loaderShow, alertShow).pipe(
      map(response => {
        return this.onSuccess(response, modelClass);
      })
    );
  }



  /**
   * UPDATE method provider
   * Set modelClass param to null for object response or "Class" needed to modelate response
   * @param {string} endpoint: url to call (without id injected in string)
   * @param {any} id: id entity
   * @param {object} query: data params. Optional
   * @param {Class} modelClass: (Optional) class to instanciate in return method. Null for object response
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  protected update(endpoint: string,
    id: any,
    query?: object,
    modelClass?: any,
    loaderShow: boolean = false,
    alertShow: boolean = false): Observable<any> {
    return this._http.putXHR(this.getUrlInjectingId(endpoint, id), query, loaderShow, alertShow).pipe(
      map(response => {
        return this.onSuccess(response, modelClass);
      })
    );
  }


  /**
   * UPDATE method provider
   * Set modelClass param to null for object response or "Class" needed to modelate response
   * @param {string} endpoint: url to call (without id injected in string)
   * @param {object} query: data params. Optional
   * @param {Class} modelClass: (Optional) class to instanciate in return method. Null for object response
   * @param {boolean} loaderShow: show loader component. False default
   * @param {boolean} alertShow: show alert component for success or error XHR response. False default
   */
  protected updateByPost(endpoint: string,
    query?: object,
    modelClass?: any,
    loaderShow: boolean = false,
    alertShow: boolean = false): Observable<any> {
    return this.add(endpoint, query, modelClass, loaderShow, alertShow);
  }


  /**
     * DELETE method provider
     * Set modelClass param to null for object response or "Class" needed to modelate response
     * @param {string} endpoint: url to call (without id injected in string)
     * @param {any} id: id entity
     * @param {Class} modelClass: (Optional) class to instanciate in return method. Null for object response
     * @param {boolean} loaderShow: show loader component. False default
     * @param {boolean} alertShow: show alert component for success or error XHR response. False default
     * @param {any} query: query params
  */
  protected delete(endpoint: string,
    id: any,
    modelClass?: any,
    loaderShow: boolean = false,
    alertShow: boolean = false,
    query: any = null
  ): Observable<any> {

    return this._http.deleteXHR(this.getUrlInjectingId(endpoint, id), loaderShow, alertShow, query).pipe(
      map(response => {
        return this.onSuccess(response, modelClass);
      })
    );
  }



  /**
   * Return a query formatted object.
   * querySetup object:
   *  - name: string to print in query
   *  - isDate: boolean | isNumber: boolean | isObject: {key: string} | isArray: boolean | isBoolean: boolean | isCollection
   *  - invalid: [values to invalidate param]
   *  - map: string to map (only for array or collection)
   * Example for querySetup:
   * const querySetup = {
   *   type: {name: 'idTipoCliente', isDate: false, invalid: [null, '']},
   *   status: {name: 'estado', isDate: false, invalid: [null, '']},
   *   dni: {name: 'numeroIdentificador', isDate: false, invalid: [null, '']},
   *   startDate: {name: 'fechaInicio', isDate: true, invalid: [null, '']}
   * };
   * @param {object} query
   * @param {object} querySetup
   * @param {object} dataParams
   */
  protected generateQuery(query: object = {}, querySetup: object, dataParams: object): object {
    _.forEach(querySetup, (param, key) => {
      const value = _.get(dataParams, key, null);

      if (!param.hasOwnProperty('invalid') || (param.hasOwnProperty('invalid') && _.isArray(param.invalid) && !param.invalid.includes(value))) {
        if (param.isDate) {
          query[param.name] = date4Api(value);

        } else if (_.get(param, 'isObject')) {
          query[param.name] = _.set({}, param.isObject.key, value);

        } else if (_.get(param, 'isArray') || _.get(param, 'isCollection')) {
          query[param.name] = _.has(param, 'map')
            ? _.map(value, param.map)
            : value;

        } else if (param.isNumber || param.isBoolean) {
          query[param.name] = value;

        } else {
          query[param.name] = _.trim(value);
        }
      }
    });

    return query;
  }



  // *************************** protected METHODS *************************** //
  /**
   * Insert id value in URI
   * @param {string} endpoint
   * @param {any} id
   */
  protected getUrlInjectingId(endpoint: string, id: any): string {
    if (_.isNil(id) || _.isNil(endpoint)) {
      return '';
    }


    if (!endpoint.includes(':id')) {
      const url = endpoint.split('?');
      url[0] += _.endsWith(url[0], '/') ? ':id/' : '/:id/';
      endpoint = url.join('?');
    }

    return endpoint.replace(':id', id.toString());
  }


  /**
   * Class factory
   * @param {Constructable<T>} ctor: class name
   * @param {object} params: (optional)
   */
  protected createClass<T>(ctor: Constructable<T>, params?: object): T {
    return new ctor(params);
  }



  /**
   * Returns data modelated
   * @param {any} data
   * @param {any} modelClass
   */
  protected modelateData(data: any, modelClass: any): any {
    if (_.isNil(data)) {
      return null;
    }

    if (Array.isArray(data)) {
      const responseList = [];

      data.forEach(element => {
        responseList.push(this.createClass(modelClass, element));
      });

      return responseList;
    }

    return this.createClass(modelClass, data);
  }





  /**
   * On success http response
   * @param {any} response
   * @param {any} modelClass
   * @returns {ProviderResponseType}
   */
  protected onSuccess(response: any, modelClass: any): ProviderResponseType {
    if (_.isNil(response)) {
      return undefined;
    }

    // @TODO: reemplazar cuando se unifique la respuesta de la API
    // const data = _.get(response, this._ResponseKeys.Data, response);
    const data = !_.isEmpty(_.pick(response, NO_REST_KEYS))
      ? _.values(_.pick(response, NO_REST_KEYS))[0]
      : _.get(response, this._ResponseKeys.Data, response);

    const parsedResponse: ProviderResponseType = {
      data: _.isNil(modelClass) ? data : this.modelateData(data, modelClass),
      msg: _.get(response, this._ResponseKeys.Msg)
    };

    if (response.hasOwnProperty(this._ResponseKeys.Pagination)) {
      parsedResponse['pagination'] = new PaginatorModel(response[this._ResponseKeys.Pagination]);
    }


    return parsedResponse;
  }
}
