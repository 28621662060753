import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MatSidenav } from '@angular/material';
import { WorkspaceSessionComponent } from '@common/components/workspace-session/workspace-session.component';

import { WorkspaceProviderService } from '@common/services/providers/workspace-provider.service';
import { SessionService } from '@common/services/shared/session.service';
import { UserProviderService } from '@common/services/providers/user-provider.service';
import { SystemProviderService } from '@common/services/providers/system-provider.service';


import { MetaPanelComponent } from '@common/core/components/meta-panel.component';
import { WorkspaceModel } from '@common/models/workspace/workspace.model';
import { ProviderResponseType } from '@common/types/provider-response.type';
import { RoleModel } from '@common/models/role/role.model';
import { NavbarLateralComponent } from '@common/components/navbar-lateral/navbar-lateral.component';



import _ from 'lodash';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent extends MetaPanelComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('workSpaceSession') workSpaceSession: WorkspaceSessionComponent;
  @ViewChild('navBarLateral') navBarLateral: NavbarLateralComponent;


  public workspaceList: Array<WorkspaceModel> = [];
  public roleTypeList: Array<RoleModel> = [];
  /** constructor */
  constructor(private _injector: Injector,
              private _sessionSrv: SessionService,
              private _router: Router,
              private _userSrv: UserProviderService,
              private _systemSrv: SystemProviderService,
              private _workspaceSrv: WorkspaceProviderService) {
    super(_injector);
    this.getWorkspacesFromApi();
    this.getRolesFromApi();
    this.suscribeWorkspaceList();

    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.workSpaceSession.close();
      }
    });
  }

  ngOnInit() { }


  /*************************** DOM METHODS **********************/
  onToogleSideNav() {
    this.sidenav.toggle();
  }


  onChangeWorkspace(event: any) {
    if (_.isNil(_.get(event, 'value'))) {
      this.workSpaceSession.open();

    } else {
      this.updateSessionWorkspaceFromApi(event.value);
    }
  }

  suscribeWorkspaceList() {
    this._sessionSrv.onChangeWorkspaces().subscribe(
      (workspaceList) => {
        this.workspaceList = workspaceList;
      }
    );
  }



  /*************************** API METHODS **********************/
  private getWorkspacesFromApi() {
    if (!_.isNil(this.userLogged)) {

      this.subcriptionApi$['workspaceList'] = this._workspaceSrv.getWorkspacesByUser(this.userLogged).subscribe(
        (response: ProviderResponseType) => {
          this._sessionSrv.setWorkspaceList(_.get(response, 'data', []));
          this.workspaceList = this._sessionSrv.getWorkspaceList();
        },
        (error) => {
          this._sessionSrv.setWorkspaceList([]);
          this.workspaceList = [];
          this.showAlertModal('Workspace.List.Alert.FailLoadList', 'error', _.get(error, 'error.key'));
        },
        () => {
          this.unsubscribeApi('workspaceList');
        }
      );
    }
  }


  private getRolesFromApi() {
    if (!_.isNil(this.userLogged)) {
      this.subcriptionApi$['roleList'] = this._systemSrv.getRoleTypes().subscribe(
        (response: ProviderResponseType) => {
          this._sessionSrv.setRoleTypeList(_.get(response, 'data', []));
          this.roleTypeList = this._sessionSrv.getRoleTypeList();
        },
        (error) => {
          this._sessionSrv.setRoleTypeList([]);
          this.roleTypeList = [];
          console.log(error);
        },
        () => {
          this.unsubscribeApi('roleList');
        }
      );
    }
  }


  private updateSessionWorkspaceFromApi(workspace: WorkspaceModel) {
    if (!_.isNil(this.userLogged)) {
      this.subcriptionApi$['changeWorkspace'] = this._userSrv.updateLastWorkspace(this.userLogged, workspace).subscribe(
        (response: ProviderResponseType) => {
          if (!_.isNil(_.get(response, 'data')) && !_.isEmpty(response.data)) {
            this._sessionSrv.updateWorkspaceSession(workspace);
            this.workSpaceSession.close();
            this._router.navigateByUrl('/panel');

          } else {
            this.navBarLateral.reset();
            this.showAlertModal('Workspace.List.Alert.FailUpdateWorkspaceSession');
          }
        },
        (error) => {
          this.navBarLateral.reset();
          this.showAlertModal('Workspace.List.Alert.FailUpdateWorkspaceSession', 'error', _.get(error, 'error.key'));
        },
        () => {
          this.unsubscribeApi('changeWorkspace');
        }
      );
    }
  }
}
