import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../services/shared/session.service';

import { environment } from '../../../environments/environment';


import _ from 'lodash';
/** @ignore */
const ENVIRONMENT = environment;

/** Pipe for imports */
@Pipe({
  name: 'BLMamount'
})
export class BLMAmountPipe implements PipeTransform {

  /** class constructor */  /** constructor */
  constructor(private _translateSrv: TranslateService,
              private _sessionSrv: SessionService) { }

  /**
   * returns amount formatted
   * @example {{text | BLMamount:2.2}}
   * @param {number | string} value
   * @param {string} [digitsInfo = '1.2-2'] {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}
   * @param {string} [keyCurrency = 'Metric.Currency']
   * @param {string} [defaultValue = '-']
   * @returns {string}
   */
  transform(value: number | string, digitsInfo: string = '1.2-2', keyCurrency: string = 'Metric.Currency', defaultValue: string = '-'): string {
    const lang = this._sessionSrv.getLangSession();
    const locale = _.find(ENVIRONMENT.language.list, lang) ? lang : ENVIRONMENT.language.default;

    return !_.isNil(value) && (_.isNumber(value) || (_.isString(value) && value !== ''))
      ? formatNumber(Number(value),  locale, digitsInfo) + this._translateSrv.instant(keyCurrency)
      : defaultValue;
  }

}
