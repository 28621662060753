import { BpmnIOType } from '../types/diagram.type';
import { BPMNPropertyTypeModel } from '../models/business-process/bpmn/bpmn-property-type.model';

/** empty inital BPMN source */
export const EMPTY_BPMN =
'<?xml version="1.0" encoding="UTF-8"?>' +
'<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" targetNamespace="http://bpmn.io/schema/bpmn" id="Definitions_1">' +
  '<bpmn:process id="Process_1" isExecutable="false">' +
    '<bpmn:startEvent id="StartEvent_1"/>' +
  '</bpmn:process>' +
  '<bpmndi:BPMNDiagram id="BPMNDiagram_1">' +
    '<bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">' +
    '</bpmndi:BPMNPlane>' +
  '</bpmndi:BPMNDiagram>' +
'</bpmn:definitions>';

/** @default */
export const MIN_BPMN_ITEMS = 5;

/** modeler text settings */
export const MODELER_TEXT_RENDER = {
  defaultStyle: {
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 300
  },
  externalStyle: {
    fontSize: '14px',
    lineHeight: 1.2
  }
};

/** modeler style settings */
export const MODELER_RENDER = {
  defaultFillColor: '#fff',
  defaultStrokeColor: '#575757'
};

/** list of native types for diagram structs */
export const BPMN_NATIVE_TYPES: Array<BpmnIOType> = [
  new BPMNPropertyTypeModel({id: 'string', name: 'BusinessProcess.Item.Panel.Form.Parameter.StringTypeLabel', native: true}),
  new BPMNPropertyTypeModel({id: 'uint', name: 'BusinessProcess.Item.Panel.Form.Parameter.NumberTypeLabel', native: true}),
  new BPMNPropertyTypeModel({id: 'boolean', name: 'BusinessProcess.Item.Panel.Form.Parameter.BooleanTypeLabel', native: true}),
  new BPMNPropertyTypeModel({id: 'date', name: 'BusinessProcess.Item.Panel.Form.Parameter.DateTypeLabel', native: true})
];

/** list of types for diagram */
export const BPMNN_TYPE_COMBO_LIST = [
  {
    name: 'BusinessProcess.Item.Panel.Form.Parameter.NativeTypeLabel',
    types: BPMN_NATIVE_TYPES
  },
  {
    name: 'BusinessProcess.Item.Panel.Form.Parameter.CustomTypeLabel',
    types: []
  }
]
