export const SYSTEM_API = {
    Response: {
        Data: 'results',
        Msg: 'key',
        MsgPlainText: 'message',
        Pagination: 'pagination'
    },
    Error: {
        Msg: 'key',
        MsgPlainText: 'message',
        Filter: ['error', 'status', 'url']
    },
    Timeout: 0
};


// @TODO: quitar cuando se unifique la respuesta de la API
export const NO_REST_KEYS = ['result', 'available_blm_languages', 'peer', 'user', 'environments'];


/***************** STYLE **************************/
export const STYLE = {
    lateralNav: {
        collapsedHeight: '48px',
        expandedHeight: '48px'
    }
};


export const TABLE_SETTINGS = {
    standart: {
        limit: 10,
        options: [10, 15 , 20],

    },
    mini: {
        limit: 5,
        options: null,

    }
};


export const LATERAL_NAV = {
    TOP_WORSPACE: 5
};


export const LANGUAGE_DEFAULT = 'default';
