import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

import { SystemProviderService } from './common/services/providers/system-provider.service';
import { SessionService } from './common/services/shared/session.service';
import { ProviderResponseType } from './common/types/provider-response.type';

/** @ignore */
const ENVIRONMENT = environment;
import _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  /** constructor */
  constructor(private _translateSrv: TranslateService,
              private _sessionSrv: SessionService,
              private _systemSrv: SystemProviderService) {

    this._translateSrv.setDefaultLang(ENVIRONMENT.language.default);
    this.getSystemTranslations();
  }


  /*********************** PRIVATE METHODS ****************************/
  private getSystemTranslations() {
    this._systemSrv.getLanguages().subscribe(
      (response: ProviderResponseType) => {
        const langList = !_.isNil(response.data) && !_.isEmpty(response.data)
          ? response.data
          : ENVIRONMENT.language.list;
        this.setupTranslation(langList);

      },
      (error) => {
        this.setupTranslation(ENVIRONMENT.language.list);
        if (ENVIRONMENT.logs) {
          console.log(error);
        }
      }
    );
  }


  private setupTranslation(langList: Array<string>) {
    this._translateSrv.addLangs(langList);
    this._sessionSrv.updateLangSession();
  }
}
